import { Injectable } from '@angular/core';

/**
 * Must be used with `[ngClass]`.
 */
export interface SortNgClassObject {
  'fa-sort-up': boolean;
  'fa-sort': boolean;
  'fa-sort-down': boolean;
}

@Injectable()
export class SortColumnsService {
  orderByParam: string = '';

  constructor() { }

  setSortClass(sortState: boolean | null): SortNgClassObject {
    return {
      'fa-sort-up': sortState === true,
      'fa-sort': sortState === null,
      'fa-sort-down': sortState === false
    };
  }

  setSortState(sortState: boolean | null): boolean | null {
    switch (sortState) {
    case true:
      return false;
    case false:
      return null;
    case null:
      return true;
    default:
      return sortState;
    }
  }

  setSortParams(column: string, sortDirection: boolean | null) {
    this.orderByParam = '';
    if (sortDirection !== null) {
      this.orderByParam = sortDirection ? column : `${column} desc`;
    }
  }
}
