import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { IActivityData } from '@models/activity/activityData';
import { ApiResponse } from '@models/api-response';
import { EducationalUnitIntegrationProfile } from '@models/educational-unit-integration-profile.model';
import { KeyValuePair } from '@models/key-value-pair';
import { ILtiAdvantageProfile, ILtiAdvantageProfileDetail } from '@models/lti-advantage-profile';
import { ILtiProfile } from '@models/lti-profile';
import { SecureFileTransferCredentials } from '@models/secure-file-transfer-credentials.model';
import { IUserSettings } from '@models/user/user-settings';
import { IntegrationProfileType } from '@shared/enums/integration-profile-type';
import { LicenseAssignmentType } from '@shared/enums/license-status';
import { Helpers } from '@shared/helpers';
import {
  instantiateApiResponseFromArray,
  instantiateApiResponseFromJson
} from '@shared/zb-rxjs-operators/rxjs-operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EducationalUnitApiService {

  educationalUnitIdSubject = new BehaviorSubject<string>('');

  constructor(
    public http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  getAccountUrl(accountId: string, prefix: string = ''): string {
    return `${this.appConfig.apiUrl}/educational-unit/${prefix}${accountId}`;
  }

  getIntegrationProfileUrl(accountId: string): string {
    return `${this.appConfig.apiUrl}/integration/profile/educational-unit/${accountId}`;
  }

  getAccountLicenseUrl(accountId: string): string {
    return `${this.getAccountUrl(accountId)}/license/manage`;
  }

  getSchoolUrl(educationalUnitId?: string): string {
    const baseUrl = `${this.appConfig.apiUrl}/educational-unit`;
    return educationalUnitId ? `${baseUrl}/${educationalUnitId}` : baseUrl;
  }

  getSchoolSettingsUrl(educationalUnitId: string): string {
    return `${this.getSchoolUrl(educationalUnitId)}/user-setting`;
  }

  getLicenseUrl(schoolId: string, districtId?: string): string {
    const url = `${this.appConfig.apiUrl}/educational-unit/${schoolId}`;
    if (districtId) {
      return `${url}/district/${districtId}/license`;
    }
    return `${url}/license`;
  }

  getSchoolLicensesUrl(schoolId: string, schoolYear: number, districtId?: string, userId?: string, classroomId?: string,
    assignmentType?: LicenseAssignmentType, excludeAvailable: boolean = true): string {
    const baseUrl = `${this.appConfig.apiUrl}/educational-unit/${schoolId}`;
    const params: KeyValuePair[] = [
      {
        key: 'SchoolYear',
        value: schoolYear
      }
    ];
    if (districtId) {
      return `${baseUrl}/district/${districtId}/license`;
    }
    if (userId) {
      params.push({ key: 'userId', value: userId });
    }
    if (classroomId) {
      params.push({ key: 'classroomId', value: classroomId });
    }
    if (assignmentType) {
      params.push({ key: 'assignmentType', value: assignmentType });
    }

    if (!excludeAvailable) {
      params.push({ key: 'excludeAvailable', value: excludeAvailable });
    }
    return params.length > 0
      ? params
        .reduce((url, param) => `${url}${param.key}=${encodeURIComponent(param.value)}&`, `${baseUrl}/license?`)
        .replace(/&$/, '')
      : `${baseUrl}/license${assignmentType && assignmentType === LicenseAssignmentType.Classroom ? '/assign' : ''}`;
  }

  getSchoolLicenses(
    schoolId: string, schoolYear: number, districtId: string = null, userId: string = null, classroomId: string = null,
    assignmentType: LicenseAssignmentType = null, filterByClass: boolean = true): Observable<any> {
    // eslint-disable-next-line max-len
    const url = this.getSchoolLicensesUrl(schoolId, schoolYear, districtId, userId, classroomId, assignmentType, filterByClass);
    return this.http.get(url);
  }

  getSchoolYearStartDate(educationalUnitId: string): Observable<any> {
    const url = `${this.getSchoolUrl(educationalUnitId)}/activate`;
    return this.http.get(url);
  }

  updateSchoolYearStartDate(
    educationalUnitId: string,
    schoolYearStartDate: string,
    schoolYearEndDate: string,
    schoolYear: string,
    expireLicenses: boolean): Observable<any> {
    const url = `${this.appConfig.apiUrl}/educational-unit/${educationalUnitId}/activate`;
    return this.http.patch(url, {
      expireLicenses,
      schoolYearDates: { schoolYearStartDate, schoolYearEndDate, schoolYear },
    });
  }

  expireEducationalUnitLicenses(educationalUnitId: string, schoolYear: number, isDistrict: boolean): Observable<any> {
    const url = `${this.getLicenseUrl(educationalUnitId)}/expire`;
    return this.http.post(url, { educationalUnitId, schoolYear, isDistrict });
  }

  getUserSettings(educationalUnitId: string): Observable<any> {
    return this.http.get(this.getSchoolSettingsUrl(educationalUnitId));
  }

  updateUserSettings(educationalUnitId: string, settings: IUserSettings): Observable<any> {
    return this.http.patch(this.getSchoolSettingsUrl(educationalUnitId), settings);
  }

  searchAccounts(params: KeyValuePair[]): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/educational-unit/account/search', params);
    return this.http.get(url);
  }

  searchEducationalUnits(params: KeyValuePair[]): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/educational-unit/search', params);
    return this.http.get(url);
  }

  getSchoolsInDistrict(educationalUnitId: string): Observable<any> {
    return this.http.get(this.getAccountUrl(educationalUnitId, 'district/'));
  }

  getEducationalUnit(educationalUnitId: string): Observable<any> {
    return this.http.get(this.getAccountUrl(educationalUnitId));
  }

  searchEducationalUnitByZbNum(zbNum: string, pageSize: number = 1): Observable<any> {
    const params = [
      { key: 'pageSize', value: pageSize },
      { key: 'pageNumber', value: 1 },
      { key: 'zbNum', value: zbNum },
    ];
    return this.searchEducationalUnits(params);
  }

  getEducationalUnitByZbNum(zbNum: string): Observable<any> {
    return this.searchEducationalUnitByZbNum(zbNum);
  }

  getEducationalUnitProfiles(educationalUnitId: string, params?: Params): Observable<any> {
    const url = this.getIntegrationProfileUrl(educationalUnitId);
    return this.http.get(url, params);
  }

  getEducationalOneRosterProfile(educationalUnitId: string): Observable<EducationalUnitIntegrationProfile[]> {
    const url = `${this.appConfig.apiUrl}/integration/profile/search`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('EducationalUnitId', educationalUnitId);
    // queryParams = queryParams.append('EducationalUnitType', 'District');
    queryParams = queryParams.append('IntegrationProfileType', 'OneRoster');
    return this.http.get(url, { params: queryParams }).pipe(
      instantiateApiResponseFromArray(EducationalUnitIntegrationProfile),
      map(apiResponse => apiResponse.response)
    );
  }

  setupNewOneRosterIntegrationProfile(educationalUnitId: string): Observable<SecureFileTransferCredentials> {
    const url = `${this.appConfig.apiUrl}/integration/educational-unit/${educationalUnitId}/secure-file-transfer/setup`;
    return this.http.post(url, {}).pipe(
      instantiateApiResponseFromJson(SecureFileTransferCredentials),
      map(apiResponse => apiResponse.response)
    );
  }

  generateNewOneRosterIntegrationPassword(educationalUnitId: string): Observable<SecureFileTransferCredentials> {
    const url = `${this.appConfig.apiUrl}/integration/educational-unit/${educationalUnitId}/secure-file-transfer/generate-new-password`;
    return this.http.post(url, {}).pipe(
      instantiateApiResponseFromJson(SecureFileTransferCredentials),
      map(apiResponse => apiResponse.response)
    );
  }

  convertEducationalUnit(educationalUnitId: string): Observable<any> {
    const url = `${this.getAccountUrl(educationalUnitId)}/convert`;
    return this.http.patch(url, null);
  }

  getEducationalUnitLicenseGroups(educationalUnitId: string, params: any): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/educational-unit/${educationalUnitId}/license`, params);
    return this.http.get(url);
  }

  addSchoolToDistrict(districtId: string, data: any): Observable<any> {
    const url = `${this.getAccountUrl(districtId, 'district/')}/school`;
    return this.http.post(url, data);
  }

  removeSchoolFromDistrict(districtId: string, schoolId: string, doesSchoolRetainLicenses: boolean)
    : Observable<any> {
    const params = [{ key: 'doesSchoolRetainLicenses', value: doesSchoolRetainLicenses }];
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/educational-unit/district/${districtId}/school/${schoolId}`, params);
    return this.http.delete(url);
  }

  removeLicenseGroupForSchoolYear(educationalUnitId: string, skuId: string, schoolYear: string): Observable<any> {
    const baseUrl = `${this.getAccountLicenseUrl(educationalUnitId)}/sku/`;
    return this.http.delete(`${baseUrl}${skuId}/school-year/${schoolYear}`);
  }

  updateLicenseGroupQuantity(educationalUnitId: string, skuId: string, schoolYear: string, quantity: number)
    : Observable<any> {
    const url = this.getAccountLicenseUrl(educationalUnitId);
    const data = { skuId, quantity, educationalUnitId, schoolYear };
    return this.http.patch(url, data);
  }

  updateOneRosterId(educationalUnitId: string, params: any, body?: any): Observable<ApiResponse<EducationalUnitIntegrationProfile>> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/integration/profile/educational-unit/${educationalUnitId}`, params);
    return this.http.put(url, body || {}).pipe(
      instantiateApiResponseFromJson(EducationalUnitIntegrationProfile),
    );
  }

  updateOneRosterData(educationalUnitId: string, params: any, body?: any): Observable<ApiResponse<EducationalUnitIntegrationProfile>> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/integration/profile/educational-unit/${educationalUnitId}/data`, params);
    return this.http.put(url, body || {}).pipe(
      instantiateApiResponseFromJson(EducationalUnitIntegrationProfile),
    );
  }

  turnOffIntegrationsRequest(educationalUnitId: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/integration/profile/educational-unit/${educationalUnitId}/turnoff-request?profileType=OneRoster`;
    return this.http.put(url, {});
  }

  deleteIntegrationProfile(educationalUnitId: string, params: any): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, `/integration/profile/educational-unit/${educationalUnitId}`, params);
    return this.http.delete(url, {});
  }

  deleteAllUsers(educationalUnitId: String): Observable<any> {
    const url = `${this.appConfig.apiUrl}/user/educational-unit/${educationalUnitId}`;
    return this.http.request('delete', url);
  }

  unassignAllLicenses(educationalUnitId: string, params): Observable<any> {
    const endpoint = `/educational-unit/${educationalUnitId}/license/manage`;
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.delete(url);
  }

  deleteAllClassrooms(educationalUnitId: string, params: any): Observable<any> {
    const url = Helpers.buildUrlWithParameters(
      this.appConfig.apiUrl, `/classroom/educational-unit/${educationalUnitId}`, params
    );
    return this.http.delete(url);
  }

  createLtiProfile(educationalUnitId: string): Observable<any> {
    const params = new HttpParams({ fromObject: { integrationId: null, profileType: 'LTI' } });
    return this.http.put(this.getIntegrationProfileUrl(educationalUnitId), {}, { params });
  }

  deleteLtiProfile(profile: ILtiProfile): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        integrationId: profile.integrationId,
        profileType: 'LTI',
      },
    });
    return this.http.delete(this.getIntegrationProfileUrl(profile.educationalUnitId), { params });
  }

  createLtiAdvantageProfile(educationalUnitId: string, profileData: ILtiAdvantageProfileDetail): Observable<any> {
    const data: any = [
      {
        companyCodeType: profileData.companyCodeType,
        accessTokenUrl: profileData.accessTokenUrl,
        authorizeUrl: profileData.authorizeUrl,
        name: profileData.name,
        issuer: profileData.issuer,
        jwkSetUrl: profileData.jwkSetUrl,
        platformId: profileData.platformId,
        clientId: profileData.clientId,
      }
    ];

    const params = new HttpParams({ fromObject: { integrationId: profileData.issuer, profileType: 'LTIAdvantage' } });
    return this.http.put(`${this.getIntegrationProfileUrl(educationalUnitId)} /data`, data, { params });
  }

  deleteLtiAdvantageProfile(profile: ILtiAdvantageProfile): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        integrationId: profile.integrationId,
        profileType: 'LTIAdvantage',
      },
    });
    return this.http.delete(this.getIntegrationProfileUrl(profile.educationalUnitId), { params });
  }

  createCleverProfile(educationalUnitId: string): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        integrationId: null,
        profileType: IntegrationProfileType.Clever,
      }
    });
    return this.http.put(this.getIntegrationProfileUrl(educationalUnitId), {}, { params });
  }

  deleteAccount(accountId: string): Observable<any> {
    return this.http.delete(this.getAccountUrl(accountId));
  }

  getActivityData(schoolId: string): Observable<ApiResponse<IActivityData>> {
    return this.http.get<ApiResponse<IActivityData>>(`${this.getSchoolUrl(schoolId)}/activities`)
      .pipe(
        shareReplay(),
        map(res => new ApiResponse<IActivityData>(true, res)),
      );
  }
}
