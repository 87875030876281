import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { BrowserService } from '@core/browser.service';

@Component({
  selector: 'zbp-choose-login',
  templateUrl: './choose-login.component.html',
  styleUrls: ['../login.component.scss', './choose-login.component.scss'],
})
export class ChooseLoginComponent implements OnInit {
  isHighlights = false;
  constructor(
    private router: Router,
    private titleService: Title,
    private appConfig: AppConfigService,
    public browserService: BrowserService,
  ) { }

  ngOnInit() {
    if (this.appConfig.isHighlightsPortalUrl) {
      this.isHighlights = true; // this is to prevent the choose role HTML from displaying
      this.router.navigateByUrl('/login/user');
    } else {
      this.titleService.setTitle('Login | My ZB Portal');
    }
  }

  get backToZanerBloserLink(): string {
    return 'http://www.zaner-bloser.com';
  }
}
