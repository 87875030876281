import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class NonIsoDateFormatter extends NgbDateParserFormatter {
  readonly displayFormat: string = 'MM/DD/YYYY';
  readonly formats: string[] = ['MM-DD-YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'];

  parse(value: string): NgbDateStruct {
    if (value) {
      const m = moment(value, this.formats);
      if (m.isValid()) {
        return {
          year: m.year(),
          month: m.month() + 1,
          day: m.date(),
        } as NgbDateStruct;
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (date && date.year && date.month && date.day) {
      const m = moment();
      m.year(date.year);
      m.month(date.month - 1);
      m.date(date.day);
      return m.format('MM/DD/YYYY');
    }
    return '';
  }
}
