import { CompanyCode } from '@shared/enums/company-code';

export namespace MasqueradeTextHelpers {
  export function getUnableToMasqueradeText(loginBrand: CompanyCode, roleBlocked = false): string {
    if (roleBlocked) {
      return 'Unable to masquerade as this role.';
    }
    const unableToMasqOnDomainText = {
      [CompanyCode.HighlightsPortal]: 'Please log into ZB Portal to masquerade as this user.',
      [CompanyCode.ZBPortal]: 'Please log into Highlights Portal to masquerade as this user.'
    };

    return unableToMasqOnDomainText[loginBrand] ?? '';
  }

  export const UNABLE_TO_MASQ_AS_ROLE_TEXT = 'Unable to masquerade as this role.';

  export const ADMIN_TO_TEACHER_CONFIRMATION_TEXT = 'Do you want to exit the Admin site and go to the teacher\'s site?';

  export const EXIT_MASQUERADE_DESCRIPTION_TEXT = 'Are you sure you want to EXIT masquerading?';

  export const CONFIRM_EXIT_MASQUERADE_TEXT = 'CONFIRM (EXIT masquerade)';
}
