/**
 * Describes how a product component launches.
 *
 * This affects if it is available in ZBPortal, Digital-Resources or a vendor app.
 */
export enum LaunchType {
  // Launch with component id and launchType.
  eBook = 'eBook',

  // Launch with component id, asset id and language.
  Viewer = 'Viewer',

  // Launch with classroom, activity, and other parameters. This launch type is a misnomer,
  // and does not refer specifically to "gum". It should be "StudentLessons".
  GumLessons = 'GumLessons',
  StudentLessons = 'StudentLessons',

  // Launch with activity and other parameters.
  QuickLessons = 'QuickLessons',
  TeacherLessons = 'TeacherLessons',

  // Launch directly into worksheet maker with component id and launchType.
  WorksheetMaker = 'WorksheetMaker',

  // Similar to StudentLessons but 2 different product applications.
  SuperKidsOnlineFun = 'SuperKidsOnlineFun',

  // Non-viewer, static content.
  SuperkidsOwnWebPages = 'SuperkidsOwnWebPages',

  // Interactive whiteboard activity that requires separate content.
  DailyRoutine = 'DailyRoutine',

  // Kickstart Number Practice activity.
  NumberPractice = 'NumberPractice',

  // Interactive whiteboard activity that is provided by a vendor.
  WhiteboardActivity = 'WhiteboardActivity',

  // Unique superkids special snowflake activities.
  SuperSmart = 'SuperSmart',

  // Launch with activity, but load list page via viewer asset.
  MatchingWithZaney = 'MatchingWithZaney',

  // @deprecated Launch with Spelling 2020 parameters for game.
  Spelling2020Game = 'Spelling2020Game',

  // @deprecated Launch with Spelling 2020 parameters for assessment.
  Spelling2020Test = 'Spelling2020Test',

  // @deprecated Launch Spelling 2020 Spelling Pattern Activity with parameters.
  Spelling2020Resource = 'Spelling2020Resource',

  // Launch with activity, product and  week id.
  PrintResource = 'PrintResource',
}
