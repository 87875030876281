import { ClassroomIcon } from '@models/classroom-icon';
import { School } from '@models/school';
import { createReducer, on, Action } from '@ngrx/store';
import * as _ from 'lodash';


import * as SchoolsActions from './schools.actions';

export const schoolsFeatureKey = 'schools';

export interface SchoolsState {
  schools: School[];
  classroomIcons: ClassroomIcon[];
  errors: string[];
  loadInProgress: boolean;
  classroomIconsLoaded: boolean;
  selectedSchoolId: string;
  schoolsHaveBeenSet: boolean;
}

export const initialState: SchoolsState = {
  schools: [],
  classroomIcons: null,
  errors: [],
  loadInProgress: false,
  classroomIconsLoaded: false,
  selectedSchoolId: '',
  schoolsHaveBeenSet: false,
};

const schoolsReducer = createReducer(
  initialState,
  on(SchoolsActions.refreshSchools, state => state),
  on(SchoolsActions.setSchools, (state, { payload }) => (
    { ...state,
      schools: _.cloneDeep(payload),
      loadInProgress: false,
      errors: [],
      selectedSchoolId: state.selectedSchoolId,
      schoolsHaveBeenSet: true, }
  )),
  on(SchoolsActions.setMessages, (state, { messages }) => (
    { ...state, errors: _.cloneDeep(messages), loadInProgress: false }
  )),
  on(SchoolsActions.resetLoading, state => ({ ...state, loadInProgress: true })),
  on(SchoolsActions.updateSchool, (state, { payload, schoolId }) => (
    { ...state, schools: [...state.schools.map(s => (s.schoolId === schoolId ? payload : s))] }
  )),
  on(SchoolsActions.addSchool, (state, { payload }) => (
    { ...state, schools: state.schools.concat(payload) }
  )),
  on(SchoolsActions.removeSchool, (state, { schoolId }) => (
    { ...state, schools: [...state.schools.filter(s => s.schoolId !== schoolId)] }
  )),
  on(SchoolsActions.setSelectedSchoolId, (state, { schoolId }) => (
    { ...state, selectedSchoolId: schoolId }
  )),
  on(SchoolsActions.initClassroomIcons, state => state),
  on(SchoolsActions.resetClassroomIconsLoading, state => ({ ...state, classroomIconsLoaded: false })),
  on(SchoolsActions.setClassroomIcons, (state, { payload }) => ({
    ...state,
    classroomIcons: _.cloneDeep(payload),
    classroomIconsLoaded: true,
  })),

);

export function reducer(state: SchoolsState | undefined, action: Action) {
  return schoolsReducer(state, action);
}
