export enum LoginStatus {
  HasLoggedIn = 'HasLoggedIn',
  NeverLoggedIn = 'NeverLoggedIn',
  NotSetup = 'NotSetup',
}

export const loginStatusNames = [
  { loginStatus: LoginStatus.HasLoggedIn, name: 'Has logged in' },
  { loginStatus: LoginStatus.NeverLoggedIn, name: 'Never logged in' },
  { loginStatus: LoginStatus.NotSetup, name: 'Not Setup' },
];
