import { Injectable } from '@angular/core';
import { ProductService } from '@core/product.service';
import { QuestService } from '@core/quest.service';
import { SchoolLicense } from '@models/license/school-license';
import { IActivity, Quest } from '@models/quest';
import { assignableTopicTypes, Topic, TopicGroup } from '@models/topic';
import { ProductType } from '@shared/enums/product-type';
import { TopicType  } from '@shared/enums/topic-type';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TopicService {
  constructor(
    private productService: ProductService,
  ) { }

  /**
   * Get topic groups
   *
   *
   *
   * @param schoolLicense
   * @param classroomId
   */
  getTopicGroups(schoolLicense: SchoolLicense, classroomId: string): Observable<TopicGroup[]> {
    return this.productService.getTopicsByProductAndGrade(
      schoolLicense.productType,
      schoolLicense.variant,
      classroomId
    )
      .pipe(
        map((groups: TopicGroup[]) => {
          let result: TopicGroup[] = [];
          if (QuestService.isSuperkids(schoolLicense.productType)) {
            /*
             * there are games, videos, books that exist for superkids...but teachers can't assign those
             */
            result = groups.filter(g => g.items?.findIndex(i => i.topicType === TopicType.Assessment) > -1);
          } else {
            result = groups;
          }
          return result;
        })
      );
  }

  /**
   * Get the topic groups belonging to the quest
   * @param activity
   */
  getActivityQuests(activity: IActivity): Quest[] {
    let result: any[];
    if (QuestService.isAggregate(TopicService.getQuest(activity.quests)) && activity.childQuests) {
      result = activity.childQuests;
    } else {
      result = activity.quests;
    }
    return _.uniqBy(result, 'questKey');
  }

  /**
   * Get all of the available topics for a quest
   *  - return topics according to product e.g. handwriting, spelling, superkids...
   * @param license
   * @param activity
   * @param topicGroups
   */
  getTopics(license: SchoolLicense, activity: IActivity, topicGroups: TopicGroup[]): Topic[] {
    let topics: Topic[];
    if (!QuestService.isHandwriting(license.productType)) {
      // Spelling - Superkids
      let topicTypeFilter;
      if (QuestService.isSuperkids(license.productType)) {
        topicTypeFilter = [TopicType.Assessment];
      } else {
        topicTypeFilter = assignableTopicTypes;
      }
      topics = TopicService.getTopicsByTopicGroup(this.getTopicGroup(activity, topicGroups))
        .filter(t => topicTypeFilter.includes(t.topicType));
    } else {
      // Handwriting - Custom Free Write
      const topic = TopicService.asTopic(activity);
      if (QuestService.isCustomActivity(license, activity)) {
        // Custom (Activity) Free Write
        topics = [{
          topicKey: topic.topicKey,
          topicType: topic.topicType,
          topicName: 'Custom FreeWrite',
        }];
      } else {
        // Handwriting
        topics = TopicService.getTopicsByTopicGroup(this.getTopicGroup(activity, topicGroups))
          .filter(t => assignableTopicTypes.includes(t.topicType));
      }
    }
    return topics;
  }

  /**
   * Get topic (name / type) according to product type
   * @param topic
   * @param productType
   */
  getTopicPartName(topic: Topic, productType: ProductType): string {
    if (QuestService.isSpelling(productType) || QuestService.isHandwriting(productType)) {
      return topic.topicName;
    }
    return topic.topicType;
  }

  /**
   * Get the topic group by activity - do so according to the quest's aggregate aspect.
   */
  private getTopicGroup(activity: IActivity, topicGroups: TopicGroup[]): TopicGroup {
    let sourceQuests: any[];
    if (QuestService.isAggregate(TopicService.getQuest(activity.quests)) && activity.childQuests) {
      sourceQuests = activity.childQuests;
    } else {
      sourceQuests = activity.quests;
    }
    return this.getTopicGroupByQuestKey(TopicService.getQuest(sourceQuests).questKey, topicGroups);
  }

  /**
   * Find the first topicGroup that holds an item - containing the quest key
   *  - accessing the collection of topic groups by key is not available.
   * @param questKey
   * @param topicGroups
   */
  private getTopicGroupByQuestKey(questKey: string, topicGroups: TopicGroup[]): TopicGroup {
    let result: TopicGroup;
    let isGroupFound = false;
    const topicKeysFound = [];
    topicGroups.forEach((group) => {
      if (!isGroupFound) {
        group.items.forEach((item) => {
          topicKeysFound.push(item.topicKey);
          if (!isGroupFound && (item.topicKey === questKey)) {
            isGroupFound = true;
            result = group;
          }
        });
      }
    });
    if (!result) {
      throw new Error(`topicService: getTopicGroupByQuestKey: no Topic Group found for ${questKey}.  Found ${topicKeysFound}`);
    }
    return result;
  }

  /**
   * Get topic types contained in a Topic Group
   *  - Daily lesson topic key is out-of-scope
   * @param topicGroup
   */
  private static getTopicsByTopicGroup(topicGroup: TopicGroup): Topic[] {
    return topicGroup.items.filter(item => item.topicType !== 'DailyLesson');
  }

  /**
   * Get the Quest
   *  - this method is here to clarify that the collection repeats its contents
   *  - repeated contents (models) differ in aspects related to separate students - but not in topic assessment terms.
   */
  static getQuest(quests: Quest[]): Quest {
    return quests[0];
  }

  /**
   * Quest as a Topic
   * Topics and Quests are used interchangeably in the activity domain
   * // TODO: interfaces for Topics and Quests are non-restrictive and overlapping
   * @param activity
   */
  static asTopic(activity: IActivity): Topic {
    return <Topic>(TopicService.getQuest(activity.quests) as any);
  }
}
