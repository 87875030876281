
import { IClassroomContext } from '@models/classroom-context';
import { IUser } from '@models/user';
import { createReducer, on, Action } from '@ngrx/store';
import { setChildren, setChildrenLoaded, setClassroom, setMessages } from './parent.actions';

export const parentFeatureKey = 'parent';

export interface ParentState {
  children: IUser[];
  classroomContext: IClassroomContext;
  childrenLoaded: boolean;
  messages: string[];
}

export const initialState: ParentState = {
  children: null,
  childrenLoaded: false,
  classroomContext: null,
  messages: [],
};

const parentReducer = createReducer(
  initialState,
  on(setChildrenLoaded, (state, { loaded }) => ({ ...state, childrenLoaded: loaded })),
  on(setChildren, (state, { children }) => ({ ...state, children, childrenLoaded: true })),
  on(setClassroom, (state, { classroom }) => {
    let students: IUser[] = [];
    if (state?.children) {
      students = state.children.filter(child => (
        child.classrooms?.findIndex(c => c.classroomId === classroom?.classroomId) !== -1
      ));
    }
    return {
      ...state,
      classroomContext: { ...state.classroomContext, classroom, students },
    };
  }),
  on(setMessages, (state, { messages }) => ({ ...state, messages })),
);

export function reducer(state: ParentState | undefined, action: Action) {
  return parentReducer(state, action);
}
