
import { LoginStatus } from '@shared/enums/login-status';
import { MasqueradeAbilities } from '@shared/enums/masquerade-abilities';
import { RoleType } from '@shared/enums/role-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import moment, { Moment } from 'moment';
import { Classroom } from './classroom';
import { IEducationalUnitMembership } from './educational-unit-membership';
import { School } from './school';
import { StudentQRCode } from './student-qr-code';

import { IUserProfileDetail } from './user/profile-detail';
import { IUserProductUsage } from './user/user-product';
import { UserRole } from './user-role';
import { UserRoleDomains } from './user-role-company-codes';

export interface IUserAssociationAddRemove {
  associatedUserId?: string;
  associatedUserName?: string;
}

export class UserPartial {
  userId?: string = undefined;
  firstName: string = undefined;
  lastName: string = undefined;
  userName?: string = undefined;
  username?: string = undefined;
  studentUserName?: string = undefined;
  externalId?: string = undefined;
  password?: string = undefined;
  abbreviatedSchoolId?: string = undefined;
  confirmPassword?: string = undefined;
  currentPassword?: string = undefined;
  rolesToAdd?: UserRole[] = [];
  rolesToRemove?: UserRole[] = [];
  userAssociationsToAdd?: IUserAssociationAddRemove[] = [];
  userAssociationsToRemove?: IUserAssociationAddRemove[] = [];
}

export function isIUserAssociationPartial(obj) {
  return typeof obj === 'object'
    && obj !== null
    && typeof obj.userId === 'string'
    && typeof obj.firstName === 'string'
    && typeof obj.lastName === 'string'
    && typeof obj.userName === 'string'
    && Array.isArray(obj.educationalMemberships);
}

export interface IUserAssociationPartial {
  userId: string;
  firstName: string;
  lastName: string;
  userName: string;
  educationalMemberships: IEducationalUnitMembership[];
}

export class IUser {
  // Shared.
  isParent: boolean = undefined;
  isTeacher: boolean = undefined;
  isSchoolAdmin: boolean = undefined;
  isDistrictAdmin: boolean = undefined;
  isElevatedAdmin: boolean = undefined;
  isAuthenticated: boolean = undefined;
  isInternal: boolean = undefined;
  isInGroup: (...args) => boolean = undefined;
  roles?: RoleType[] = [];
  memberships?: any[] = [];
  userName: string = undefined;
  studentUserName?: string = undefined;
  abbreviatedSchoolId: string = undefined;
  lastActivityTime?: string = undefined;
  userMasqueradeCompanyCodes?: UserRoleDomains[] = undefined;
  masqueradeAbility?: MasqueradeAbilities = undefined;

  // ZbPortal-Api.
  firstName: string = undefined;
  lastName: string = undefined;
  userId: string = undefined;
  externalId?: string = undefined;
  @ClassCast(School)
    schools: School[] = [];
  districts?: any[] = [];
  classrooms?: Classroom[] = [];
  // eslint-disable-next-line no-use-before-define
  @ClassCast(IUser)
  // eslint-disable-next-line no-use-before-define
    userAssociations?: IUser[] = [];
  @ClassCast(IUserProductUsage)
    productUsages?: IUserProductUsage[] = [];
  _lastLogin?: Moment = undefined;
  loginStatus: LoginStatus;
  // systemName?: string;
  assignedLicenseCount?: number = undefined;
  isActive: boolean = undefined;
  isLockedOut: boolean = undefined;
  canMasquerade?: boolean = undefined;
  locale: string = undefined;
  @ClassCast(IUserProfileDetail)
    profileDetail: IUserProfileDetail = copyObject({}, IUserProfileDetail);
  lti: any = undefined;
  generateStudentQRCodeOnCreate?: boolean = undefined;
  @ClassCast(StudentQRCode)
    studentQRCode: StudentQRCode = undefined;

  // Legacy.
  uid?: string = undefined;
  _lti?: any = undefined;
  getDisplayName: string = undefined;
  getSystemName: string = undefined;
  ssoLoginUrl: string = undefined;

  get isStudent(): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === RoleType.Student;
    }

    return this.roles?.includes(RoleType.Student);
  }

  get fullName(): string {

    if (this.firstName && this.lastName) {
      return `${this.firstName.trim()} ${this.lastName.trim()}`;
    }
    if (this.firstName) {
      return this.firstName.trim();
    }
    return this.userName;
  }

  set lastLogin(lastLogin: string | moment.Moment) {
    if (lastLogin && !moment.isMoment(lastLogin)) {
      this._lastLogin = moment(lastLogin);
    }
  }

  get lastLogin(): Moment {
    return this._lastLogin;
  }

  set username(value: string) {
    if (this.isStudent && this.abbreviatedSchoolId) {
      this.userName = value?.length > 0 ? `${value}_${this.abbreviatedSchoolId}` : value;
    } else {
      this.userName = value;
    }
  }

  get username(): string {
    return this.userName;
  }

  get systemName(): string {
    return this.userName;
  }

  onInit() {
    if (this.profileDetail?.viewingAsRole && this.roles.length < 2) {
      //eslint-disable-next-line
      this.profileDetail.viewingAsRole = this.roles[0];
    }
  }

  isRole(role: RoleType): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === role;
    }
    return this.roles.includes(role);
  }

}

// IUserUpdate - firstName and lastName are not required
type Diff<T extends keyof any, U extends keyof any> =
  ({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
type Overwrite<T, U> = Pick<T, Diff<keyof T, keyof U>> & U;
interface UserPartialExtension {
  firstName?: string,
  lastName?: string,
}
export interface IUserUpdate extends Overwrite<UserPartial, UserPartialExtension> {
  userId: string,
  isRestricted?: boolean,
  isOneRoster?: boolean,
  schoolId?: string,
}
