import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { SiteDownBrandHandlerComponent } from './site-down/site-down-brand-handler.component';

const routes: Routes = [
  { path: 'logout', component: LogoutComponent },
  { path: 'site-error', component: SiteDownBrandHandlerComponent },
  {
    path: '',
    canActivateChild: [AuthenticationGuard],
    loadChildren: () => import('./root/root.module').then(m => m.RootModule),
  },
  { path: '**', component: PageNotFoundComponent, canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
