import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ZbPortalRedesignHelpers } from '@core/services/feature-flagged-features/zb-portal-redesign-helpers';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { hlFooterLinks, zbFooterLinks } from '@shared/enums/footer-links';

@Component({
  selector: 'zbp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() showLinks = true;
  @Input() isAndroidUser: boolean = false;
  @Input() background = 'bgPrimary';
  isHighlights = false;
  applyZbRedesign: boolean = false;
  zbCopyrightText: string = 'Zaner-Bloser';

  private readonly dialogOptions: NgbModalOptions = {
    size: 'lg', backdrop: 'static'
  };

  /**
   * Checks hostname because this is used in anonymous context.
   */
  isHighlightsPortalUser: boolean = this.appConfig.isHighlightsPortalUrl;

  constructor(
    private modalService: NgbModal,
    private appConfig: AppConfigService,
    private zbRedesignHelpers: ZbPortalRedesignHelpers) {
    this.dialogOptions = {
      backdrop: 'static',
      backdropClass: 'customBackdrop'
    };

    this.isHighlights = this.appConfig.isHighlightsPortalUrl;
  }

  ngOnInit(): void {
    this.applyZbRedesign = !this.isHighlightsPortalUser && this.zbRedesignHelpers.isZbPortal2024Active();

    if (this.applyZbRedesign) {
      this.zbCopyrightText = 'Zaner-Bloser, Inc. All Rights Reserved.';
    }
  }

  open(content) {
    this.modalService.open(content, this.dialogOptions);
  }

  getSystemRequirementsLink(): string {
    if (this.isHighlights) {
      return  hlFooterLinks.systemRequirements;
    }

    return zbFooterLinks.systemRequirements;
  }

  getLegalLink() {
    if (this.isHighlights) {
      return hlFooterLinks.legal;
    }

    return zbFooterLinks.legal;
  }

  getPrivacyLink() {
    if (this.isHighlights) {
      return hlFooterLinks.privacy;
    }

    return zbFooterLinks.privacy;
  }

  getTechSupportLink() {
    if (this.isHighlights) {
      return hlFooterLinks.support;
    }

    return zbFooterLinks.support;
  }
}
