import { Injectable } from '@angular/core';
import { Remarkable } from 'remarkable';

import { boxFencePlugin } from './markdown-plugins/box-fence';
import { bracketPlugin } from './markdown-plugins/bracket';
import { underlinePlugin } from './markdown-plugins/underline';

@Injectable()
export class MarkdownService {
  private md: Remarkable;

  constructor() {
    this.md = new Remarkable('full', { breaks: true, typographer: true, quotes: '“”‘’' });

    // Adds Z-B markdown plugins.
    this.md.use(underlinePlugin);
    this.md.use(bracketPlugin);
    this.md.use(boxFencePlugin);
  }

  parse(value: string): Remarkable.Token[] {
    return this.md.parse(value, {});
  }

  parseInline(value: string): Remarkable.Token[] {
    return this.md.parseInline(value, {});
  }

  render(value: string): string {
    return this.md.render(value);
  }

  renderInline(value: string): string {
    return this.md.renderInline(value);
  }
}
