import { ProductType } from '@shared/enums/product-type';
import { VariantType } from '@shared/enums/variant-type';
import { SchoolLicense } from './license/school-license';

export class IClassroomProductDescription {
  productType: ProductType = undefined;
  variantType: VariantType = undefined;
  description: string = undefined;
  productLineKey: string = undefined;
  currentLesson?: string = undefined;
  schoolYear?: string = undefined;
}

/**
 * Model represents all the products that a classroom is currently assigned
 * and what products a classroom has available to assign
 *
 * @export
 * @class ClassroomProducts
 */
export class ClassroomProducts {
  constructor(public assignedProducts: SchoolLicense[] = [], public availableProducts: SchoolLicense[] = []) {
    this.assignedProducts = this.assignedProducts.sort((a, b) => a.description.localeCompare(b.description));
    this.availableProducts = this.availableProducts.sort((a, b) => a.description.localeCompare(b.description));
  }

  moveFromAvailableToAssigned(input: string | string[]) {
    if (typeof input === 'string') {
      this.moveFromArrayToArrayById(input, this.availableProducts, this.assignedProducts);
    } else if (input instanceof Array) {
      input.forEach((i) => {
        this.moveFromArrayToArrayById(i, this.availableProducts, this.assignedProducts);
      });
    }
  }

  moveFromAssignedToAvailable(input: string | string[]) {
    if (typeof input === 'string') {
      this.moveFromArrayToArrayById(input, this.assignedProducts, this.availableProducts);
    } else if (input instanceof Array) {
      input.forEach((i) => {
        this.moveFromArrayToArrayById(i, this.assignedProducts, this.availableProducts);
      });
    }
  }

  private moveFromArrayToArrayById(id: string, source: SchoolLicense[], destination: SchoolLicense[]) {
    const index = source.findIndex(l => l.skuId === id);

    if (index >= 0) {
      destination.push(source.splice(index, 1)[0]);
    }
  }
}
