export const DNAME = 'deepObjects';

/**
 * This is a decorator to add to model attributes we want to keep typing for after transpile for
 * nested/ deep objects and arrays
 * Example:
 *
 *    @ClassCast(Employee)
 *    public employee: Employee = undefined;
 */
export function ClassCast(cast: any): (target: any, classPropertyName: string) => void {
  return function ClassCastInner(target: any, classPropertyName: string): void {
    const targetRef = target;
    if (!targetRef[DNAME]) {
      targetRef[DNAME] = {};
    }
    targetRef[DNAME][classPropertyName] = cast;
  };
}

