/**
 * Provides an enum for hostname.
 *
 * This should only be used for components or services that work for anonymous users. In all other cases, the
 * most-appropriate enum is CompanyCode.
 *
 * @see CompanyCode
 */
export enum CompanyHost {
  HighlightsPortal = 'highlightsportal',

  // The following are unused, but provided for posterity.
  ZBPortal = 'zbportal',
  Localhost = 'localhost',
}
