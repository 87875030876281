import { EducationalUnitType } from '@shared/enums/educational-unit-type';
import { copyArray } from '@shared/zb-object-helper/object-helper';

import { EducationalUnitBase } from './educational-unit';
import { School } from './school';

export class District extends EducationalUnitBase {
  educationalUnitType: EducationalUnitType = EducationalUnitType.District;
  private _schools: School[] = [];

  onInit() {
    this.districtId = this.districtId ?? this.educationalUnitId;
    this.hasRosteringIntegrations = this.getHasRosteringIntegrations();
  }

  get schools(): School[] {
    return this._schools;
  }

  set schools(schools: School[]) {
    this._schools = copyArray(schools, School);
  }

}
