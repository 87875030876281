import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DefaultTranslationProvider implements MissingTranslationHandler {
  // We need default translations for UI elements that are shared between sites that don't have
  // translations yet or that are accessible by anonymous users.
  readonly defaultTranslations: {[key: string]: string} = {
    privacyLink: 'Privacy',
    legalLink: 'Legal',
    sysRequirementsLink: 'System Requirements',
    techSupportLink: 'Technical Support',
    helpLink: 'Help',
    myAccountLink: 'My Account',
    chooseLanguageLink: 'Choose Language',
    logoutLink: 'Logout',

    // Parent translations that are now also used in teacher translations.
    'parentScores-activity': 'Activity',
    'parentScores-assessment': 'Assessment',
    'parentScores-chooseProduct': 'Choose Product',
    'parentScores-chooseProductMessage': `Choose a product above to see this student's progress.`,
    'parentScores-comprehension': 'Comprehension',
    'parentScores-created': 'Created',
    'parentScores-date': 'Date',
    'parentScores-dateCompleted': 'Date Completed',
    'parentScores-decoding': 'Decoding',
    'parentScores-dueDate': 'Due Date',
    'parentScores-priRep': 'Print Scores',
    'parentScores-exRep': 'Export Scores',
    'parentScores-independentActivities': 'Independent Activities',
    'parentScores-indClassrep': 'back to Independent Work',
    'parentScores-individualReport': 'Individual Report',
    'parentScores-individualProgress': 'INDIVIDUAL PROGRESS REPORT',
    'parentScores-memoryWords': 'Memory Words',
    'parentScores-noScores': 'There are no scores to show because this student has not completed any assessments yet.',
    'parentScores-noWork': 'There is no work for this student.',
    'parentScores-noQuestWork': 'There are no quests for this student.',
    'parentScores-phonemicAwareness': 'Phonemic Awareness',
    'parentScores-phonics': 'Phonics',
    'parentScores-phonologicalAwareness': 'Phonological Awareness',
    'parentScores-product': 'Product',
    'parentScores-progressReport': 'Progress Report',
    'parentScores-popUptitle': 'ON-PAGE HELP',
    'parentScores-questName': 'Quest Name',
    'parentScores-quests': 'Quests',
    'parentScores-sprObj': 'Objective',
    'parentScores-sprPart': 'Part',
    'parentScores-sprCut': 'Cut-Off',
    'parentScores-scoreAndOnlineLink': 'Score',
    'parentScores-structuralAnalysis': 'Structural Analysis',
    'parentScores-time': 'Time',
    'parentScores-timeCompleted': 'Time Completed',
    'parentScores-type': 'Type',
    'parentScores-writing': 'Writing',
  };

  handle(params: MissingTranslationHandlerParams): string {
    return this.defaultTranslations[params.key] || params.key;
  }
}
