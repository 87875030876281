import { District } from '@models/district';
import { createReducer, on, Action } from '@ngrx/store';
import * as _ from 'lodash';


import * as DistrictsActions from './districts.actions';

export const districtsFeatureKey = 'districts';

export interface DistrictsState {
  districts: District[];
  errors: string[];
  loadInProgress: boolean;
}

export const initialState: DistrictsState = {
  districts: null,
  errors: [],
  loadInProgress: false,
};

const districtsReducer = createReducer(
  initialState,
  on(DistrictsActions.refreshDistricts, state => state),
  on(DistrictsActions.setDistricts, (state: DistrictsState, { payload }) => (
    { districts: _.cloneDeep(payload), loadInProgress: false, errors: [] }
  )),
  on(DistrictsActions.setDistrict, (state: DistrictsState, { payload }) => {
    const newDistrict = state.districts
      ? state.districts.findIndex(d => d.districtId === payload.districtId) === -1
      : true;
    const newState = {
      districts: state.districts ? state.districts.map(d => (d.districtId === payload.districtId ? payload : d)) : [],
      errors: [...state.errors],
      loadInProgress: state.loadInProgress,
    };
    if (newDistrict) {
      newState.districts = [...newState.districts, payload];
    }
    return newState;
  }),
  on(DistrictsActions.setMessages, (state, { messages }) => (
    { ...state, errors: _.cloneDeep(messages), loadInProgress: false }
  )),
  on(DistrictsActions.resetLoading, state => ({ ...state, loadInProgress: true })),
);

export function reducer(state: DistrictsState | undefined, action: Action) {
  return districtsReducer(state, action);
}
