import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ApiResponse } from '@models/api-response';
import { EmailSubscriptionModel } from '@models/email/email-subscription.model';
import { KeyValuePair } from '@models/key-value-pair';
import { Helpers } from '@shared/helpers';
import { instantiateApiResponseFromArray,
  instantiateApiResponseFromJson } from '@shared/zb-rxjs-operators/rxjs-operators';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailApiService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) {}

  private getClassroomResendEmailUrl(classroomId: string): string {
    return `${this.appConfig.apiUrl}/application/email/authenticated/resend-parent-activation-emails/${classroomId}`;
  }

  /**
   * Triggers a password reset email for a teacher.  Not intended for use with students
   */
  resetPassword(email: string): Observable<any> {
    const params = [{ key: 'toAddress', value: email }];
    const endpoint =      '/application/email/unauthenticated/template-type/forgotpassword';
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, endpoint, params);
    return this.http.post(url, {});
  }

  resendParentEmails(classroomId: string): Observable<any> {
    return this.http.post(this.getClassroomResendEmailUrl(classroomId), {});
  }

  /**
   * Retrieves the email log
   * @params {KeyValuePair} The parameters for the endpoint.
   * @returns {Observable<any>} An Observable of the email log
   */
  getEmailLog(params: KeyValuePair[] = []): Observable<any> {
    return this.http.get(
      Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/email/email-log', params)
    );
  }

  /**
   * Calls the save api endpoint to persist the new email and email templates for an email address
   * @param {EmailSubscriptionModel} The request object to be passed to the endpoint
   * @returns {Observable<ApiResponse<EmailSubscriptionModel>>>} The response from the endpoint
   */
  saveNotificationEmail(request: EmailSubscriptionModel): Observable<ApiResponse<EmailSubscriptionModel>> {
    const url = `${this.appConfig.apiUrl}/email/email-subscription`;
    return this.http.put(url, request).pipe(
      instantiateApiResponseFromJson(EmailSubscriptionModel)
    );
  }


  /**
   * Gets a record based on the email address. This is need to populate the checkboxes on the modal
   * @param {string} email address
   * @returns {Observable<EmailSubscriptionModel>} The response as Observable
   */
  getNotificationEmailRecord(email: string): Observable<EmailSubscriptionModel> {
    return this.http.get(`${this.appConfig.apiUrl}/email/email-subscription?EmailAddress=${email}`).pipe(
      instantiateApiResponseFromArray(EmailSubscriptionModel),
      map(res => res.response[0])
    );
  }

  /**
   * Gets all email records registered for email notifications
   *
   * @returns array of EmailSubscriptionResponse
   */
  getAllNotificationEmailRecords(pageNumber: number, pageSize: number): Observable<any> {
    const params = [
      { key: 'pageNumber', value: pageNumber },
      { key: 'pageSize', value: pageSize },
    ];
    return this.http.get(
      Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/email/email-subscription', params)
    )
      .pipe(
        map(res => res)
      );
  }

  /**
   * Deletes an email address from the notification list
   * @param email address to delete as string
   * @returns void
   */
  deleteEmailAddress(address: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/email/${address}/email-subscription`;
    return this.http.delete(url)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] }))
      );
  }
}
