export enum LoginPageType {
  Default,
  Parents,
}

// Values map directly to routes for login
export enum LoginPageRolesByRoute {
  Student = 'student',
  User = 'user', // Route used for Admins & Teachers
  Parent = 'parent'
}
