import { Quest } from '../quest';

export class AssessmentQuest extends Quest {
  detailKey = 'assessmentDashboardQuestDetail';
  assessmentKey: string;
  score?: string;
  activityId?: string;
  updatedOn: Date | string;

  constructor(data?: { [key: string]: any }) {
    super(data);
    if (data) {
      this.setAllowedProperties(data, this.detailKey);

      if (!this.assessmentKey && data.questKey) {
        this.assessmentKey = data.questKey;
      }
    }

    this.description = this.description ?? 'Complete the assessment challenge';
  }

  getAllowedPropertyNames(): string[] {
    return [
      'activityId',
      'assessmentKey',
    ];
  }

  label(): string {
    if (this.description) {
      return this.description;
    }

    const matches = this.assessmentKey.match(/(pretest|posttest)G([K1-9]+)U(\d+)/);
    if (matches && matches.length > 3) {
      const testType = matches[1] === 'pretest' ? 'Pretest' : 'Posttest';
      return `Grade ${matches[2]} Unit ${matches[3]} ${testType}`;
    }

    return '';
  }

  typeLabel(): string {
    return 'Assessment';
  }
}
