export enum LicenseStatus {
  AssignedToYou = 'AssignedToYou',
  Available = 'Available',
  NotAvailable = 'NotAvailable',
}

export enum LicenseAssignmentType {
  Classroom = 'Classroom',
  Teacher = 'Teacher',
}

export enum LicenseActivationStatus {
  Active = 'Active',
  NonActive = 'NonActive',
  Expired = 'Expired',
  Removed = 'Removed',
}
