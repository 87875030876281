
import { Classroom, IClassroomCreate, IClassroomUpdate } from '@models/classroom';
import { ClassroomIcon } from '@models/classroom-icon';
import { ClassroomPin } from '@models/classroom-pin';
import { SchoolLicense } from '@models/license/school-license';
import { IProductLine } from '@models/product-line';
import { Quest } from '@models/quest';
import { QuestCollection } from '@models/quest-collection';
import { IUser } from '@models/user';
import { IViewerAsset } from '@models/viewer-asset';
import { createAction, props } from '@ngrx/store';
import { ProductType } from '@shared/enums/product-type';
import { VariantType } from '@shared/enums/variant-type';

export enum LEARNING_ACTIONS {
  resetProductLines = '[Learning] Reset Products Loading',
  resetClassrooms = '[Learning] Reset Classrooms Loading',
  resetClassroomIcons = '[Learning] Reset Classroom Icons Loading',
  resetClassroomSaved = '[Learning] Reset Classroom Saved',
  initProductLines = '[Learning] Initialize Products',
  initClassrooms = '[Learning] Initialize Classrooms',
  initClassroomIcons = '[Learning] Initialize Classroom Icons',
  initStudentQuests = '[Learning] Initialize Student Quests',
  loadClassroomProfiles = '[Learning] Load Classroom Profiles',
  setProductLines = '[Learning] Set Product lines',
  updateProductComponentPinned = '[Learning] Update Product Component Pinned',
  setClassrooms = '[Learning] Set Classrooms',
  loadSchoolLicenses = '[Learning] Load School Licenses',
  setSchoolLicenses = '[Learning] Set School Licenses',
  resetClassroom = '[Learning] Reset Classroom',
  loadClassroom = '[Learning] Load Classroom',
  createClassroom = '[Learning] Create New Classroom',
  saveExistingClassroom = '[Learning] Save Existing Classroom',
  classroomSaved = '[Learning] Classroom Saved',
  setClassroom = '[Learning] Set Classroom',
  setClassroomIcons = '[Learning] Set Classroom Icons',
  setClassroomStudents = '[Learning] Set Classroom Students',
  setClassroomLicenses = '[Learning] Set Classroom Licenses',
  updateClassroom = '[Learning] Update Classroom',
  addClassroomStudents = '[Learning] Add Classroom Students',
  removeClassroom = '[Learning] Remove Classroom',
  updateStudentQuestsFromHub = '[Learning] Update Student Quests From Hub',
  setStudentQuestCollection = '[Learning] Set Student Quest Collection',
  setMessages = '[Learning] Set Messages',
  removeProduct = '[Learning] Remove product',
  clearProductLines = '[Learning] Clear Product lines from all state',
  loadViewerAssets = '[Learning] Load Viewer Assets',
  setViewerAssets = '[Learning] Set Viewer Assets',
  initClassroomPins = '[Learning] Initialize Classroom Pins',
  loadClassroomPins = '[Learning] Load Classroom Pins',
  setClassroomPins = '[Learning] Set Classroom Pins',
  setPinState = '[Learning] Set Pin State',
  updateClassroomPin = '[Learning] Update Classroom Pin',
  deleteClassroomPin = '[Learning] Delete Classroom Pin',
}

export const initProductLines = createAction(LEARNING_ACTIONS.initProductLines, props<{ resetCache: boolean }>());
export const initClassrooms = createAction(LEARNING_ACTIONS.initClassrooms);
export const initClassroomIcons = createAction(LEARNING_ACTIONS.initClassroomIcons);
export const loadClassroomProfiles = createAction(LEARNING_ACTIONS.loadClassroomProfiles);
export const resetProductLinesLoading = createAction(LEARNING_ACTIONS.resetProductLines, props<{
  resetCache: boolean,
}>());
export const resetClassroomsLoading = createAction(LEARNING_ACTIONS.resetClassrooms);
export const resetClassroomIconsLoading = createAction(LEARNING_ACTIONS.resetClassroomIcons);
export const resetClassroomSaved = createAction(LEARNING_ACTIONS.resetClassroomSaved);
export const setProductLines = createAction(LEARNING_ACTIONS.setProductLines, props<{ payload: IProductLine[] }>());
export const updateProductComponentPinned = createAction(LEARNING_ACTIONS.updateProductComponentPinned, props<{
  productLineKey: string,
  variantType: string,
  componentId: string,
  isPinned: boolean,
}>());
export const loadSchoolLicenses = createAction(LEARNING_ACTIONS.loadSchoolLicenses, props<{ schoolId: string }>());
export const setSchoolLicenses = createAction(LEARNING_ACTIONS.setSchoolLicenses, props<{
  payload: SchoolLicense[]
}>());
export const removeProduct = createAction(LEARNING_ACTIONS.removeProduct, props<{ payload: string }>());
export const resetClassroom = createAction(LEARNING_ACTIONS.resetClassroom, props<{ classroomId: string }>());
export const loadClassroom = createAction(LEARNING_ACTIONS.loadClassroom, props<{ classroomId: string }>());
export const createClassroom = createAction(LEARNING_ACTIONS.createClassroom, props<{
  classroom: IClassroomCreate,
  schoolId: string,
  message: string
}>());
export const saveExistingClassroom = createAction(LEARNING_ACTIONS.saveExistingClassroom, props<{
  classroom: IClassroomUpdate,
  schoolId: string,
  message: string
}>());
export const classroomSaved = createAction(LEARNING_ACTIONS.classroomSaved, props<{ message: string }>());
export const setClassrooms = createAction(LEARNING_ACTIONS.setClassrooms, props<{ payload: Classroom[] }>());
export const setClassroom = createAction(LEARNING_ACTIONS.setClassroom, props<{ classroom: Classroom }>());
export const setClassroomIcons = createAction(LEARNING_ACTIONS.setClassroomIcons, props<{
  payload: ClassroomIcon[]
}>());
export const setClassroomStudents = createAction(LEARNING_ACTIONS.setClassroomStudents, props<{ students: IUser[] }>());
export const setClassroomLicenses = createAction(LEARNING_ACTIONS.setClassroomLicenses, props<{
  licenses: SchoolLicense[],
}>());
export const updateClassroom = createAction(LEARNING_ACTIONS.updateClassroom, props<{ classroom: Classroom }>());
export const addClassroomStudents = createAction(LEARNING_ACTIONS.addClassroomStudents, props<{ students: IUser[] }>());
export const removeClassroom = createAction(LEARNING_ACTIONS.removeClassroom, props<{ classroomId: string }>());
export const initStudentQuests = createAction(LEARNING_ACTIONS.initStudentQuests, props<{
  classroomId: string,
  productType: ProductType,
}>());
export const updateStudentQuestsFromHub = createAction(LEARNING_ACTIONS.updateStudentQuestsFromHub, props<{
  quests: Quest[],
}>());
export const setStudentQuestCollection = createAction(LEARNING_ACTIONS.setStudentQuestCollection, props<{
  collection: QuestCollection,
}>());
export const setMessages = createAction(LEARNING_ACTIONS.setMessages, props<{ messages: string[] }>());
export const clearProductLines = createAction(LEARNING_ACTIONS.clearProductLines);
export const loadViewerAssets = createAction(LEARNING_ACTIONS.loadViewerAssets, props<{
  productType: ProductType,
  variantType: VariantType | string,
}>());
export const setViewerAssets = createAction(LEARNING_ACTIONS.setViewerAssets, props<{
  viewerAssets: IViewerAsset[],
}>());
export const initClassroomPins = createAction(LEARNING_ACTIONS.initClassroomPins);
export const loadClassroomPins = createAction(LEARNING_ACTIONS.loadClassroomPins);
export const setClassroomPins = createAction(LEARNING_ACTIONS.setClassroomPins, props<{ payload: ClassroomPin[] }>());
export const setPinState = createAction(LEARNING_ACTIONS.setPinState, props<{ active: boolean }>());
export const updateClassroomPin = createAction(LEARNING_ACTIONS.updateClassroomPin, props<{
  classroomId: string, pinUrl: string, productKey: string, assessmentKey: string
}>());
export const deleteClassroomPin = createAction(LEARNING_ACTIONS.deleteClassroomPin, props<{ classroomId: string }>());
