import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@core/feature-flag.service';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';

@Injectable({
  providedIn: 'root',
})
export class ZbPortalRedesignHelpers {
  isZbPortal2024Active(): boolean {
    return this.featureFlagService.isActive(FeatureFlagType.ZBPortal2024);
  }

  constructor(private featureFlagService: FeatureFlagService) {}
}
