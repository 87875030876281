import { ApiResponse } from '@models/api-response';
import { StandardHttpResponse } from '@models/standard-http-response';
import { copyArray, copyObject } from './object-helper';

/**
 * Creates an ApiResponse from a JSON object.
 * @param classCast Constructor function for response type.
 * @param res Object with response and messages fields.
 * @returns ApiResponse with response of type T.
 */
export function apiResponseFromJson<T>(classCast: new () => T, res: StandardHttpResponse): ApiResponse<T> {
  const apiResponse = new ApiResponse<T>();
  apiResponse.messages = res?.messages || [];
  apiResponse.success = true;
  apiResponse.response = copyObject(
    res.response, classCast);
  return apiResponse;
}

/**
 * Creates an ApiResponse from a JSON array.
 * @param classCast Constructor function for response type.
 * @param res Object with response and messages fields.
 * @returns ApiResponse with response array of type T.
 */
export function apiResponseFromArray<T>(classCast: new () => T, res: StandardHttpResponse): ApiResponse<T[]> {
  const apiResponse = new ApiResponse<T[]>();
  apiResponse.messages = res?.messages || [];
  apiResponse.success = true;
  apiResponse.response = copyArray(
    res.response, classCast);
  return apiResponse;
}
