import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'zbp-text-search',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.scss'],
  providers: [
    {

      provide: NG_VALUE_ACCESSOR,
      /* eslint-disable no-use-before-define */
      useExisting: forwardRef(() => TextSearchComponent),
      multi: true,
    },
  ]
})
export class TextSearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() searchTermPlaceholderText: string = 'Search';
  @Input() inputTextboxAriaLabel: string = 'Search for users';
  @Output() searchFunc: EventEmitter<void> = new EventEmitter<void>();

  searchFormControl: FormControl;
  onTouched: any;

  private subscriptions: Subscription[] = [];

  ngOnInit() {
    this.searchFormControl = new FormControl('');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  writeValue(value: any): void {
    this.searchFormControl.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.subscriptions.push(
      this.searchFormControl.valueChanges.subscribe(fn),
    );
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchFormControl.disable();
    } else {
      this.searchFormControl.enable();
    }
  }

  reset() {
    this.writeValue('');
    this.searchFunc.emit();
  }
}
