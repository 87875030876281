import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import {
  QuestAssignedByType,
  QuestClassMap,
  QuestCompletionStatus,
  QuestStatus,
  QuestType
} from '@shared/enums/quest-type';

export interface IQuest {
  questId: string;
  numberOfStarsToEarn: number;
  questType: QuestType;
  classroomId: string;
  questKey: string;
  isComplete: boolean;
  description: string;
}

export class Quest implements IQuest {
  detailKey: string = undefined;
  questId: string = undefined;
  numberOfStarsToEarn: number = undefined;
  questType: QuestType = undefined;
  classroomId: string = undefined;
  groupId?: string = undefined;
  userGroupId?: string = undefined;
  userId?: string = undefined;
  activityId?: string = undefined;
  productType?: ProductType = ProductType.None;
  productDescription?: string = '';
  grade?: GradeType = GradeType.UnknownGrade;
  startOn?: Date | string = undefined;
  expiresOn?: Date | string = undefined;
  completedOn?: Date | string = undefined;
  score?: string = undefined;
  status?: QuestStatus = undefined;
  completionStatus?: QuestCompletionStatus = undefined;
  assignedByType: QuestAssignedByType = undefined;
  questKey: string = undefined;
  isComplete: boolean = undefined;
  description: string = undefined;
  componentId?: string = undefined;
  isRandom?: boolean = undefined;
  name?: string = undefined;
  updatedOn?: Date | string = undefined;
  studentAudioPrompt?: string = undefined;
  closedOn?: Date | string = undefined;
  isClosed: boolean = false;

  constructor(data?: { [key: string]: any }) {
    if (data) {
      this.defaultProps.forEach((propName: string) => {
        if (data[propName]) {
          this[propName] = data[propName];
        }
      });
    }
  }

  setAllowedProperties(data: { [key: string]: any }, defaultDetailKey: string = 'questDetail'): void {
    // Api returns data without specific detail key on quest searches so fallback to that.
    const detailKey = data && data[defaultDetailKey] ? defaultDetailKey : 'questDetail';

    if (data && data[detailKey]) {
      this.getAllowedPropertyNames().forEach((propName: string) => {
        if (data[detailKey][propName]) {
          // Prefers checking if property exists by detail key.
          this[propName] = data[detailKey][propName];
        } else {
          this[propName] = null;
        }
      });
    }
  }

  getAllowedPropertyNames(): string[] {
    return [];
  }

  label(): string {
    return this.description ? this.description : 'Generic Quest';
  }

  typeLabel(): string {
    return 'Quest';
  }

  private defaultProps = [
    'assignedByType',
    'classroomId',
    'completedOn',
    'completionStatus',
    'componentId',
    'description',
    'expiresOn',
    'grade',
    'groupId',
    'isComplete',
    'isRandom',
    'numberOfStarsToEarn',
    'productDescription',
    'productType',
    'questId',
    'questKey',
    'questType',
    'score',
    'startOn',
    'status',
    'updatedOn',
    'userGroupId',
    'userId',
    'studentAudioPrompt',
  ];
}

export class QuestLink {
  readonly isLinkable: boolean;
  readonly questId: string;

  constructor(quest: Quest) {
    this._quest = quest;
    this.isLinkable = this._linkableQuestTypes.some(questType => questType === quest.questType) && !quest.isRandom;
  }

  private readonly _quest: Quest;
  private readonly _linkableQuestTypes: QuestType[] = [
    QuestType.SpellingActivity,
    QuestType.HandwritingActivity,
    QuestType.GumActivity,
    QuestType.Assessment,
  ];

  get quest(): Quest {
    return this._quest;
  }
}

/**
 * Create a quest for a specific quest type.
 *
 * @param {Object} context
 *   See quest-type/quest-types.ts.
 * @param {QuestType} type
 *   The quest type enum.
 * @param {Object} values
 *   Values to set for the quest.
 */
export function questFactory(context: Object, type: QuestType, values: any): Quest {
  const typeClass = QuestClassMap[type];
  if (typeClass && context[typeClass]) {
    return new context[typeClass](values);
  }

  throw new TypeError(`${typeClass} is not defined`);
}


/**
 * Activity
 * - typical data structure used for activities and topics.
 */
export interface IActivity { quests: Quest[], childQuests: Quest[] }
