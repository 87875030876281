import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ZbpIconNames } from '../branded-login/login-form/login-form-model';

/**
 * Used to display an icon with a CTA to a user around account-related form actions
 * Used throughout the account creation and management flows
 */
@Component({
  selector: 'zbp-login-information-cta',
  templateUrl: './login-information-cta.component.html',
  styleUrls: ['./login-information-cta.component.scss'],
})
export class LoginInformationCtaComponent {
  constructor(private router: Router,
  ) { }

  /**
   * There are a lot of styling options within this component,
   * But the general styling is very consistent across the various usages
   */
  @Input() iconSrc?: string = '';
  @Input() iconAlt: string = '';
  @Input() zbpIconName?: ZbpIconNames = null;
  @Input() zbpIconFillColor?: string = '';
  @Input() headingText: string = '';
  @Input() bodyHTML: string = '';
  @Input() showBackToLoginLinkButton: boolean = false;
  @Input() showSendAgainSection: boolean = false;
  @Output() sendAgainClickedHandler: EventEmitter<Event> = new EventEmitter();
  @Input() showPrimaryButton: boolean = false;
  @Input() primaryButtonLabel: string = '';
  @Input() primaryButtonIconName: ZbpIconNames = null;
  @Output() primaryButtonClickHandler: EventEmitter<Event> = new EventEmitter();
  @Input() primaryButtonTooltip: string = '';
  @Input() primaryButtonAriaLabel: string = '';

  handleSendAgain(event: Event): void {
    this.sendAgainClickedHandler.emit(event);
  }

  handlePrimaryButtonClick(event: Event): void {
    this.primaryButtonClickHandler.emit(event);
  }

  backToLogin(): void {
    this.router.navigateByUrl('/login/user');
  }
}
