import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@models/api-response';
import { IUserMessage, IUserMessageCreateRequest, IUserMessageUpdateRequest } from '@models/user/user-message';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './appconfig.service';

// not currently needed, but will be once the user to user messages are added
// import { IPagedResponse, PagedResponse } from '@models/paged-response';

@Injectable({
  providedIn: 'root',
})
export class UserMessageService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  private getParentMesssageBaseUrl(): string {
    return `${this.appConfig.apiUrl}/classroom/parent-message`;
  }

  private getParentMessageForClassroomUrl(classroomId?: string): string {
    return classroomId ? `${this.getParentMesssageBaseUrl()}?classroomId=${classroomId}` : this.getParentMesssageBaseUrl();
  }

  getClassroomMessageForParent(classroomId: string): Observable<ApiResponse<IUserMessage>> {
    return this.http.get<ApiResponse<IUserMessage>>(this.getParentMessageForClassroomUrl(classroomId))
      .pipe(
        map(res => new ApiResponse<IUserMessage>(true, {
          response: res.response,
          messages: [...res.messages],
        })),
      );
  }

  addClassroomMessageForParent(userMessage: IUserMessageCreateRequest): Observable<ApiResponse<IUserMessage>> {
    return this.http.post<ApiResponse<IUserMessage>>(this.getParentMesssageBaseUrl(), userMessage)
      .pipe(
        map(res => new ApiResponse<IUserMessage>(true, {
          response: res.response,
          messages: [...res.messages],
        })),
      );
  }

  updateClassroomMessageForParent(userMessage: IUserMessageUpdateRequest): Observable<ApiResponse<IUserMessage>> {
    return this.http.patch<ApiResponse<IUserMessage>>(this.getParentMesssageBaseUrl(), userMessage)
      .pipe(
        map(res => new ApiResponse<IUserMessage>(true, {
          response: res.response,
          messages: [...res.messages],
        })),
      );
  }

  deleteClassroomMessageForParent(messageId: string): Observable<ApiResponse<boolean>> {
    return this.http.request('delete', `${this.getParentMesssageBaseUrl()}?messageId=${messageId}`)
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
      );
  }
}
