import { ApiResponse } from './api-response';

export interface IPagedResponse<T> {
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  response: T;
  messages: string[];
}

export class PagedResponse<T> extends ApiResponse<T> implements IPagedResponse<T> {
  pageNumber: number = 1;
  pageSize: number = 25;
  totalPages: number = 1;
  totalRecords: number = 0;

  constructor(success?: boolean, data?: IPagedResponse<T>) {
    super(success, data);

    this.pageNumber = data.pageNumber;
    this.pageSize = data.pageSize;
    this.totalPages = data.totalPages;
    this.totalRecords = data.totalRecords;
  }

  hasMore(): boolean {
    return this.pageSize * this.totalPages < this.totalRecords;
  }
}
