import { DNAME } from '@shared/zb-object-helper/class-cast';
import { toString } from 'lodash';

/**
 * Does a full copy on an object including nested objects and arrays (deep copy)
 * Removes attributes that are not in the destination class model
 * Also support typing of attributes and arrays.  If you want a nested attribute typed (for access
 * to its functions) then add the @ClassCast decorator to the attribute in your model.  example:
 *
 *    @ClassCast(Employee)
 *    public employee: Employee = undefined;
 *
 * @param objectIn   The generic object we want to create a class object from
 * @param classCast  The class constructor we want to create
 * @param allowExtras (optional, defaults to true) This will place anything in the JSON into the instance,
 *                    even if not in the model.
 */
export function copyObject<T>(objectIn: Object, classCast: new () => T, allowExtras: boolean = true): T {
  /* eslint-disable */
  if (classCast === null || classCast === undefined) {
    // add any other weird cases to this check as they are discovered
    throw new Error('Cannot copy object with class cast parameter: ' + toString(classCast));
  }
  if (objectIn instanceof classCast) {
    return objectIn;
  }
  const newInstance: T = new classCast();
  const originalEntries = Object.entries(newInstance)
  const descriptors = Object.getOwnPropertyDescriptors(classCast.prototype);
  const settableDescriptors = Object.keys(descriptors).filter(key => {
    const descriptor = descriptors[key];
    return descriptor && (descriptor.set || (descriptor.writable && descriptor.enumerable));
  }).sort((a, b) => {
    const aHasSet = descriptors[a].set;
    const bHasSet = descriptors[b].set;

    if (aHasSet && !bHasSet) {
      return 1;
    }

    if (!aHasSet && bHasSet) {
      return -1;
    }

    return 0;
  }).reduce((result, key) => {
    result[key] = descriptors[key];
    return result;
  }, {});
  const objectEntries = Object.entries(settableDescriptors);
  let allEntries = originalEntries.concat(objectEntries);
  // TODO: this is deprecated. This will place anything in the JSON into the instance, even if not in the model.
  if (allowExtras) {
    allEntries = Object.entries(objectIn).concat(allEntries);
  }
  const allNonPrivateEntries = allEntries.filter(entry => !entry[0].startsWith('_'));

  allNonPrivateEntries.forEach((entry) => {
    const [propertyKey, ] = entry;
    const attributeValue: any = objectIn[propertyKey];

    // check if the attribute is generic, custom object, or custom array
    // @ts-ignore
    if (attributeValue !== undefined) {
      if (!newInstance[DNAME] || !newInstance[DNAME][propertyKey]) {
        assignGenericAttribute(newInstance, propertyKey, attributeValue);
      } else {
        if (newInstance[DNAME][propertyKey] instanceof Array && attributeValue instanceof Array) {
          assignArrayAttribute(newInstance, propertyKey, attributeValue);
        } else {
          assignObjectAttribute(newInstance, propertyKey, attributeValue);
        }
      }
    }
  });
  if (!!newInstance['onInit'] && typeof newInstance['onInit'] === 'function') {
    //@ts-ignore
    newInstance.onInit();
  }

  return newInstance;
}

/**
 *
 * @param arrayIn    The array of generic objects we want to create class objects from
 * @param classCast  The class constructor we want to create
 */
export function copyArray<T>(arrayIn: any, classCast: new () => T): T[] {
  const arr: T[] = [];
  arrayIn.forEach((obj) => {
    arr.push(copyObject(obj, classCast));
  });
  return arr;
}

/**
 * The model did not define a class type - assign as a generic
 *
 * @param parent
 * @param attributeName
 */
export function assignGenericAttribute(parent: any, attributeName: string, attributeValue: any): void {
  parent[attributeName] = attributeValue;
}

/**
 * The model defined an class array.  Create a full class object for each item in array
 *
 * @param parent
 * @param attributeName
 */
export function assignArrayAttribute(parent: any, attributeName: string, attributeValue: any): void {
  const arr: any[] = [];
  // for (const obj of attributeValue as any) {
  //   arr.push(copyObject(obj, parent[DNAME][attributeName][0]));
  // }
  attributeValue.forEach((obj) => {
    arr.push(copyObject(obj, parent[DNAME][attributeName][0]));
  });
  parent[attributeName] = arr;
}

/**
 * The model defined a class attribute.  Create a full class object for this attribute
 *
 * @param parent
 * @param attributeName
 */
export function assignObjectAttribute(parent: any, attributeName: string, attributeValue: any): void {
  // value is null when we modeled an object that is not present in the json string - set to null
  if (!attributeValue) {
    parent[attributeName] = null;
  } else if (attributeValue instanceof Array) {
    parent[attributeName] = copyArray(attributeValue, parent[DNAME][attributeName]);
  } else {
    parent[attributeName] = copyObject(attributeValue, parent[DNAME][attributeName]);
  }
}

/**
 * Creates a class typed object from string input
 *
 * @param stringIn   The json string we want to create a class object from
 * @param classCast  The class constructor we want to create
 */
export function objectFromString<T>(stringIn: string, classCast: new () => T): T {
  return copyObject(JSON.parse(stringIn), classCast);
}

export function instantiateFromJson<T>(json: any, classCast: new () => T): T {
  if (json instanceof Array) {
    return copyArray(json, classCast) as unknown as T;
  }
  return copyObject(json, classCast);
}

// This should work better to do mass find and replace with regex on new myClass() instantiations.
export function instantiateFromJsonReverseParam<T>(classCast: new () => T, json: any): T {
  return instantiateFromJson(json, classCast);
}
