import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { KeyValuePair } from '@models/key-value-pair';
import { Helpers } from '@shared/helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersApiService {

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  searchOrders(params: KeyValuePair[]): Observable<any> {
    return this.http.get(Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/order/search', params));
  }

  getOrder(stageHeaderId: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/order/${stageHeaderId}`;
    return this.http.get(url);
  }

  updateOrderAdmin(bookmasterOrderStageHeaderId: string, digitalAdminName: string, digitalAdminEmail: string)
    : Observable<any> {
    const url = `${this.appConfig.apiUrl}/order/digital-admin`;
    const data = { bookmasterOrderStageHeaderId, digitalAdminName, digitalAdminEmail };
    return this.http.patch(url, data);
  }

  searchOrderAlerts(params: KeyValuePair[]): Observable<any> {
    return this.http.get(Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/order/alert/search', params));
  }

  getAlertCount(): Observable<any> {
    const url = `${this.appConfig.apiUrl}/order/alert/count`;
    return this.http.get(url);
  }

  archiveOrder(stageHeaderId: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/order/archive/${stageHeaderId}`;
    return this.http.patch(url, {});
  }

  removeOrderLineItem(stageHeaderId: string, stageLineItemId: string): Observable<any> {
    const url = `${this.appConfig.apiUrl}/order/${stageHeaderId}/line-item/${stageLineItemId}/alerts/resolve`;
    return this.http.patch(url, {});
  }
}
