export enum AvatarStickerStatusType {
  New = 'New',
  Viewed = 'Viewed',
  Selected = 'Selected',
  NotEarned = 'NotEarned',
}

export enum AvatarStarEarnType {
  AvatarTease = 'AvatarTease'
}
