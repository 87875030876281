<main *ngIf="!isLoading" id="brandedLoginPage" [class]="brandClass">
  <zbp-back-to-website-link *ngIf="!isHighlights" class="fixed-position-top-left"></zbp-back-to-website-link>
  <!-- HL Portal and ZB Designs are slightly different, but similar enough to reuse the same component -->
  <div class="content-pane" [ngClass]="isHighlights ? 'col-lg-6 col-xl-6 col-xxl-6 col-sm-10 col-xs-12' : ''">
    <figure class="brand-logo-container">
      <img class="brand-logo-img" [src]="logoSrc" [alt]="logoAlt">
    </figure>
    <div class="login-form-container">
      <zbp-login-form></zbp-login-form>
    </div>
    <!-- HL Portal footer spans half page width -->
    <zbp-footer *ngIf="isHighlights" class="w-100"></zbp-footer>
  </div>
  <!-- ZB Portal footer spans full page width -->
  <zbp-footer *ngIf="!isHighlights" class="w-100"></zbp-footer>
</main>
 