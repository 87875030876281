import { Component } from '@angular/core';
import { OauthResponse } from '@models/oauth-response';
import { OAuth2Provider } from '@shared/enums/oauth2-provider.enum';
import { OAuth2Service } from '../oauth2/oauth2.service';

@Component({
  selector: 'zbp-login-sso',
  templateUrl: './login.sso.component.html',
  styleUrls: ['./login.sso.component.scss']
})
export class LoginSsoComponent {

  constructor(private oauth2Service: OAuth2Service) {
  }

  ssoLogin(provider: OAuth2Provider) {
    this.oauth2Service.getSsoUrl(provider)
      .subscribe((res: OauthResponse) => {
        window.location.href = res.url;
      });
  }

  protected readonly OAuth2Provider = OAuth2Provider;
}
