import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { UserService } from '@core/user.service';

@Component({
  selector: 'zbp-login',
  template: `<router-outlet></router-outlet>`
})
export class LoginComponent implements OnInit {
  constructor(
    private titleService: Title,
    private appConfig: AppConfigService,
    public userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Login');

    const route = this.appConfig.findLoginRoute();
    this.router.navigate([route]);
  }
}
