import { environment } from '@environments/environment';

export const hlFooterLinks = {
  systemRequirements: `${environment.assetUrl}content/shared/TechnicalRequirements_HighlightsPortal.pdf`,
  legal: 'https://earlylearning.highlights.com/policies/terms-of-service',
  privacy: 'https://earlylearning.highlights.com/policies/privacy-policy',
  support: 'https://earlylearning.highlights.com/pages/contact-us',
};

export const zbFooterLinks = {
  systemRequirements: `${environment.assetUrl}content/shared/zbportal-technical-requirements.pdf`,
  legal: 'https://www.zaner-bloser.com/policies/terms-of-use.php',
  privacy: 'https://www.zaner-bloser.com/policies/privacy-policy.php',
  support: 'https://www.zaner-bloser.com/contact/tech-support.php',
};
