import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '@core/appconfig.service';
import { ApiResponse } from '@models/api-response';
import { KeyValuePair } from '@models/key-value-pair';
import { IProductLine } from '@models/product-line';
import { ProductDownload } from '@shared/enums/product-download';
import { Helpers } from '@shared/helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminProductsApiService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  getProductUploadUrl(): string {
    return `${this.appConfig.apiUrl}/product/spreadsheet`;
  }

  updateProducts(formData: FormData): Observable<any> {
    // Adds a content-type that's removed later so application/json isn't applied by default.
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    return this.http.post(this.getProductUploadUrl(), formData, { headers, responseType: 'json' });
  }

  getProducts(productKey: ProductDownload, params: HttpParams): Observable<any> {
    return this.http.get(this.getProductUploadUrl(), { params, responseType: 'blob' });
  }

  getProductLineAnnouncements(params: KeyValuePair[] = []): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/product-line-announcement', params);
    return this.http.get(url);
  }

  getProductLinesForAnnouncement(announcementId: string): Observable<ApiResponse<IProductLine[]>> {
    return this.getProductLineAnnouncements([{ key: 'announcementId', value: announcementId }]);
  }

  getProductLines(params: KeyValuePair[] = []): Observable<any> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/product-line', params);
    return this.http.get(url);
  }
}
