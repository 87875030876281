import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { AppConfigService } from '../appconfig.service';
import { AuthenticationService } from '../authentication.service';
import { AccessControlService } from '../services/access-control/access-control.service';
import { UserService } from '../user.service';

/**
 * AuthenticationGuard ensures the user is authenticated before a route can be accessed.
 * Unauthenticated users will be redirected to the login
 */
@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthenticationService,
    private accessService: AccessControlService,
    private appConfigService: AppConfigService,
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const { url } = state;

    return this.checkLogin(url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  checkLogin(url: string): Observable<boolean> {
    // if the route does not require authentication proceed
    if (this.accessService.isUnauthorizedRoute(url)) {
      return of(true);
    }
    return this.authService.authStatus
      .pipe(
        skipWhile(status => status === null),
        map((status) => {
          // when the user is logged in
          if (status) {
            // verify the user has agreed to the EULA
            if (!this.userService.hasAgreed && url !== '/agreement') {
              this.router.navigateByUrl('/agreement');
              return false;
            }
            return true;
          }
          // User is NOT actively logged in
          // Store the attempted URL for redirect later
          this.appConfigService.redirectUrl = url;

          // Navigate to the login page.
          const route = this.appConfigService.findLoginRoute();
          this.router.navigateByUrl(route);
          return false;
        })
      );
  }
}
