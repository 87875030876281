export const teacherAreaIcon = `<svg xmlns="http://www.w3.org/2000/svg"
     width="59"
     height="49"
     viewBox="0 0 59 49"
     fill="none">
  <path
      d="M2.12109 40.3017V4.51866C2.12109 3.09967 3.26862 1.95215 4.6876 1.95215H54.3397C55.7587 1.95215 56.9062 3.09967 56.9062 4.51866V44.8301C56.9062 46.2491 55.7587 47.3966 54.3397 47.3966H39.7303"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="2.4678"
      stroke-linecap="round"
      stroke-linejoin="round"
  />
  <path
      d="M22.2704 12.8364C26.4409 12.8364 29.8342 16.2297 29.8342 20.4002C29.8342 24.5708 26.4409 27.964 22.2704 27.964C18.0998 27.964 14.7066 24.5708 14.7066 20.4002C14.7066 16.2297 18.0998 12.8364 22.2704 12.8364ZM22.2704 11.6025C17.4088 11.6025 13.4727 15.5387 13.4727 20.4002C13.4727 25.2618 17.4088 29.1979 22.2704 29.1979C27.1319 29.1979 31.0681 25.2618 31.0681 20.4002C31.0681 15.5387 27.1319 11.6025 22.2704 11.6025Z"
      fill="var(--zbp-svg-primary-color, black)"
  />
  <path
      d="M25.6387 31.1217H16.7176C13.7192 31.1217 11.3008 33.5525 11.3008 36.5385V46.4591H31.0678V38.7842H43.2094C43.2094 38.7842 47.7255 38.7842 47.7255 31.1094H25.6387V31.1217Z"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.2339"
      stroke-linecap="round"
      stroke-linejoin="round"
  />
  <path
      d="M46.4278 25.7646C47.1311 25.7646 47.711 26.3446 47.711 27.0479V31.1198H45.1445V27.0479C45.1445 26.3446 45.7245 25.7646 46.4278 25.7646Z"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.2339"
      stroke-miterlimit="10"
  />
  <path d="M35.1914 9.41602H50.1339"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.2339"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
  <path d="M35.1914 13.4629H50.1339"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.2339"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
  <path d="M35.1914 17.5986H50.1339"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.2339"
        stroke-linecap="round"
        stroke-linejoin="round"
  />
</svg>`;
