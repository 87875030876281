import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ApiResponse } from '@models/api-response';
import { ClassroomProgressReport } from '@models/classroom-progress-report';
import { ClassroomQuestReport } from '@models/classroom-quest-report';
import { KeyValuePair } from '@models/key-value-pair';
import { SchoolLicense } from '@models/license/school-license';
import { IPagedResponse } from '@models/paged-response';
import { IActivity, IQuest, Quest, questFactory } from '@models/quest';
import { QuestGroup } from '@models/quest-group';
import {
  AbandonQuestRequest,
  CreateQuestRequest,
  ReassignQuestRequest,
  UpdateQuestRequest
} from '@models/quest-request';
import { ReassignQuestResponse, UpdateQuestResponse } from '@models/quest-response';
import { QuestScoreReport } from '@models/quest-score-report';
import questTypes from '@models/quest-type/quest-types';
import {
  IAssessmentObjectiveManualPart,
  ISuperkidsLegacyAssessmentPartManualScore,
  ISuperkidsLegacyAssessmentPartProgressDetail,
  ISuperkidsLegacyAssessmentScoreDetail
} from '@models/score';
import { StudentActivityReport } from '@models/student-activity-report';
import { StudentProgressReport } from '@models/student-progress-report';
import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { QuestAssignedByType, QuestStatus, QuestType } from '@shared/enums/quest-type';
import { ScoreBenchmark } from '@shared/enums/score-benchmark';
import { Helpers } from '@shared/helpers';
import { copyArray } from '@shared/zb-object-helper/object-helper';
import {
  instantiateApiResponseFromArray,
  instantiateApiResponseFromJson
} from '@shared/zb-rxjs-operators/rxjs-operators';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TopicService } from '../learning/products/topic/topic.service';
import { AppConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root',
})
export class QuestService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  private getQuestUrl(action: string = ''): string {
    if (action === 'reassign') {
      return `${this.appConfig.apiUrl}/teacher/quest/reassign`;
    }

    const actionPart = action ? `/${action}` : '';
    return `${this.appConfig.apiUrl}/quest${actionPart}`;
  }

  private getQuestReportUrl(action: string, baseEndpoint: string, classroomId: string, groupId?: string, schoolYear?: string, showPastYearReports?: boolean): string {
    let baseUrl = `${this.appConfig.apiUrl}/${baseEndpoint}/classroom/${classroomId}/${action}`;
    if (groupId) {
      if (action === 'assigned-work') {
        baseUrl += `?groupId=${groupId}`;
      } else {
        baseUrl += `/group/${groupId}`;
      }
    }
    if (baseEndpoint === 'export') {
      const offset = moment().utcOffset();
      const separator = baseUrl.includes('?') ? '&' : '?';
      baseUrl += `${separator}offsetInMinutes=${offset}`;
    }

    baseUrl += this.addSchoolYear(baseUrl, schoolYear);
    baseUrl += this.addShowPastYearReports(baseUrl, showPastYearReports);

    return baseUrl;
  }

  private getParentsQuestReportUrl(action: string, baseEndpoint: string, classroomId: string, studentId: string): string {
    return `${this.appConfig.apiUrl}/${baseEndpoint}/classroom/${classroomId}/${action}/user/${studentId}`;
  }

  // The assessment question report is under a differet endpoint than other reports because.
  private getQuestAssessmentReportUrl(activityId: string, action: string = 'question-report', schoolYear: string = null, showPastYearReports: boolean = false): string {
    let baseUrl = `${this.appConfig.apiUrl}/digital-resources/assessment/${activityId}/${action}`;

    baseUrl += this.addSchoolYear(baseUrl, schoolYear);
    baseUrl += this.addShowPastYearReports(baseUrl, showPastYearReports);

    return baseUrl;
  }

  private getQuestAssessmentReportUrlForParents(activityId: string): string {
    return `${this.appConfig.apiUrl}/digital-resources/assessment/${activityId}/question-report`;
  }

  private getProgressReportUrl(action: string, questKey: string, product: ProductType, grade: GradeType | string, id: string, schoolYear?: string, showPastYearReports?: boolean): string {
    let baseUrl = `${this.appConfig.apiUrl}/digital-resources/assessment/progress-report/`;

    if (action === 'classroom') {
      baseUrl += `${questKey}/product/${product}/grade/${grade}/${action}/${id}`;
    } else {
      baseUrl += `product/${product}/grade/${grade}/classroom/${id}/${action}/${questKey}`;
    }

    baseUrl += this.addSchoolYear(baseUrl, schoolYear);
    baseUrl += this.addShowPastYearReports(baseUrl, showPastYearReports);

    return baseUrl;
  }

  private getProgressExportUrl(action: string, questKey: string, product: ProductType, grade: GradeType | string, id: string, schoolYear?: string, showPastYearReports?: boolean): string {
    let  baseUrl = `${this.appConfig.apiUrl}/digital-resources/assessment/progress-report-export/`;
    if (action === 'classroom') {
      baseUrl += `${questKey}/product/${product}/grade/${grade}/${action}/${id}`;
    } else {
      baseUrl += `product/${product}/grade/${grade}/classroom/${id}/${action}/${questKey}`;
    }

    baseUrl += this.addSchoolYear(baseUrl, schoolYear);
    baseUrl += this.addShowPastYearReports(baseUrl, showPastYearReports);

    return baseUrl;
  }

  private getManualScoreUrl(questKey: string, product: ProductType, grade: GradeType | string, classroomId: string, userId: string, objective: string, schoolYear?: string, showPastYearReports?: boolean): string {
    let baseUrl = `${this.appConfig.apiUrl}/digital-resources/assessment/manual-score/${questKey}/product/${product}/grade/${grade}/classroom/${classroomId}/user/${userId}/objective/${objective}`;

    baseUrl += this.addSchoolYear(baseUrl, schoolYear);
    baseUrl += this.addShowPastYearReports(baseUrl, showPastYearReports);

    return baseUrl;
  }

  private addSchoolYear(baseUrl: string, schoolYear?: string): string {
    const separator = baseUrl.includes('?') ? '&' : '?';
    return schoolYear ? `${separator}schoolYear=${schoolYear}` : '';
  }

  private addShowPastYearReports(baseUrl: string, showPastYearReports?: boolean): string {
    const separator = baseUrl.includes('?') ? '&' : '?';
    return showPastYearReports ? `${separator}showPastYearReports=${showPastYearReports}` : '';
  }

  createQuest(data: CreateQuestRequest): Observable<ApiResponse<Partial<IQuest[]>>> {
    const values: CreateQuestRequest = {
      ...data,
      startOn: this.normalizeToDateTime(data.startOn),
    };
    if (data.expiresOn) {
      values.expiresOn = this.normalizeToDateTime(data.expiresOn);
    }

    return this.http.post<ApiResponse<IQuest[]>>(this.getQuestUrl(), values)
      .pipe(
        map((r: ApiResponse<Partial<IQuest[]>>) => new ApiResponse<Partial<IQuest[]>>(true, r)),
      );
  }

  getQuests(params: KeyValuePair[]): Observable<ApiResponse<Quest[]>> {
    return this.findQuests(params);
  }

  getParentsQuestStudentScores(classroomId: string, studentId: string, pageNumber: number, classroomLicenses?: SchoolLicense[]): Observable<IPagedResponse<QuestScoreReport[]>> {
    const params = {
      pageSize: 25,
      pageNumber,
    };
    return this.http.get(`${this.appConfig.apiUrl}/report/classroom/${classroomId}/assigned-work/user/${studentId}`, { params })
      .pipe(
        map(res => res as IPagedResponse<QuestScoreReport[]>),
        map((res) => {
          if (res.response && res.response.length > 0) {
            if (classroomLicenses) {
              res.response = res.response
                .filter(questReport => (classroomLicenses
                  .find(l => l.productType === questReport.productType
                  && Helpers.mapVariantToGradeType(l.variant) === Helpers.mapVariantToGradeType(questReport.grade))));
            }
          }
          const copyResponse = { ...res };
          copyResponse.response = copyArray(res.response, QuestScoreReport);
          return copyResponse;
        })
      );
  }

  getQuestsByGroupId(id: string, isAggregate?: boolean): Observable<ApiResponse<Quest[]>> {
    const params = [
      { key: 'groupId', value: id },
      { key: 'activeOnly', value: false },
      { key: 'excludeAggregateQuests', value: !isAggregate },
    ];

    this.getAllProductTypes().forEach((p) => {
      params.push({ key: 'productTypes', value: p });
    });
    return this.findQuests(params);
  }

  getAssignedClassroomQuests(classroomId: string, statusType: QuestStatus = QuestStatus.Active): Observable<ApiResponse<QuestGroup[]>> {
    const productTypes: string[] = this.getAllProductTypes();
    const params = new HttpParams({
      fromObject: {
        statusType,
        assignedByType: 'Teacher',
        classroomId,
        productTypes,
      },
    });

    return this.http.get<ApiResponse<QuestGroup[]>>(this.getQuestUrl('group'), { params })
      .pipe(
        map(res => new ApiResponse<QuestGroup[]>(true, res)),
      );
  }

  updateQuests(data: UpdateQuestRequest[]): Observable<ApiResponse<UpdateQuestResponse[]>> {
    const requests = data.map((request: UpdateQuestRequest) => {
      if (request.startOn) {
        request.startOn = this.normalizeToDateTime(request.startOn);
      }
      if (request.expiresOn) {
        request.expiresOn = this.normalizeToDateTime(request.expiresOn);
      }
      if (request.closedOn) {
        request.closedOn = this.normalizeToDateTime(request.closedOn);
      }
      return request;
    });

    return this.http.patch<ApiResponse<UpdateQuestResponse[]>>(this.getQuestUrl(), requests)
      .pipe(
        map(res => new ApiResponse<UpdateQuestResponse[]>(true, res)),
      );
  }

  deleteQuestGroup(groupId: string, grade: GradeType | string, productType: ProductType, classroomId: string): Observable<ApiResponse<string[]>> {
    const data = {
      groupId,
      grade,
      productType,
      classroomId,
      assignedByType: QuestAssignedByType.Teacher,
      userIds: [],
    } as AbandonQuestRequest;
    return this.http.request('delete', this.getQuestUrl(), { body: data })
      .pipe(
        map(res => new ApiResponse<string[]>(true, res)),
      );
  }

  removeStudentFromQuestGroup(groupId: string, questType: QuestType, productType: ProductType, usersToRemove: string[]): Observable<ApiResponse<ReassignQuestResponse>> {
    const data = {
      groupId,
      questType,
      productType,
      userIdsToRemove: usersToRemove,
      userIdsToAdd: [],
    } as ReassignQuestRequest;
    return this.http.patch(this.getQuestUrl('reassign'), data)
      .pipe(
        map(res => new ApiResponse<ReassignQuestResponse>(true, res)),
      );
  }

  addStudentsToQuestGroup(groupId: string,
    questType: QuestType,
    productType: ProductType,
    numberOfStarsToEarn: number,
    usersToAdd: string[]): Observable<ApiResponse<ReassignQuestResponse>> {
    const data = {
      groupId,
      questType,
      productType,
      numberOfStarsToEarn,
      userIdsToAdd: usersToAdd,
      userIdsToRemove: [],
    } as ReassignQuestRequest;
    return this.http.patch(this.getQuestUrl('reassign'), data)
      .pipe(
        map(res => new ApiResponse<ReassignQuestResponse>(true, res)),
      );
  }

  getClassroomQuestReport(classroomId: string, groupId?: string, schoolYear?: string, showPastYearReports?: boolean, classroomLicenses?: SchoolLicense[]): Observable<ApiResponse<ClassroomQuestReport[]>> {
    if (!classroomId) {
      return this.getNoClassroomError();
    }

    const url = this.getQuestReportUrl(
      'assigned-work',
      'report',
      classroomId,
      groupId,
      schoolYear,
      showPastYearReports);
    return this.http.get(url)
      .pipe(
        map((res: any) => {
          if (res.response && res.response.length > 0) {
            if (classroomLicenses) {
              res.response = res.response
                .filter(questReport => (classroomLicenses
                  .find(l => l.productType === questReport.productType
                  && Helpers.mapVariantToGradeType(l.variant) === Helpers.mapVariantToGradeType(questReport.grade))));
            }
          }
          return res;
        }),
        instantiateApiResponseFromArray(ClassroomQuestReport),
      );
  }

  getParentsQuestReport(classroomId: string, studentId: string): Observable<ApiResponse<ClassroomQuestReport[]>> {
    if (!classroomId) {
      return this.getNoClassroomError();
    }
    const url = this.getParentsQuestReportUrl('assigned-work', 'report', classroomId, studentId);
    return this.http.get(url)
      .pipe(
        instantiateApiResponseFromArray(ClassroomQuestReport),
      );
  }

  static isAggregate(quest: Quest): boolean {
    return quest.questType === QuestType.AggregateQuest;
  }

  static isCustomActivity(license: SchoolLicense, activity: IActivity): boolean {
    const quest = (TopicService.getQuest(activity.quests)) as any;
    return QuestService.isHandwriting(license.productType)
      && (quest.topicType && (quest.topicType === 'CustomFreeWrite'));
  }

  static isSpelling(productType: ProductType): boolean {
    return (productType === ProductType.spcn2022
      || productType === ProductType.spcn2020tx
      || productType === ProductType.spcn2020txs
      || productType === ProductType.spcn2016n);
  }

  static isHandwriting(productType: ProductType): boolean {
    return (productType === ProductType.hw2020n
      || productType === ProductType.hw2025n
      || productType === ProductType.hw2020tx
      || productType === ProductType.hw2020txs
      || productType === ProductType.laesc2020n
      || productType === ProductType.laesc2020tx
      || productType === ProductType.laesc2020txs);
  }

  static isSuperkids(productType: ProductType): boolean {
    return (productType === ProductType.sk2015
      || productType === ProductType.sk2017);
  }

  static isFreeWrite(): boolean {
    return false;
  }

  private findQuests(params: KeyValuePair[]): Observable<ApiResponse<Quest[]>> {
    const url = Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/quest', params);
    return this.http.get<ApiResponse<Quest[]>>(url)
      .pipe(
        map((r) => {
          const quests = r.response.map(q => questFactory(questTypes, q.questType, q));
          return new ApiResponse<Quest[]>(true, { response: quests, messages: [] });
        }),
      );
  }

  getLatestStudentActivityReport(classroomId: string, userId?: string, schoolYear?: string, showPastYearReports?: boolean,  classroomLicenses?: SchoolLicense[]): Observable<ApiResponse<StudentActivityReport[]>> {
    if (!classroomId) {
      return this.getNoClassroomError();
    }
    const action = userId ? `independent-work/users/${userId}` : 'independent-work';
    return this.http.get(this.getQuestReportUrl(action, 'report', classroomId, null, schoolYear, showPastYearReports))
      .pipe(
        map((res: any) => {
          if (res.response && res.response.length > 0) {
            if (classroomLicenses) {
              res.response = res.response
                .filter(questReport => (classroomLicenses
                  .find(l => l.productType === questReport.productType
                  && Helpers.mapVariantToGradeType(l.variant) === Helpers.mapVariantToGradeType(questReport.grade))));
            }
            res.response = res.response
              .map((report) => {
                if (!report.grade && report.topicKey) {
                  const gradeMatches = report.topicKey.match(/G([K0-9]+)/);
                  if (gradeMatches && gradeMatches.length > 1) {
                    return {
                      ...report,
                      grade: Helpers.mapGradeToGradeType(gradeMatches[1]),
                    };
                  }
                }
                return report;
              });
          }
          return res;
        }),
        instantiateApiResponseFromArray(StudentActivityReport),
      );
  }

  getReportExport(type: string, classroomId: string, userOrGroupId?: string, schoolYear?: string, showPastYearReports?: boolean): Observable<Blob> {
    if (!classroomId) {
      return of(null);
    }

    const action = userOrGroupId && type === 'independent-work' ? `${type}/users/${userOrGroupId}` : type;
    const groupId = type !== 'independent-work' ? userOrGroupId : null;
    return this.http.get(
      this.getQuestReportUrl(action, 'export', classroomId, groupId, schoolYear, showPastYearReports), {
        responseType: 'blob'
      });
  }

  getQuestScoreReport(classroomId: string, questKey: string, groupId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<ApiResponse<QuestScoreReport>> {
    if (!classroomId) {
      return this.getNoClassroomError();
    }
    const action = `assigned-work/${questKey}/group/${groupId}`;
    return this.http.get(this.getQuestReportUrl(action, 'report', classroomId, null, schoolYear, showPastYearReports))
      .pipe(
        instantiateApiResponseFromJson(QuestScoreReport),
      );
  }

  getQuestScoreReportForParents(classroomId: string, questKey: string, groupId: string, userId: string)
    : Observable<ApiResponse<QuestScoreReport>> {
    const action = `assigned-work/${questKey}/group/${groupId}/user/${userId}`;
    return this.http.get<ApiResponse<QuestScoreReport>>(this.getQuestReportUrl(action, 'report', classroomId))
      .pipe(
        map(res => new ApiResponse<QuestScoreReport>(true, res)),
      );
  }

  getQuestAssessmentReport(activityId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<ApiResponse<ISuperkidsLegacyAssessmentScoreDetail>> {
    const url = this.getQuestAssessmentReportUrl(activityId, 'question-report', schoolYear, showPastYearReports);
    return this.http.get(url)
      .pipe(
        instantiateApiResponseFromJson(ISuperkidsLegacyAssessmentScoreDetail),
      );
  }

  getQuestAssessmentReportForParent(activityId: string): Observable<ApiResponse<ISuperkidsLegacyAssessmentScoreDetail>> {
    const url = this.getQuestAssessmentReportUrlForParents(activityId);
    return this.http.get(url)
      .pipe(
        instantiateApiResponseFromJson(ISuperkidsLegacyAssessmentScoreDetail),
      );
  }

  getQuestAssessmentExport(activityId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<Blob> {
    const url = this.getQuestAssessmentReportUrl(
      activityId,
      'question-report-export',
      schoolYear,
      showPastYearReports);
    return this.http.get(url, { responseType: 'blob' });
  }

  getStudentProgressReport(product: ProductType, grade: GradeType | string, classroomId: string, userId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<ApiResponse<StudentProgressReport>> {
    return this.http.get(this.getProgressReportUrl(
      'user',
      userId,
      product,
      grade,
      classroomId,
      schoolYear,
      showPastYearReports))
      .pipe(
        instantiateApiResponseFromJson(StudentProgressReport),
      );
  }

  getStudentProgressReportForParent(product: ProductType, grade: GradeType | string, classroomId: string, studentId: string): Observable<ApiResponse<ClassroomProgressReport>> {
    const baseUrl = `${this.appConfig.apiUrl}/digital-resources/assessment/progress-report/product/${product}/grade/${grade}/classroom/${classroomId}/user/${studentId}?role=Parent`;
    return this.http.get(baseUrl)
      .pipe(
        instantiateApiResponseFromJson(ClassroomProgressReport),
      );
  }

  exportStudentProgressReport(product: ProductType, grade: GradeType | string, classroomId: string, userId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<Blob> {
    const url = this.getProgressExportUrl(
      'user',
      userId,
      product,
      grade,
      classroomId,
      schoolYear,
      showPastYearReports);
    return this.http.get(url, { responseType: 'blob' });
  }

  getClassroomProgressReport(
    questKey: string,
    product: ProductType,
    grade: GradeType | string,
    classroomId: string,
    schoolYear?: string,
    showPastYearReports?: boolean):
    Observable<ApiResponse<ClassroomProgressReport>> {
    return this.http.get(this.getProgressReportUrl(
      'classroom',
      questKey,
      product,
      grade,
      classroomId,
      schoolYear,
      showPastYearReports))
      .pipe(
        instantiateApiResponseFromJson(ClassroomProgressReport),
      );
  }

  exportClassroomProgressReport(questKey: string, product: ProductType, grade: GradeType | string, classroomId: string, schoolYear?: string, showPastYearReports?: boolean): Observable<Blob> {
    const url = this.getProgressExportUrl(
      'classroom',
      questKey,
      product,
      grade,
      classroomId,
      schoolYear,
      showPastYearReports);
    return this.http.get(url, { responseType: 'blob' });
  }

  getManualScoreForAssessment(questKey: string, product: ProductType, grade: GradeType | string, classroomId: string, userId: string, objective: string, schoolYear?: string, showPastYearReports?: boolean): Observable<ApiResponse<ISuperkidsLegacyAssessmentPartProgressDetail[]>> {
    const url: string = this.getManualScoreUrl(
      questKey,
      product,
      grade,
      classroomId,
      userId,
      objective,
      schoolYear,
      showPastYearReports);
    return this.http.get<ApiResponse<ISuperkidsLegacyAssessmentPartManualScore>>(url)
      .pipe(
        map(res => new ApiResponse<ISuperkidsLegacyAssessmentPartProgressDetail[]>(true, {
          response: res.response.parts.map(p => ({
            ...p,
            totalCorrect: p.benchmark === ScoreBenchmark.NotScored ? '' : p.totalCorrect,
            // Map missing Api properties from the parameters.
            assessmentKey: questKey,
            isInProgress: false,
          })),
          messages: res.messages,
        })),
      );
  }

  setManualScoreForAssessment(questKey: string, product: ProductType, grade: GradeType | string, classroomId: string, userId: string, objective: string, parts: IAssessmentObjectiveManualPart[]): Observable<ApiResponse<ISuperkidsLegacyAssessmentPartManualScore>> {
    return this.http.patch(this.getManualScoreUrl(questKey, product, grade, classroomId, userId, objective), parts)
      .pipe(
        map(res => new ApiResponse<ISuperkidsLegacyAssessmentPartManualScore>(true, res)),
      );
  }

  getAllProductTypes(): string[] {
    return Object['values'](ProductType).reduce((result: string[], key: string) => {
      if (!result.includes(key)) {
        result.push(key);
      }
      return result;
    }, []);
  }

  /**
   * Normalizes an ISO date to DateTime ISO string.
   *
   * @param {string | Date} isoDate
   *   A date string to pass to moment.
   *
   * @returns {string}
   *    2020-03-06T12:43:28.872Z
   */
  private normalizeToDateTime(isoDate: string | Date): string {
    return moment(isoDate).toISOString();
  }

  private getNoClassroomError(): Observable<ApiResponse<any>> {
    console.error('ClassroomId is not defined');
    return of(new ApiResponse<any>(false, {
      response: [],
      messages: [`An error occurred due to an issue with this classroom data. Logging out and back in again may resolve the issue.`],
    }));
  }
}
