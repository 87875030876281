
import { Classroom } from '@models/classroom';
import { IClassroomContext } from '@models/classroom-context';
import { ClassroomIcon } from '@models/classroom-icon';
import { ClassroomPin } from '@models/classroom-pin';
import { SchoolLicense } from '@models/license/school-license';
import { IProductLine } from '@models/product-line';
import { Quest } from '@models/quest';
import { QuestCollection } from '@models/quest-collection';
import { IViewerAsset } from '@models/viewer-asset';
import { Action, createReducer, on } from '@ngrx/store';
import { ProductType } from '@shared/enums/product-type';
import * as _ from 'lodash';

import * as LearningActions from './learning.actions';

export const learningFeatureKey = 'learning';

export interface LearningState {
  productLines: IProductLine[];
  classrooms: Classroom[];
  classroomIcons: ClassroomIcon[];
  studentQuests: QuestCollection[];
  schoolLicenses: SchoolLicense[];
  classroomContext: IClassroomContext;
  viewerAssets: IViewerAsset[];
  classroomPins: ClassroomPin[];
  errors: string[];
  productsLoaded: boolean;
  classroomsLoaded: boolean;
  classroomIconsLoaded: boolean;
  licensesLoaded: boolean;
  classroomSaved: boolean;
  classroomPinsLoaded: boolean;
  pinState: boolean;
}

export const initialState: LearningState = {
  productLines: null,
  classrooms: null,
  classroomIcons: null,
  studentQuests: null,
  schoolLicenses: null,
  classroomContext: null,
  viewerAssets: null,
  classroomPins: null,
  errors: [],
  productsLoaded: false,
  classroomsLoaded: false,
  classroomIconsLoaded: false,
  licensesLoaded: false,
  classroomSaved: false,
  classroomPinsLoaded: false,
  pinState: false,
};

const learningReducer = createReducer(
  initialState,
  on(LearningActions.initClassrooms, state => state),
  on(LearningActions.initClassroomIcons, state => state),
  on(LearningActions.initProductLines, state => state),
  on(LearningActions.resetClassroomsLoading, state => ({ ...state, classroomsLoaded: false })),
  on(LearningActions.resetClassroomIconsLoading, state => ({ ...state, classroomIconsLoaded: false })),
  on(LearningActions.resetProductLinesLoading, state => ({ ...state, productsLoaded: false })),
  on(LearningActions.resetClassroomSaved, state => ({ ...state, classroomSaved: false })),
  on(LearningActions.setMessages, (state, { messages }) => ({ ...state, errors: _.cloneDeep(messages) })),
  on(LearningActions.setClassrooms, (state, { payload }) => ({
    ...state,
    classrooms: _.cloneDeep(payload),
    classroomsLoaded: true,
  })),
  on(LearningActions.setClassroomIcons, (state, { payload }) => ({
    ...state,
    classroomIcons: _.cloneDeep(payload),
    classroomIconsLoaded: true,
  })),
  on(LearningActions.resetClassroom, state => ({ ...state, classroomContext: null })),
  on(LearningActions.loadSchoolLicenses, state => ({ ...state, licensesLoaded: false })),
  on(LearningActions.setSchoolLicenses, (state, { payload }) => ({
    ...state,
    schoolLicenses: _.cloneDeep(payload),
    licensesLoaded: true
  })),
  on(LearningActions.classroomSaved, state => ({ ...state, classroomSaved: true })),
  on(LearningActions.setClassroom, (state, { classroom }) => {
    const newState = { ...state, classroomContext: null };
    if (classroom) {
      if (classroom) {
        newState.classroomContext = { classroom, students: null, licenses: null };
      }
    }
    return newState;
  }),
  on(LearningActions.setClassroomStudents, (state, { students }) => ({
    ...state,
    classroomContext: {
      ...state.classroomContext,
      students,
    }
  })),
  on(LearningActions.setClassroomLicenses, (state, { licenses }) => ({
    ...state,
    classroomContext: {
      ...state.classroomContext,
      licenses,
    },
  })),
  on(LearningActions.updateClassroom, (state, { classroom }) => ({
    ...state,
    classrooms: state.classrooms.map(c => (c.classroomId === classroom.classroomId
      ? { ...classroom, roleType: c.roleType }
      : c)),
    classroomContext: { ...state.classroomContext, classroom },
  })),
  on(LearningActions.addClassroomStudents, (state, { students }) => ({
    ...state,
    classroomContext: {
      ...state.classroomContext,
      students: [
        // Filters existing students that were updated.
        ...state.classroomContext.students.filter(student => !students.find(s => s.userId === student.userId)),
        // Concatenate the new students.
        ...students,
      ],
    },
  })),
  on(LearningActions.removeClassroom, (state, { classroomId }) => ({
    ...state,
    classrooms: state.classrooms.filter(c => c.classroomId !== classroomId),
    classroomContext: state.classroomContext && state.classroomContext.classroom.classroomId === classroomId
      ? null
      : state.classroomContext,
  })),
  on(LearningActions.setProductLines, (state, { payload }) => ({
    ...state,
    productLines: _.cloneDeep(payload),
    productsLoaded: true,
  })),
  on(LearningActions.updateProductComponentPinned, (state, { productLineKey, variantType, componentId, isPinned }) => {
    if (state?.productLines) {
      return {
        ...state,
        productLines: state.productLines.map((pl) => {
          if (pl.productLineKey === productLineKey) {
            return {
              ...pl,
              variants: pl.variants.map((v) => {
                if (v.variantType === variantType) {
                  return {
                    ...v,
                    skus: v.skus.map((s) => {
                      const found = s.components.find(c => c.componentId === componentId);
                      if (found) {
                        return {
                          ...s,
                          components: s.components.map(c => (c.componentId === componentId ? { ...c, isPinned } : c)),
                        };
                      }
                      return s;
                    }),
                  };
                }
                return v;
              })
            };
          }
          return pl;
        })
      };
    }
    return { ...state };
  }),
  on(LearningActions.updateStudentQuestsFromHub, (state, { quests }) => {
    const studentQuests: QuestCollection[] = state && state.studentQuests ? _.cloneDeep(state.studentQuests) : [];
    // The hub sends a subset of quests based on the classroom and product of the updated quests.
    quests.forEach((quest: Quest) => {
      const { classroomId, productType, questKey } = quest;
      let collectionIndex = studentQuests.findIndex(c => c.product === productType && c.classroomId === classroomId);

      if (collectionIndex && quest.productType === ProductType.None) {
        collectionIndex = studentQuests.findIndex(c => !!c.quests.find(f => f.productType === productType
          && f.questKey === questKey) && c.classroomId === classroomId);
      }

      if (collectionIndex !== -1) {
        const foundIndex = studentQuests[collectionIndex].quests.findIndex(q => q.questId === quest.questId);
        if (foundIndex !== -1) {
          // Replaces with the updated quest.
          studentQuests[collectionIndex].quests[foundIndex] = quest;
        } else {
          // Adds new quest to quest collection.
          studentQuests[collectionIndex].quests.push(quest);
        }
      } else {
        // Adds a new quest collection.
        studentQuests.push({ classroomId, product: productType, quests: [quest] });
      }
    });

    return { ...state, studentQuests };
  }),
  on(LearningActions.setStudentQuestCollection, (state, { collection }) => {
    if (collection) {
      const studentQuests = state.studentQuests ? _.cloneDeep(state.studentQuests) : [];
      const index = studentQuests.findIndex(c => c.product === collection.product
        && c.classroomId === collection.classroomId);
      if (index !== -1) {
        studentQuests[index] = collection;
      } else {
        studentQuests.push(collection);
      }

      return { ...state, studentQuests };
    }
    return { ...state };
  }),
  on(LearningActions.loadViewerAssets, state => ({ ...state, viewerAssets: null })),
  on(LearningActions.setViewerAssets, (state, { viewerAssets }) => ({ ...state, viewerAssets })),
  on(LearningActions.initClassroomPins, state => state),
  on(LearningActions.setClassroomPins, (state, { payload }) => ({
    ...state,
    classroomPins: _.cloneDeep(payload),
    classroomPinsLoaded: true,
  })),
  on(LearningActions.setPinState, (state, { active }) => ({ ...state, pinState: active })),
);

export function reducer(state: LearningState | undefined, action: Action) {
  return learningReducer(state, action);
}
