import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { TopicType } from '@shared/enums/topic-type';
import { Helpers } from '@shared/helpers';
import { boxFencePlugin } from '@shared/markdown-plugins/box-fence';
import { bracketPlugin } from '@shared/markdown-plugins/bracket';
import { underlinePlugin } from '@shared/markdown-plugins/underline';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { Remarkable } from 'remarkable';
import { ScoreDetail } from './score';

export class QuestStudentScore {
  questId: string = undefined;
  firstName: string = undefined;
  lastName: string = undefined;
  completedOn?: string = undefined;
  score?: string = undefined;
  //TODO: make score detail a class
  scoreDetail?: ScoreDetail = undefined;
  unitNumber: number = undefined;
  activityId: string = undefined;
  topicType: TopicType = undefined;
  private _description: string = undefined;
  productName: string = undefined;
  questKey: string = undefined;
  private _descriptionAsMarkdown = undefined;

  set description(description: string) {
    this._description = description;
    const md = new Remarkable('full', { breaks: true, typographer: true, quotes: '“”‘’' });
    // Adds Z-B markdown plugins.
    md.use(underlinePlugin);
    md.use(bracketPlugin);
    md.use(boxFencePlugin);
    this._descriptionAsMarkdown = md.renderInline(description);
  }

  get description() {
    return this._description;
  }

  get descriptionAsMarkdown() {
    return this._descriptionAsMarkdown;
  }
}

export class QuestScoreReport {
  description: string = undefined;
  productName: string = undefined;
  grade: GradeType = undefined;
  gradeDisplay: string = undefined;
  scoreDetail?: ScoreDetail = undefined;
  private _productType: ProductType = undefined;
  @ClassCast(QuestStudentScore)
    details: QuestStudentScore[] = [];

  isSuperkids: boolean = false;

  set productType(productType: ProductType) {
    this._productType = productType;
    this.isSuperkids = Helpers.superkidsLegacyProductTypes.includes(productType);
  }

  get productType() { return this._productType; }
}
