import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  ArrowRightIcon,
  BackArrowIcon,
  CalendarIcon,
  CheckCircleIcon,
  CheckMarkIcon,
  CogIcon,
  CopyDisabledIcon,
  CopyEnabledIcon,
  CustomCardIcon,
  DeleteAllIcon,
  DownloadIcon,
  EllipseIcon,
  EllipseOutlineIcon,
  EnvelopeIcon,
  EyeIcon,
  FileIcon,
  FlipIcon,
  InfoCircleIcon,
  KeyIcon,
  MaskIcon,
  NeutralIcon,
  PasteDisabledIcon,
  PasteEnabledIcon,
  PencilIcon,
  PrinterIcon,
  SmileyFaceIcon,
  TCrossIcon,
  ThumbtackIcon,
  TrashCanIcon,
  UploadIcon,
  VerticalEllipsesIcon,
  XCrossCircleIcon,
  ArrowChevronRightIcon,
  ArrowChevronLeftIcon,
  XCrossIcon,
  CheckedBoxIcon,
  UncheckedBoxIcon,
  MoveHandleIcon,
  ResizeHandleIcon,
  WrenchesIcon,
  ParentRoleIcon,
  StudentRoleIcon,
  AdminTeacherRoleIcon,
  AdminAreaIcon,
  CxAdminAreaIcon,
  DistrictAdminAreaIcon,
  SchoolAdminAreaIcon,
  SupportAreaIcon,
  TeacherAreaIcon, ParentAreaIcon,
} from '@assets/highlights/svgs/all-svgs';
import { CheckCircleInverted, ExclamationInCircle } from '@assets/img/zbp-icons/all-svgs';
import { zbpIconNames } from './zbp-icon-names';

/**
 * # Icon Manual Parameterization is Required!
 *
 * SVGs that are added for this component need some manual processing to fully support all features.
 * The colors for the SVG should be parameterized such that they can be easily overridden, and have consistent defaults.
 *
 * - The primary color, usually black, should be replaced with `var(--zbp-svg-primary-color, black)`
 * in each place that it is referenced in the SVG markup.
 *
 * - The secondary color, usually white, should be replaced with `var(--zbp-svg-secondary-color, white)`
 * in each place that it is referenced in the SVG markup.
 *
 * A good reference for advanced usage of the pattern would be
 * `/assets/highlights/svgs/area-icons/school-admin-area-icon.ts`, which uses the primary and
 * secondary colors in both fill and stroke to achieve the desired image.
 *
 * ## SVG Parameterization Example:
 * Here the circle will default to have a stroke of black and fill of white, but can be replaced via CSS.
 * ```
 * <svg class="styledCircle" height="100" width="100" xmlns="http://www.w3.org/2000/svg">
 *   <circle r="45" cx="50" cy="50"
 *     stroke="var(--zbp-svg-primary-color, black)"
 *     fill="var(--zbp-svg-secondary-color, white)"
 * />
 * </svg>
 * ```
 *
 * ## Using the Parameters in CSS Example:
 * If we wanted to replace those colors on that SVG specifically, we could then use the following CSS.
 * ```
 * .styledCircle {
 *    --zbp-svg-primary-color: lime;
 *    --zbp-svg-secondary-color: magenta;
 * }
 * ```
 */
@Component({
  standalone: true,
  selector: 'zbp-icon',
  templateUrl: './zbp-icon.html',
  styleUrls: ['./zbp-icon.scss'],
  imports: [CommonModule]
})
export class ZbpIconComponent {
  private iconNameToComponentMap = {
    [zbpIconNames.arrow_right]: ArrowRightIcon,
    [zbpIconNames.arrow_chevron_right]: ArrowChevronRightIcon,
    [zbpIconNames.arrow_chevron_left]: ArrowChevronLeftIcon,
    [zbpIconNames.smileyFace]: SmileyFaceIcon,
    [zbpIconNames.thumbtack]: ThumbtackIcon,
    [zbpIconNames.printer]: PrinterIcon,
    [zbpIconNames.x_cross]: XCrossIcon,
    [zbpIconNames.flip]: FlipIcon,
    [zbpIconNames.t_cross]: TCrossIcon,
    [zbpIconNames.pencil]: PencilIcon,
    [zbpIconNames.ellipse]: EllipseIcon,
    [zbpIconNames.ellipse_outline]: EllipseOutlineIcon,
    [zbpIconNames.eye]: EyeIcon,
    [zbpIconNames.custom_card]: CustomCardIcon,
    [zbpIconNames.download]: DownloadIcon,
    [zbpIconNames.neutral]: NeutralIcon,
    [zbpIconNames.backArrow]: BackArrowIcon,
    [zbpIconNames.calendar]: CalendarIcon,
    [zbpIconNames.copy_enabled]: CopyEnabledIcon,
    [zbpIconNames.copy_disabled]: CopyDisabledIcon,
    [zbpIconNames.paste_enabled]: PasteEnabledIcon,
    [zbpIconNames.paste_disabled]: PasteDisabledIcon,
    [zbpIconNames.vertical_ellipses]: VerticalEllipsesIcon,
    [zbpIconNames.check_mark]: CheckMarkIcon,
    [zbpIconNames.check_circle]: CheckCircleIcon,
    [zbpIconNames.trash_can]: TrashCanIcon,
    [zbpIconNames.key]: KeyIcon,
    [zbpIconNames.cog]: CogIcon,
    [zbpIconNames.mask]: MaskIcon,
    [zbpIconNames.envelope]: EnvelopeIcon,
    [zbpIconNames.delete_all]: DeleteAllIcon,
    [zbpIconNames.x_cross_circle]: XCrossCircleIcon,
    [zbpIconNames.upload]: UploadIcon,
    [zbpIconNames.file]: FileIcon,
    [zbpIconNames.info_circle]: InfoCircleIcon,
    [zbpIconNames.unchecked_box]: UncheckedBoxIcon,
    [zbpIconNames.checked_box]: CheckedBoxIcon,
    [zbpIconNames.move_handle]: MoveHandleIcon,
    [zbpIconNames.resize_handle]: ResizeHandleIcon,
    [zbpIconNames.wrenches]: WrenchesIcon,
    [zbpIconNames.parent_role]: ParentRoleIcon,
    [zbpIconNames.student_role]: StudentRoleIcon,
    [zbpIconNames.admin_teacher_role]: AdminTeacherRoleIcon,
    [zbpIconNames.admin_area]: AdminAreaIcon,
    [zbpIconNames.cx_admin_area]: CxAdminAreaIcon,
    [zbpIconNames.district_admin_area]: DistrictAdminAreaIcon,
    [zbpIconNames.school_admin_area]: SchoolAdminAreaIcon,
    [zbpIconNames.support_area]: SupportAreaIcon,
    [zbpIconNames.teacher_area]: TeacherAreaIcon,
    [zbpIconNames.parent_area]: ParentAreaIcon,
    [zbpIconNames.exclamation_in_circle]: ExclamationInCircle,
    [zbpIconNames.check_circle_inverted]: CheckCircleInverted,
  };

  @Input() set iconName(value: string) {
    this._iconName = value;
    this.loadSvg();
  }

  protected _iconName: string = null;

  /**
   * Overrides the default fill color for the icon.
   * can accept a CSS variable e.g.: var(--primaryFade);
   */
  @Input() iconFillColor: string;

  /**
   * Enable default hover styles
   */
  @Input() hoverStyle: 'disabled' | 'enabled' | 'alternate' = 'disabled';

  @Input() disabled: boolean = false;

  /** Adjusts the size of the icon rendered.
   * <ul>
   * <li><strong>default</strong>: lets the icon do whatever it wants.</li>
   * <li><strong>font-size</strong>: forces icon to match font size.</li>
   * </ul>
   */
  @Input() iconSize: 'font-size' | 'default' = 'default';

  svgIcon: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
  }

  /**
   * Retrieves and sanitizes the SVG icon based on the provided iconName input.
   * If the icon does not exist, logs an error and sets the svgIcon to an empty string.
   * @private
   */
  private loadSvg(): void {
    if (this._iconName && this.iconNameToComponentMap[this._iconName]) {
      this.svgIcon = this.sanitizer.bypassSecurityTrustHtml(this.iconNameToComponentMap[zbpIconNames[this._iconName]]);
    } else {
      console.error(`Icon ${this._iconName} not found.`);
      this.svgIcon = this.sanitizer.bypassSecurityTrustHtml('');
    }
  }
}
