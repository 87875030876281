import { ViewerAssetJsonType, ViewerAssetType, ViewerLanguage, ViewerEdition } from '@shared/enums/viewer-asset';

import { IProductComponent } from './product-component';

// Legacy file-based.
export interface IViewerAssetJson {
  category: string;
  id: string;
  type: ViewerAssetJsonType;
  name: string;
  filename: string;
  engine: string;
  language: ViewerLanguage;
  weight?: number;
  edition?: ViewerEdition;
  toggle?: string[];
}

// Api-based.
export interface IViewerAsset {
  assetId: string;
  componentId: string;
  category: string;
  edition?: ViewerEdition;
  engine?: string;
  weight: number;
  name: string;
  fileName: string;
  viewerAssetType: ViewerAssetType;
  language: ViewerLanguage;
  instructions: string;
  tags: string[];
  toggles: string[];
  relatedAudio: string[];
  visible: boolean;
}

export class ViewerAsset implements IViewerAsset {
  assetId: string;
  componentId: string;
  category: string;
  weight: number;
  name: string;
  edition?: ViewerEdition;
  engine?: string;
  fileName: string;
  viewerAssetType: ViewerAssetType;
  language: ViewerLanguage;
  instructions: string;
  tags: string[];
  relatedAudio: string[];
  toggles: string[];
  visible: boolean;

  constructor(values: IViewerAsset) {
    Object.keys(values).forEach((key: string) => {
      if (Object.prototype.hasOwnProperty.call(values, key) && values[key]) {
        this[key] = values[key];
      }
    });
  }

  /**
   * Creates viewer asset object from legacy JSON structure.
   */
  static fromJson(componentId: string, values: IViewerAssetJson): ViewerAsset {
    let viewerAssetType: ViewerAssetType = null;
    if (values.type === ViewerAssetJsonType.canvas) {
      viewerAssetType = ViewerAssetType.Canvas;
    } else if (values.type === ViewerAssetJsonType.image) {
      viewerAssetType = ViewerAssetType.Image;
    } else if (values.type === ViewerAssetJsonType.pdf) {
      viewerAssetType = ViewerAssetType.Pdf;
    } else if (values.type === ViewerAssetJsonType.video) {
      viewerAssetType = ViewerAssetType.Video;
    } else if (values.type === ViewerAssetJsonType.video_canvas) {
      viewerAssetType = ViewerAssetType.VideoCanvas;
    } else {
      // ImagePrint, PdfLocked, Iwb, Dr, Audio, SuperSmart, Set are all Superkids asset types that were introduced
      // after the specification for viewer asset spreadsheets was defined for supplemental products.
      throw new Error('Asset type is not supported for this product.');
    }

    return new ViewerAsset({
      assetId: values.id,
      name: values.name,
      componentId,
      viewerAssetType,
      language: values.language || ViewerLanguage.en,
      edition: values.edition,
      fileName: values.filename,
      toggles: values.toggle || [],
      engine: values.engine || null,
      relatedAudio: [],
      category: values.category,
      tags: [],
      instructions: null,
      weight: values.weight || 0,
      visible: true,
    });
  }
}

export interface IProductComponentViewerAssets extends IProductComponent {
  viewerAssets: IViewerAsset[];
}
