import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { TopicActivityType, TopicType } from '@shared/enums/topic-type';
import { ScoreDetail } from './score';

export class StudentActivityReport {
  lastName: string = undefined;
  firstName: string = undefined;
  completedOn: string = undefined;
  activityName: string = undefined;
  productType: ProductType = undefined;
  score: string = undefined;
  scoreDetail?: ScoreDetail = undefined;
  unitNumber: number = undefined;
  userId: string = undefined;
  productName: string = undefined;
  grade?: GradeType = undefined;
  topicType: TopicType = undefined;
  topicKey: string = undefined;
  activityId: string = undefined;
  activityType: TopicActivityType = undefined;
  classroomId: string = undefined;
}
