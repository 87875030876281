import { ApiResponse } from '@models/api-response';
import { UserError } from '@models/error-handling/user-error.model';
import { StandardHttpResponse } from '@models/standard-http-response';
import { apiResponseFromArray, apiResponseFromJson } from '@shared/zb-object-helper/zb-object-helper-functions';
import { Observable, OperatorFunction, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export type Type<T> = new (...args: T[]) => T;

export function instantiateApiResponseFromJson<T>( // the generic parameter helps us conserve the type of the observable
  classCast: Type<T> // add an optional parameter to prefix the logging
) {
  return (source$: Observable<T>): Observable<ApiResponse<T>> => source$.pipe(
    map(res => apiResponseFromJson(classCast, res as unknown as StandardHttpResponse)),
  );
}

export function instantiateApiResponseFromArray<T>( // the generic parameter helps us conserve the type of the observable
  classCast: Type<T> // add an optional parameter to prefix the logging
) {
  return (source$: Observable<T>): Observable<ApiResponse<T[]>> => source$.pipe(
    map(res => apiResponseFromArray(classCast, res as unknown as StandardHttpResponse)),
  );
}

export function catchAndOverrideErrorHandlingMessages<T>(userMessages: string[]): OperatorFunction<T, T> {
  return (source$: Observable<T>) => source$.pipe(
    catchError(() => throwError(new UserError(userMessages))),
  );
}

export function  addIncrementer<T>(): (source: Observable<T>) => Observable<[number, T]> {
  let counter = 0;
  return (source$: Observable<T>) => source$.pipe(
    map((value) => {
      counter++;
      return [counter, value];
    })
  );
}
