import { District } from '@models/district';
import { createAction, props } from '@ngrx/store';


export enum DISTRICT_ACTIONS {
  reset = '[Districts] Reset Loading',
  setMessages = '[Districts] Set Messages',
  refresh = '[Districts] Refresh Districts',
  setDistricts = '[Districts] Set Districts',
  setDistrict = '[Districts] Set District',
}

export const resetLoading = createAction(DISTRICT_ACTIONS.reset);
export const setMessages = createAction(DISTRICT_ACTIONS.setMessages, props<{ messages: string[] }>());
export const refreshDistricts = createAction(DISTRICT_ACTIONS.refresh);
export const setDistricts = createAction(DISTRICT_ACTIONS.setDistricts, props<{ payload: District[] }>());
export const setDistrict = createAction(DISTRICT_ACTIONS.setDistrict, props<{ payload: District }>());
