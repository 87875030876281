import { ClassroomIconType } from '@shared/enums/classroom-icon-type';
import { GradeType } from '@shared/enums/grade-type';
import { RoleType } from '@shared/enums/role-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import { ClassroomIcon } from './classroom-icon';
import { IClassroomProductDescription } from './classroom-products';
import { ClassroomProfile } from './classroom-profile';
import { IEducationalUnitMembership } from './educational-unit-membership';
import { LicenseAssignee } from './license/license-assignee';
import { IUser, UserPartial } from './user';

export interface IClassroomCreate {
  name: string;
  schoolAssignedId?: string;
  gradeDesignation: GradeType;
  classroomIconType?: ClassroomIconType,
  studentUsersToAssignToClassroom?: UserPartial[];
  teacherUsersToAssignToClassroom?: UserPartial[];
  licenseRequests?: LicenseAssignee[];
}

export interface IClassroomUpdate extends IClassroomCreate {
  classroomId: string;
}

export interface IClassroom {
  classroomId: string;
  name: string;
  educationalUnitId?: string;
  teacherEmail?: string;
  teacherEmails?: string[];
  integrationId?: string;
  gradeDesignation?: GradeType;
  classroomIcon?: ClassroomIcon;
  profile?: ClassroomProfile;
  schoolAssignedId?: string;
  roleType?: RoleType;
  assignedLicenseCount?: number;
  studentCount?: number;
  licenses?: IClassroomProductDescription[];
}

export interface IUserClassroom {
  classroomId: string;
  name: string;
  educationalUnitId: string;
  schoolAssignedId?: string;
  roleType: RoleType;
  classroomIcon?: ClassroomIcon;
}

export class Classroom {
  classroomId: string = undefined;
  name: string = undefined;
  classroomNameYear: string = undefined;
  educationalUnitId: string = undefined;
  hasActivities: boolean = undefined;
  isOneRoster: boolean  = undefined;
  integrationId: string = undefined;
  teacherEmail: string = undefined;
  teacherEmails: string[] = [];
  gradeDesignation?: GradeType = GradeType.None;
  @ClassCast(ClassroomIcon)
    classroomIcon: ClassroomIcon = undefined;
  @ClassCast(ClassroomProfile)
    profile: ClassroomProfile = undefined;
  schoolAssignedId: string = undefined;
  roleType: RoleType = undefined;
  assignedLicenseCount: number = undefined;
  studentCount: number = undefined;
  @ClassCast(IUser)
    students: IUser[] = [];
  // Note that this is not actually a license or a product, but a descriptor of ProductType.
  @ClassCast(IClassroomProductDescription)
    licenses: IClassroomProductDescription[] = [];
  enableLeaderboard: boolean = undefined;

  static fromMembership(membership: IEducationalUnitMembership): Classroom {
    const values: IClassroom = {
      educationalUnitId: membership.educationalUnitId,
      classroomId: membership.classroomId,
      name: membership.classroomName,
      roleType: membership.roleType,
    };
    return copyObject(values, Classroom);
  }

  static fromUserStatus(classroom: IUserClassroom): Classroom {

    const values: IClassroom = {
      educationalUnitId: classroom.educationalUnitId,
      classroomId: classroom.classroomId,
      name: classroom.name,
      roleType: classroom.roleType,
      schoolAssignedId: classroom.schoolAssignedId ? classroom.schoolAssignedId : null,
      classroomIcon: classroom.classroomIcon,
    };
    return copyObject(values, Classroom);
  }

  static isIUserClassroom(obj: any): obj is IUserClassroom {
    return (
      typeof obj.classroomId === 'string'
      && typeof obj.name === 'string'
      && typeof obj.schoolId === 'string'
      && (typeof obj.schoolAssignedId === 'string' || !obj.schoolAssignedId)
      && typeof obj.roleType === 'string'
      && (typeof obj.classroomIcon === 'string' || !obj.classroomIcon)
    );
  }
}

export interface IClassroomReportData {
  classrooms: Classroom[];
}

export class ClassroomReportHeaderRequest {
  educationalUnitId: string = undefined;
  hasActivities: boolean = undefined;
  schoolYear: string = undefined;
  classroomIds: string[] = undefined;
  studentId: string = undefined;
  includeLicenses: boolean = undefined;
}
