export enum ScoreBenchmark {
  NotScored = 'NotScored',
  DoesNotMeet = 'DoesNotMeet',
  Meets = 'Meets',
  Exceeds = 'Exceeds',
}

export const scoreBenchmarkClassMap: {[key: string]: string} = {
  NotScored: '',
  DoesNotMeet: 'text-danger',
  Meets: 'text-warning',
  Exceeds: 'text-success',
};

export const scoreBenchmarkLabelMap: {[key: string]: string} = {
  NotScored: 'N/A',
  DoesNotMeet: 'Does not meet',
  Meets: 'Meets',
  Exceeds: 'Exceeds',
};
