export const supportAreaIcon = `<svg xmlns="http://www.w3.org/2000/svg"
     width="65"
     height="60"
     viewBox="0 0 65 60"
     fill="none">
  <path
      d="M61.171 30.3723C61.0089 13.0807 47.5171 1.33929 32.5117 1.33929C17.6592 1.33929 4.00693 12.8391 3.85233 30.5705C1.96605 31.7593 0.726006 33.851 0.726006 36.25V42.5C0.726006 46.2334 3.77835 49.2857 7.51172 49.2857C9.53347 49.2857 11.1724 47.6468 11.1724 45.625V29.6875C11.1724 17.8896 20.7138 8.34821 32.5117 8.34821C44.3096 8.34821 53.851 17.8896 53.851 29.6875V51.3393H32.5117C30.49 51.3393 28.851 52.9782 28.851 55C28.851 57.0218 30.49 58.6607 32.5117 58.6607H54.3867C58.1201 58.6607 61.1724 55.6084 61.1724 51.875V48.3768C63.0259 47.2799 64.2974 45.281 64.2974 42.9375V35.75C64.2974 33.4624 63.0227 31.4675 61.171 30.3723Z"
      fill="var(--zbp-svg-secondary-color, white)"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.07143"
  />
  <path
      d="M23.1367 36.7857C24.1076 36.7857 25.0387 36.4 25.7252 35.7135C26.4117 35.027 26.7974 34.0959 26.7974 33.125C26.7974 32.1541 26.4117 31.223 25.7252 30.5365C25.0387 29.85 24.1076 29.4643 23.1367 29.4643C22.1658 29.4643 21.2347 29.85 20.5482 30.5365C19.8617 31.223 19.476 32.1541 19.476 33.125C19.476 34.0959 19.8617 35.027 20.5482 35.7135C21.2347 36.4 22.1658 36.7857 23.1367 36.7857Z"
      fill="var(--zbp-svg-secondary-color, white)"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.07143"
  />
  <path
      d="M41.8867 36.7857C42.8576 36.7857 43.7887 36.4 44.4752 35.7135C45.1617 35.027 45.5474 34.0959 45.5474 33.125C45.5474 32.1541 45.1617 31.223 44.4752 30.5365C43.7887 29.85 42.8576 29.4643 41.8867 29.4643C40.9158 29.4643 39.9847 29.85 39.2982 30.5365C38.6117 31.223 38.226 32.1541 38.226 33.125C38.226 34.0959 38.6117 35.027 39.2982 35.7135C39.9847 36.4 40.9158 36.7857 41.8867 36.7857Z"
      fill="var(--zbp-svg-secondary-color, white)"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.07143"
  />
  <path
      d="M51.2553 27.5044L51.8957 27.5106L51.7886 26.8792C51.0224 22.3611 48.6823 18.2599 45.1827 15.3015C41.6831 12.343 37.2497 10.718 32.6672 10.7143H32.6667C22.9428 10.7143 12.4521 18.768 13.2884 31.4415L13.3374 32.1838L14.0259 31.9021C17.9612 30.2917 21.4369 27.7325 24.1431 24.4528C26.5418 21.5458 28.2698 18.1527 29.2122 14.5157C33.5996 22.2109 41.809 27.4136 51.2553 27.5044Z"
      fill="var(--zbp-svg-secondary-color, white)"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="1.07143"
  />
</svg>`;
