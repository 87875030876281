
export class UserLti {
  launchPresentationReturnUrl?: string;

  // @deprecated
  launch_presentation_return_url?: string;
  ext_ims_lis_memberships_url?: string;
  ext_ims_lis_memberships_id?: string;
  consumer_domain: string;
}
