import { SchoolYearDates } from '@models/school-year-dates';
import { EducationalUnitType } from '@shared/enums/educational-unit-type';
import { IntegrationProfileType } from '@shared/enums/integration-profile-type';
import { RoleType } from '@shared/enums/role-type';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import * as moment from 'moment';

import { IEducationalUnitIntegrationProfile } from './educational-unit-integration-profile';
import { IProductLine } from './product-line';

export interface IEducationalUnitPartial {
  educationalUnitId: string;
  name: string;
  abbreviatedSchoolId?: string;
  digitalAdmins?: string[];
}

export interface IEducationalUnit extends IEducationalUnitPartial {
  educationalUnitType?: EducationalUnitType;
  roleType?: RoleType;
  districtId?: string;
  districtName?: string;
  zbNum?: string;
  street?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  country?: string;
  hasAvailableLicenses?: boolean;
  hasFutureActivation?: boolean;
  hasAssignableLicenses?: boolean;
  schoolYearDates?: SchoolYearDates;
  productLines?: IProductLine[];
  integrationProfiles?: IEducationalUnitIntegrationProfile[];
}

export interface IEducationalUnitAccount {
  educationalUnitId: string;
  educationalUnitType: EducationalUnitType;
  accountName: string;
  zbNum?: string;
  parentAccountName?: string;
  abbreviatedSchoolId?: string;
  lastLogin?: string;
  state?: string | number;
  country?: string | number;
}

export class EducationalUnitBase {
  educationalUnitId: string = null;
  educationalUnitType: EducationalUnitType;
  districtId: string;
  name: string;
  abbreviatedSchoolId: string = null;
  roleType: RoleType;
  hasAvailableLicenses: boolean = false;
  hasFutureActivation: boolean = false;
  hasSchoolUserRoles: boolean = false;
  hasClasses: boolean = false;
  hasLicensesAssigned: boolean = false;
  hasLicensesDesignated: boolean = false;
  hasRosteringIntegrations: boolean = false;
  isOneRoster: boolean = false;
  zbNum: string = null;
  districtName?: string = null;
  _lastLogin?: moment.Moment = null;
  street: string = null;
  city: string = null;
  stateOrProvince: string = null;
  postalCode: string = null;
  country: string = null;
  productLines: IProductLine[] = [];
  integrationProfiles: IEducationalUnitIntegrationProfile[] = [];
  schoolYearDates: SchoolYearDates;
  earliestSchoolYearAvailable: number = null;
  availableSchoolYears: number[] = [];

  // @todo adds LTI profile type when that is added to Integrations dashboard.
  private dashboardTypes: IntegrationProfileType[] = [IntegrationProfileType.OneRoster];

  set accountName(accountName: string) {
    this.name = accountName;
  }

  set parentAccountName(parentAccountName: string) {
    this.districtName = parentAccountName;
  }

  static fromAccount(partial: IEducationalUnitAccount): EducationalUnitBase {
    const values: IEducationalUnit = {
      educationalUnitId: partial.educationalUnitId,
      educationalUnitType: partial.educationalUnitType,
      zbNum: partial.zbNum ? partial.zbNum : null,
      abbreviatedSchoolId: partial.abbreviatedSchoolId ? partial.abbreviatedSchoolId : null,
      name: partial.accountName,
      roleType: null,
    };
    const instance = copyObject(values, this);
    instance.districtName = partial.parentAccountName;
    instance.lastLogin = partial.lastLogin ? moment(partial.lastLogin) : null;

    return instance;
  }

  set schoolYearStartDate(value: moment.MomentInput) {
    if (value) {
      this.schoolYearDates.schoolYearStartDate = moment(value).toISOString();
    } else {
      this.schoolYearDates.schoolYearStartDate = null;
    }
  }

  get schoolYearStartDate(): moment.MomentInput {
    return this.schoolYearDates?.schoolYearStartDate ? moment(this.schoolYearDates.schoolYearStartDate) : null;
  }

  set schoolYearEndDate(value: moment.MomentInput) {
    if (value) {
      this.schoolYearDates.schoolYearEndDate = moment(value).toISOString();
    } else {
      this.schoolYearDates.schoolYearEndDate = null;
    }
  }

  get schoolYearEndDate(): moment.MomentInput {
    return this.schoolYearDates?.schoolYearEndDate ? moment(this.schoolYearDates.schoolYearEndDate) : null;
  }

  set schoolYear(value: number) {
    this.schoolYearDates.schoolYear = value;
  }

  get schoolYear(): number {
    return this.schoolYearDates?.schoolYear
      ? this.schoolYearDates.schoolYear
      : null;
  }

  set lastLogin(lastLogin: string | moment.Moment) {
    if (lastLogin && !moment.isMoment(lastLogin)) {
      this._lastLogin = moment(lastLogin);
    }
  }

  get lastLogin(): moment.Moment {
    return this._lastLogin;
  }

  getHasRosteringIntegrations(): boolean {
    return this.integrationProfiles.some(profile => this.dashboardTypes.includes(profile.profileType)
      && profile.integrationDashboardEnabled);
  }

  getFormattedStartDate(format: string = 'M/D/YYYY @ h:mma'): string {
    return this.schoolYearDates.schoolYearStartDate
      ? moment(this.schoolYearDates.schoolYearStartDate).utcOffset('0').format(format)
      : '';
  }

  getFormattedEndDate(format: string = 'M/D/YYYY @ h:mma'): string {
    return this.schoolYearDates.schoolYearEndDate
      ? moment(this.schoolYearDates.schoolYearEndDate).utcOffset('0').format(format)
      : '';
  }

  getStartDate(): moment.Moment {
    return this.schoolYearDates?.schoolYearStartDate
      ? moment(this.schoolYearDates.schoolYearStartDate)
      : null;
  }

  getEndDate(): moment.Moment {
    return this.schoolYearDates?.schoolYearEndDate
      ? moment(this.schoolYearDates.schoolYearEndDate)
      : null;
  }

  getFormattedLoginDate(format: string = 'M/D/YYYY'): string {
    return this.lastLogin ? this.lastLogin.format(format) : 'never';
  }
}
