import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from './index';

export interface IFacade {
  // Dispatches an action.
  dispatch(action: Action): void;

  // Selects from state using a factory method that takes arguments.
  selectWithProps(func: Function, ...props: any[]): Observable<any>;
}

@Injectable()
export class CoreFacade implements IFacade {
  constructor(private store: Store<State>) { }

  dispatch(action: Action): void {
    this.store.dispatch(action);
  }

  selectWithProps(func: Function, ...props: any[]): Observable<any> {
    return this.store.select(func(...props));
  }
}
