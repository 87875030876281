import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'zbp-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})

export class PreloaderComponent implements OnInit {
  ONE_MINITUE_IN_MILLIES: number = 60000;
  @Input() isError: boolean;
  @Input() timeoutMillis: number | null = this.ONE_MINITUE_IN_MILLIES;
  @Input() errorMessage: string = 'Something Went Wrong';
  @Input() responsive: boolean = false;
  @Input() spinnerHeight: string = '200';
  @Input() spinnerWidth: string = '200';
  @Output() hasTimedOut: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
    if (this.timeoutMillis) {
      this.startTimeoutCounter();
    }
  }

  startTimeoutCounter() {
    setTimeout(() => {
      this.isError = true;
      this.hasTimedOut.emit();
    }, this.timeoutMillis || 0);
  }
}
