import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LicenseGroup } from '@models/license/license-group';

@Component({
  selector: 'zbp-admin-license-start-end-date-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './admin-license-start-end-date-table.component.html',
  styleUrls: ['./admin-license-start-end-date-table.component.scss']
})
export class AdminLicenseStartEndDateTableComponent {
  @Input() isSearching: boolean = false;
  @Input() licenseGroups: LicenseGroup[] = [];
}
