export const parentAreaIcon = `<svg xmlns="http://www.w3.org/2000/svg"
     width="75"
     height="75"
     viewBox="0 0 75 75"
     fill="none">
  <path
      d="M42.7682 24.9993C42.7682 30.2284 38.5292 34.4674 33.3001 34.4674C28.071 34.4674 23.832 30.2284 23.832 24.9993C23.832 19.7703 28.071 15.5312 33.3001 15.5312C38.5292 15.5312 42.7682 19.7703 42.7682 24.9993Z"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="3.5625"
      stroke-linejoin="round"
  />
  <path
      d="M34.8893 57.4042V57.2756C34.8893 54.8617 36.4296 52.7169 38.717 51.9457L48.3128 48.7105M34.8893 57.4042V61.037H60.3503V57.1306C60.3503 54.7877 58.8981 52.6902 56.7051 51.8657L48.3128 48.7105M34.8893 57.4042H12.6484V49.0713C12.6484 46.9145 13.8817 44.9477 15.8231 44.0082L17.6684 43.1153C24.9504 39.5915 32.0002 38.5055 39.6479 40.5135H40.1158M48.3128 48.7105C52.84 48.7105 56.5099 45.0406 56.5099 40.5135C56.5099 35.9864 52.84 32.3164 48.3128 32.3164C43.7857 32.3164 40.1158 35.9864 40.1158 40.5135M48.3128 48.7105C43.7857 48.7105 40.1158 45.0406 40.1158 40.5135"
      stroke="var(--zbp-svg-primary-color, black)"
      stroke-width="3.5625"
      stroke-linejoin="round"
  />
</svg>
`;
