/**
 * Defines a product type.
 *
 * A product type is used by the API to define things differently than the normal Product Line,
 * Variant, SKU and Product Component terminology.
 *
 * The keys here are helpful to map between a Product Line and its API Product Type in ZBPortal.
 * Its the values are sent to the API.
 */
export enum ProductType {
  None = 'None',

  // Classroom/Student-facing products.
  gum2021n = 'DigitalResourcesGum2021',
  hw2016n = 'DigitalResourcesHandwriting2016',
  hw2020n = 'DigitalResourcesHandwriting2020',
  hw2025n = 'DigitalResourcesHandwriting2025',
  hw2020tx = 'DigitalResourcesHandwriting2020Texas',
  hw2020txs = 'DigitalResourcesHandwriting2020TexasSample',
  laesc2020n = 'DigitalResourcesLaEscritura2020',
  laesc2020tx = 'DigitalResourcesLaEscritura2020Texas',
  laesc2020txs = 'DigitalResourcesLaEscritura2020TexasSample',
  spcn2016n = 'DigitalResourcesSpelling2016',
  spcn2020tx = 'DigitalResourcesSpelling2020Texas',
  spcn2020txs = 'DigitalResourcesSpelling2020TexasSample',
  spcn2022 = 'DigitalResourcesSpelling2022',
  sk2015 = 'DigitalResourcesSuperkids2015',
  sk2017 = 'DigitalResourcesSuperkids2017',
  sk2026 = 'DigitalResourcesSuperkids2026',
  fsk2021 = 'DigitalResourcesSuperkidsFsk',
  hea2011 = 'DigitalResourcesSuperkidsHea',

  // Teacher-only products.
  gum2016n = 'DigitalResourcesGum2016',
  jiw2021n = 'DigitalResourcesJumpIntoWriting2021',
  irtw2016n = 'DigitalResourcesIReadToWrite2016',
  irtw2021n = 'DigitalResourcesIReadToWrite2021',
  wordh2017n = 'DigitalResourcesWordHeroes2017',
  ww2017n = 'DigitalResourcesWordWisdom2017',
  kns2021 = 'DigitalResourcesKickstartNumberSense2021',
  kenc2023 = 'DigitalResourcesKickstartEarlyNumberAndCounting2023',
  ms2024 = 'DigitalResourcesMindscapes2024',

  //ECE Product
  ece2024 = 'DigitalResourcesEce2024',
}
