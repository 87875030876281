import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class IsoDatePickerAdapter extends NgbDateAdapter<string> {
  readonly formats: string[] = ['MM-DD-YYYY', 'MM/DD/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'];

  fromModel(date: string): NgbDateStruct {
    if (date) {
      // Uses moment to convert date string.
      const m = moment(date, this.formats);
      if (m.isValid()) {
        return { year: m.year(), month: m.month() + 1, day: m.date() } as NgbDateStruct;
      }
    }

    return null;
  }

  toModel(struct: NgbDateStruct): string {
    if (struct && struct.year && struct.month && struct.day) {
      const m = moment();
      m.year(struct.year);
      m.month(struct.month - 1);
      m.date(struct.day);

      return m.format('YYYY-MM-DD');
    }
    return '';
  }
}
