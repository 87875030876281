import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAdminBenchmark } from '@models/admin-benchmark';
import { ApiResponse } from '@models/api-response';
import { Classroom } from '@models/classroom';
import { ClassroomPin } from '@models/classroom-pin';
import { School } from '@models/school';
import { StudentProgressReport } from '@models/student-progress-report';
import { GradeType } from '@shared/enums/grade-type';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppConfigService } from './appconfig.service';

@Injectable({
  providedIn: 'root',
})

/*
 * This shim service will allow report-related Observable to span across modules.
 */

export class ReportsService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  scoresReportSelectionCriteria = { schoolId: null, classroomId: null };
  currentSchoolId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentClassroomId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentStudentId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentPinnedRoute$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentPin$: BehaviorSubject<ClassroomPin> = new BehaviorSubject<ClassroomPin>(null);
  schools$: BehaviorSubject<School[]> = new BehaviorSubject<School[]>([]);
  selectedSchool$: BehaviorSubject<School> = new BehaviorSubject<School>(null);
  classrooms$: BehaviorSubject<Classroom[]> = new BehaviorSubject<Classroom[]>([]);
  selectedClassroom$: BehaviorSubject<Classroom> = new BehaviorSubject<Classroom>(null);
  studentProgressReports$: BehaviorSubject<StudentProgressReport[]> = new BehaviorSubject<StudentProgressReport[]>([]);
  // eslint-disable-next-line max-len
  selectedStudentProgressReport$: BehaviorSubject<StudentProgressReport> = new BehaviorSubject<StudentProgressReport>(null);

  private previousSchoolId: string = null;
  private previousClassroomId: string = null;

  setCurrentSchoolId$(schoolId: string): void {
    if (schoolId !== this.previousSchoolId) {
      this.previousSchoolId = schoolId;
      this.scoresReportSelectionCriteria.schoolId = schoolId;
      this.currentSchoolId$.next(schoolId);
    }
  }

  setCurrentClassroomId$(classroomId: string): void {
    if (classroomId !== this.previousClassroomId) {
      this.previousClassroomId = classroomId;
      this.scoresReportSelectionCriteria.classroomId = classroomId;
      this.currentClassroomId$.next(classroomId);
    }
  }

  setCurrentStudentId$(studentId: string): void {
    if (studentId !== this.previousClassroomId) {
      this.currentStudentId$.next(studentId);
    }
  }

  setCurrentPinnedRoute$(pinnedRoute: string): void {
    this.currentPinnedRoute$.next(pinnedRoute);
  }

  setCurrentPin$(classroomPin: ClassroomPin): void {
    this.currentPin$.next(classroomPin);
  }

  setSchools$(schools: School[]): void {
    this.schools$.next(schools);
  }

  setSelectedSchool$(school: School): void {
    this.selectedSchool$.next(school);
  }

  setClassrooms$(classrooms: Classroom[]): void {
    this.classrooms$.next(classrooms);
  }

  setSelectedClassroom$(classroom: Classroom): void {
    this.selectedClassroom$.next(classroom);
  }

  setStudentProgressReports$(studentProgressReports: StudentProgressReport[]): void {
    this.studentProgressReports$.next(studentProgressReports);
  }

  setSelectedStudentProgressReport$(studentProgressReport: StudentProgressReport): void {
    this.selectedStudentProgressReport$.next(studentProgressReport);
  }

  resetScoresReportSelectionCriteria() {
    this.scoresReportSelectionCriteria = { schoolId: null, classroomId: null };
  }

  getBenchmark(
    classroomId: string,
    schoolId: string,
    districtId: string,
    schoolYear: string,
    grade: GradeType | string): Observable<ApiResponse<IAdminBenchmark[]>> {
    const params = new HttpParams({ fromObject: {
      classroomId,
      schoolId,
      districtId,
      schoolYear,
      grade,
    } });

    return this.http.get<ApiResponse<IAdminBenchmark[]>>(`${this.appConfig.apiUrl}/report/admin-benchmark`, { params });
  }

  exportAdminBenchmarkReport(
    districtId: string,
    schoolId: string,
    classroomId: string,
    schoolYear: string,
    grade: GradeType): Observable<{ filename: string; data: Blob }> {
    let params = new HttpParams({ fromObject: { schoolYear, grade } });
    if (districtId) {
      params = params.append('districtId', districtId);
    }
    if (schoolId) {
      params = params.append('schoolId', schoolId);
    }
    if (classroomId) {
      params = params.append('classroomId', classroomId);
    }
    const url = `${this.appConfig.apiUrl}/export/report/admin-benchmark`;
    return this.http.get(url, { params, observe: 'response', responseType: 'blob' })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentDisposition = response.headers.get('Content-Disposition');
          // Default name in case we dont get the filename from the API
          let filename = 'benchmark-report.xlsx';
          if (contentDisposition) {
            const matches = contentDisposition.match(/filename="?(.+?)"?$/);
            if (matches && matches[1]) {
              // eslint-disable-next-line prefer-destructuring
              filename = matches[1];
            }
          }
          return {
            filename,
            data: response.body as Blob
          };
        })
      );
  }
}
