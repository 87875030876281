
import { District } from '@models/district';
import { IProductLineConfiguration } from '@models/product-line';
import { School } from '@models/school';
import { ISearch } from '@models/search';
import { createAction, props } from '@ngrx/store';

export enum ADMIN_ACTIONS {
  refreshOpenAlerts = '[Admin] Refresh Open Alerts',
  resetAlertsLoading = '[Admin] Reset Alerts Loading',
  refreshProductLines = '[Admin] Refresh Product Lines',
  resetProductLinesLoading = '[Admin] Reset Product Lines Loading',
  setOpenAlerts = '[Admin] Set Open Alerts',
  setProductLines = '[Admin] Set Product Lines',
  setAccount = '[Admin] Set Account',
  setSearch  = '[Admin] Set Search',
  setMessages = '[Admin] Set Messages',
}

export const refreshOpenAlerts = createAction(ADMIN_ACTIONS.refreshOpenAlerts);
export const resetAlertsLoading = createAction(ADMIN_ACTIONS.resetAlertsLoading);
export const refreshProductLines = createAction(ADMIN_ACTIONS.refreshProductLines);
export const resetProductLinesLoading = createAction(ADMIN_ACTIONS.resetProductLinesLoading);
export const setOpenAlerts = createAction(ADMIN_ACTIONS.setOpenAlerts, props<{ payload: number }>());
export const setProductLines = createAction(ADMIN_ACTIONS.setProductLines, props<{
  payload: IProductLineConfiguration[],
}>());
export const setAccount = createAction(ADMIN_ACTIONS.setAccount, props<{ payload: District | School}>());
export const setSearch = createAction(ADMIN_ACTIONS.setSearch, props<{ search: ISearch }>());
export const setMessages = createAction(ADMIN_ACTIONS.setMessages, props<{ payload: string[] }>());
