import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { SfxService } from '@core/sfx.service';
import { IAudioHelpFile } from '@models/audio-help-file';

@Component({
  selector: 'zbp-student-audio-button',
  templateUrl: './student-audio-button.component.html',
  styleUrls: ['./student-audio-button.component.scss'],
})
export class StudentAudioButtonComponent implements AfterViewInit {
  isLoaded = false;
  isPlaying = false;
  audio: IAudioHelpFile = null;

  @Input() set audioId(id: string) {
    this.audio = this.sfxService.getAudioFileById(id);
  }
  @Input() set audioKey(key: string) {
    this.audio = this.sfxService.getAudioFileByKey(key);
  }
  @ViewChild('audioEl', { static: false }) audioEl: ElementRef<HTMLAudioElement>;

  constructor(private changeDetectorRef: ChangeDetectorRef, private sfxService: SfxService) { }

  // Needs to be afterViewInit because Angular ViewChild.
  ngAfterViewInit(): void {
    this.sfxService.load(this.audioEl.nativeElement)
      .subscribe((loaded) => {
        this.isLoaded = loaded;
        this.changeDetectorRef.detectChanges();
      });
  }

  playOrStop(): void {
    if (!this.isPlaying && this.isLoaded) {
      this.isPlaying = true;
      this.sfxService.playAsCurrent(this.audioEl?.nativeElement).subscribe(() => {
        this.isPlaying = false;
      });
    } else if (this.isLoaded) {
      this.sfxService.stop(this.audioEl.nativeElement);
      this.isPlaying = false;
    }
  }
}
