export const districtAdminAreaIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="76" height="50" viewBox="0 0 76 50" fill="none">
  <path d="M33.4077 22.3711H5.45312V33.2855H33.4077V22.3711Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M5.36696 24.8721C5.26099 24.87 5.15707 24.8425 5.06398 24.7918C4.97088 24.7411 4.89135 24.6688 4.83208 24.5809C4.73821 24.4491 4.67597 24.2975 4.65018 24.1378C4.62439 23.978 4.63575 23.8145 4.68338 23.6599L5.33393 21.4997C5.36977 21.3413 5.45555 21.1986 5.57861 21.0927C5.70168 20.9867 5.85554 20.9231 6.01751 20.9111H32.8486C33.0102 20.9235 33.1636 20.9873 33.2862 21.0932C33.4089 21.1992 33.4944 21.3416 33.5301 21.4997L34.1806 23.6599C34.2281 23.8144 34.2396 23.9778 34.2141 24.1374C34.1887 24.2971 34.1271 24.4488 34.034 24.5809C33.9741 24.6686 33.8941 24.7407 33.8007 24.7914C33.7073 24.842 33.6033 24.8697 33.4971 24.8721H5.36696Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M32.8467 21.1171C32.964 21.13 33.0741 21.1797 33.1613 21.2591C33.2485 21.3385 33.3083 21.4436 33.3321 21.5591L33.9805 23.7193C34.0193 23.8431 34.0291 23.9742 34.009 24.1024C33.989 24.2306 33.9397 24.3525 33.8649 24.4586C33.8233 24.5191 33.7683 24.5693 33.7042 24.6051C33.6401 24.6409 33.5686 24.6615 33.4952 24.6651H5.3651C5.29174 24.6615 5.22022 24.6409 5.1561 24.6051C5.09198 24.5693 5.037 24.5191 4.99543 24.4586C4.92065 24.3525 4.87132 24.2306 4.85126 24.1024C4.8312 23.9742 4.84097 23.8431 4.87978 23.7193L5.52826 21.5591C5.55202 21.4436 5.61179 21.3385 5.69899 21.2591C5.7862 21.1797 5.89634 21.13 6.01358 21.1171H32.8467ZM32.8467 20.7041H6.01358C5.80737 20.7157 5.61041 20.7936 5.45192 20.926C5.29344 21.0584 5.18188 21.2384 5.1338 21.4393L4.47707 23.5995C4.27055 24.3202 4.7125 25.0781 5.35891 25.0781H33.489C34.1354 25.0781 34.5877 24.3202 34.3709 23.5995L33.7203 21.4393C33.6722 21.2384 33.5607 21.0584 33.4022 20.926C33.2437 20.7936 33.0467 20.7157 32.8405 20.7041H32.8467Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M11.7756 33.4781C11.4967 33.4859 11.2261 33.3829 11.023 33.1918C10.8198 33.0006 10.7006 32.7367 10.6914 32.4579V22.2601C10.6962 21.9818 10.8096 21.7165 11.0074 21.5208L18.0291 14.3174C18.1715 14.1704 18.3421 14.0534 18.5307 13.9736C18.7192 13.8937 18.922 13.8525 19.1267 13.8525C19.3315 13.8525 19.5342 13.8937 19.7227 13.9736C19.9113 14.0534 20.0819 14.1704 20.2244 14.3174L27.2461 21.5208C27.4439 21.7165 27.5573 21.9818 27.562 22.2601V32.4579C27.5534 32.7369 27.4344 33.0011 27.2311 33.1924C27.0278 33.3837 26.7568 33.4864 26.4778 33.4781H11.7756Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M19.1229 14.0595C19.2997 14.0592 19.4748 14.0945 19.6377 14.1633C19.8005 14.2321 19.9479 14.3331 20.0708 14.4602L27.0925 21.6655C27.2518 21.8231 27.3436 22.0363 27.3486 22.2603V32.4581C27.3394 32.6822 27.242 32.8935 27.0776 33.046C26.9131 33.1984 26.695 33.2796 26.4709 33.2718H11.7728C11.5487 33.2796 11.3306 33.1984 11.1662 33.046C11.0017 32.8935 10.9043 32.6822 10.8951 32.4581V22.2603C10.9001 22.0363 10.9919 21.8231 11.1512 21.6655L18.1729 14.4602C18.2962 14.333 18.4439 14.2321 18.6072 14.1632C18.7704 14.0944 18.9458 14.0591 19.1229 14.0595ZM19.1229 13.6465C18.8916 13.6469 18.6628 13.6937 18.4499 13.7842C18.2371 13.8746 18.0446 14.0069 17.8838 14.1731L10.8621 21.3785C10.6264 21.6119 10.4921 21.9287 10.4883 22.2603V32.4581C10.4969 32.7919 10.6377 33.1086 10.8798 33.3386C11.1218 33.5686 11.4452 33.6932 11.779 33.6848H26.4771C26.8109 33.6932 27.1343 33.5686 27.3763 33.3386C27.6184 33.1086 27.7592 32.7919 27.7678 32.4581V22.2603C27.764 21.9287 27.6297 21.6119 27.394 21.3785L20.3723 14.1731C20.2115 14.0069 20.019 13.8746 19.8062 13.7842C19.5933 13.6937 19.3645 13.6469 19.1332 13.6465H19.1229Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M19.1533 22.6845C18.7497 22.6825 18.3557 22.5609 18.0211 22.3351C17.6866 22.1094 17.4264 21.7895 17.2734 21.416C17.1205 21.0425 17.0816 20.6321 17.1618 20.2365C17.2419 19.841 17.4375 19.478 17.7237 19.1935C18.01 18.9089 18.3741 18.7156 18.7702 18.6379C19.1662 18.5601 19.5764 18.6015 19.949 18.7567C20.3216 18.9119 20.6398 19.174 20.8636 19.5099C21.0873 19.8458 21.2065 20.2405 21.2061 20.6441C21.2055 20.9129 21.152 21.179 21.0485 21.4272C20.9449 21.6753 20.7935 21.9005 20.6028 22.0901C20.4122 22.2796 20.186 22.4296 19.9372 22.5316C19.6885 22.6336 19.4221 22.6856 19.1533 22.6845Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M19.1527 18.8066C19.5159 18.8066 19.8708 18.9143 20.1727 19.116C20.4747 19.3177 20.71 19.6045 20.8489 19.9399C20.9879 20.2754 21.0243 20.6446 20.9534 21.0007C20.8826 21.3568 20.7077 21.684 20.451 21.9407C20.1942 22.1975 19.867 22.3723 19.5109 22.4432C19.1548 22.514 18.7856 22.4777 18.4501 22.3387C18.1147 22.1998 17.8279 21.9644 17.6262 21.6625C17.4244 21.3606 17.3168 21.0056 17.3168 20.6425C17.3173 20.1558 17.5109 19.6891 17.8551 19.3449C18.1993 19.0007 18.666 18.8071 19.1527 18.8066ZM19.1527 18.3936C18.7085 18.3956 18.2747 18.5292 17.9063 18.7774C17.5378 19.0257 17.2512 19.3775 17.0825 19.7885C16.9138 20.1995 16.8706 20.6513 16.9584 21.0868C17.0462 21.5223 17.261 21.9221 17.5757 22.2357C17.8904 22.5492 18.291 22.7626 18.7268 22.8488C19.1627 22.9349 19.6143 22.8901 20.0247 22.7199C20.435 22.5497 20.7858 22.2617 21.0327 21.8924C21.2796 21.5231 21.4116 21.0889 21.4121 20.6446C21.4121 20.0481 21.1751 19.4761 20.7534 19.0543C20.3316 18.6326 19.7595 18.3956 19.1631 18.3956L19.1527 18.3936Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M19.0859 19.4834V20.8278" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M19.0898 20.8281H20.2236" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M21.4242 8.91504H19.5448C19.3669 8.91504 19.2227 9.05928 19.2227 9.23721V10.8935C19.2227 11.0714 19.3669 11.2156 19.5448 11.2156H21.4242C21.6021 11.2156 21.7463 11.0714 21.7463 10.8935V9.23721C21.7463 9.05928 21.6021 8.91504 21.4242 8.91504Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M21.4237 9.12104C21.4388 9.12104 21.4539 9.12403 21.4679 9.12985C21.4819 9.13566 21.4947 9.14418 21.5054 9.15491C21.5162 9.16565 21.5247 9.1784 21.5305 9.19244C21.5363 9.20647 21.5393 9.2215 21.5393 9.23669V10.8888C21.5393 10.9195 21.5271 10.9489 21.5054 10.9706C21.4837 10.9923 21.4543 11.0045 21.4237 11.0045H19.5422C19.5119 11.0039 19.483 10.9915 19.4618 10.9699C19.4406 10.9483 19.4287 10.9191 19.4287 10.8888V9.23669C19.4287 9.20637 19.4406 9.17727 19.4618 9.15564C19.483 9.13401 19.5119 9.12158 19.5422 9.12104H21.4237ZM21.4237 8.70801H19.5422C19.4729 8.70801 19.4043 8.7217 19.3402 8.74829C19.2762 8.77489 19.2181 8.81386 19.1691 8.86298C19.1202 8.9121 19.0815 8.9704 19.0551 9.03453C19.0288 9.09866 19.0154 9.16736 19.0156 9.23669V10.8888C19.0154 10.9582 19.0288 11.0269 19.0551 11.091C19.0815 11.1551 19.1202 11.2134 19.1691 11.2625C19.2181 11.3117 19.2762 11.3506 19.3402 11.3772C19.4043 11.4038 19.4729 11.4175 19.5422 11.4175H21.4237C21.5639 11.4175 21.6984 11.3618 21.7975 11.2627C21.8966 11.1635 21.9523 11.029 21.9523 10.8888V9.23669C21.9523 9.09648 21.8966 8.96201 21.7975 8.86286C21.6984 8.76371 21.5639 8.70801 21.4237 8.70801Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M19.1262 13.0426C19.044 13.0426 18.9652 13.0099 18.9071 12.9518C18.849 12.8937 18.8164 12.8149 18.8164 12.7328V8.71603C18.8164 8.63387 18.849 8.55507 18.9071 8.49698C18.9652 8.43889 19.044 8.40625 19.1262 8.40625C19.2083 8.40625 19.2871 8.43889 19.3452 8.49698C19.4033 8.55507 19.436 8.63387 19.436 8.71603V12.7328C19.436 12.8149 19.4033 12.8937 19.3452 12.9518C19.2871 13.0099 19.2083 13.0426 19.1262 13.0426Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M15.5312 33.4496V25.4161C15.5312 25.2058 15.6148 25.004 15.7635 24.8553C15.9122 24.7066 16.114 24.623 16.3243 24.623H22.3671C22.5774 24.623 22.7791 24.7066 22.9278 24.8553C23.0766 25.004 23.1601 25.2058 23.1601 25.4161V33.4496H15.5312Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M22.3666 24.8239C22.5221 24.8239 22.6713 24.8857 22.7813 24.9957C22.8913 25.1057 22.9531 25.2549 22.9531 25.4105V33.2416H15.7373V25.4146C15.7373 25.259 15.799 25.1099 15.909 24.9999C16.019 24.8899 16.1682 24.8281 16.3238 24.8281H22.3666V24.8239ZM22.3666 24.415H16.3238C16.1924 24.4148 16.0623 24.4404 15.9409 24.4906C15.8195 24.5407 15.7093 24.6143 15.6164 24.7072C15.5235 24.8001 15.4499 24.9104 15.3997 25.0318C15.3496 25.1531 15.3239 25.2832 15.3242 25.4146V33.6546H23.3661V25.4146C23.3664 25.2832 23.3407 25.1531 23.2906 25.0318C23.2404 24.9104 23.1668 24.8001 23.0739 24.7072C22.9811 24.6143 22.8708 24.5407 22.7494 24.4906C22.628 24.4404 22.4979 24.4148 22.3666 24.415Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M19.4336 24.6445V33.5372" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M9.88809 23.5362C9.72242 23.5362 9.5633 23.4714 9.4448 23.3556C9.32631 23.2398 9.25785 23.0822 9.25408 22.9166C9.25021 22.7489 9.31252 22.5864 9.42755 22.4643L18.721 12.6568C18.7803 12.5957 18.8517 12.5474 18.9306 12.515C19.0095 12.4827 19.0942 12.467 19.1794 12.4689C19.2644 12.4673 19.3489 12.483 19.4277 12.5149C19.5065 12.5469 19.578 12.5944 19.6379 12.6548L28.8714 22.4003C28.9286 22.4608 28.9734 22.5319 29.0031 22.6097C29.0328 22.6875 29.047 22.7704 29.0447 22.8536C29.0424 22.9368 29.0237 23.0188 28.9897 23.0948C28.9557 23.1708 28.9071 23.2394 28.8466 23.2966C28.7299 23.408 28.5743 23.4695 28.413 23.468C28.3269 23.4681 28.2417 23.4508 28.1626 23.4171C28.0834 23.3834 28.0119 23.334 27.9524 23.2718L19.1794 14.0116L10.3466 23.3296C10.2888 23.3942 10.2182 23.4459 10.1392 23.4815C10.0603 23.5171 9.9747 23.5357 9.88809 23.5362Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M19.1834 12.6663C19.2406 12.665 19.2974 12.6751 19.3506 12.696C19.4038 12.717 19.4523 12.7483 19.4932 12.7882L28.7267 22.5337C28.8038 22.6163 28.8452 22.7258 28.8422 22.8386C28.8391 22.9515 28.7917 23.0586 28.7102 23.1368C28.6306 23.2111 28.5258 23.2524 28.417 23.2524C28.3589 23.2534 28.3014 23.2421 28.248 23.2193C28.1946 23.1966 28.1466 23.1628 28.1072 23.1202L19.4829 14.019L19.1834 13.7031L18.884 14.019L10.2101 23.1843C10.1727 23.2263 10.127 23.2602 10.0759 23.2839C10.0249 23.3077 9.9695 23.3207 9.91322 23.3222C9.85693 23.3238 9.80093 23.3138 9.74865 23.2929C9.69636 23.272 9.6489 23.2407 9.60917 23.2008C9.56811 23.1625 9.53507 23.1164 9.51198 23.0653C9.48889 23.0141 9.4762 22.9588 9.47466 22.9027C9.47313 22.8466 9.48277 22.7907 9.50303 22.7384C9.52328 22.686 9.55374 22.6382 9.59265 22.5978L18.8861 12.7882C18.9266 12.7484 18.9747 12.7171 19.0276 12.6961C19.0804 12.6752 19.1369 12.6651 19.1938 12.6663H19.1834ZM19.1938 12.2533C19.0787 12.2506 18.9644 12.2715 18.8578 12.3148C18.7512 12.3582 18.6547 12.423 18.5742 12.5053L9.2808 22.3148C9.12771 22.4764 9.04508 22.6922 9.05109 22.9147C9.05709 23.1372 9.15124 23.3482 9.31282 23.5013C9.4744 23.6544 9.69017 23.737 9.91268 23.731C10.1352 23.725 10.3462 23.6308 10.4993 23.4693L19.1731 14.302L27.816 23.4032C27.8916 23.4832 27.9823 23.5475 28.0829 23.5924C28.1834 23.6374 28.2918 23.6621 28.4019 23.6652C28.5119 23.6683 28.6215 23.6496 28.7244 23.6103C28.8273 23.571 28.9214 23.5119 29.0014 23.4362C29.0814 23.3605 29.1457 23.2699 29.1907 23.1693C29.2356 23.0688 29.2604 22.9604 29.2634 22.8504C29.2665 22.7403 29.2479 22.6307 29.2086 22.5278C29.1693 22.4249 29.1101 22.3308 29.0345 22.2508L19.8009 12.5053C19.7209 12.4224 19.6245 12.3572 19.5178 12.3138C19.4111 12.2704 19.2965 12.2498 19.1814 12.2533H19.1938Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M18.2383 28.1602V29.9011" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M20.4531 28.1602V29.9011" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M33.8143 32.9717H5.05221C4.97807 32.9717 4.91797 33.0318 4.91797 33.1059V33.9692C4.91797 34.0433 4.97807 34.1034 5.05221 34.1034H33.8143C33.8884 34.1034 33.9485 34.0433 33.9485 33.9692V33.1059C33.9485 33.0318 33.8884 32.9717 33.8143 32.9717Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M33.7376 33.1787V33.8953H5.12007V33.1787H33.7376ZM33.8099 32.7656H5.04779C4.95742 32.7656 4.87074 32.8015 4.80684 32.8654C4.74293 32.9293 4.70703 33.016 4.70703 33.1064V33.9696C4.70703 34.0143 4.71586 34.0585 4.73301 34.0997C4.75016 34.1409 4.77529 34.1784 4.80696 34.2098C4.83863 34.2413 4.87622 34.2662 4.91755 34.2831C4.95888 34.3 5.00314 34.3086 5.04779 34.3083H33.8016C33.8462 34.3086 33.8905 34.3 33.9318 34.2831C33.9732 34.2662 34.0108 34.2413 34.0424 34.2098C34.0741 34.1784 34.0992 34.1409 34.1164 34.0997C34.1335 34.0585 34.1424 34.0143 34.1424 33.9696V33.1064C34.1424 33.0616 34.1335 33.0173 34.1164 32.976C34.0993 32.9346 34.0742 32.8971 34.0425 32.8654C34.0109 32.8338 33.9733 32.8087 33.932 32.7916C33.8907 32.7744 33.8463 32.7656 33.8016 32.7656H33.8099Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M12.0625 34.1019V33.9449C12.063 33.5891 12.2047 33.2479 12.4563 32.9963C12.7079 32.7447 13.049 32.6031 13.4049 32.6025H24.8998C25.2557 32.6031 25.5968 32.7447 25.8484 32.9963C26.1 33.2479 26.2416 33.5891 26.2422 33.9449V34.1019H12.0625Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M24.8993 32.8095C25.1918 32.8098 25.4729 32.9229 25.6841 33.1252C25.8953 33.3276 26.0203 33.6036 26.0331 33.8958H12.2685C12.2818 33.6034 12.4072 33.3274 12.6188 33.1251C12.8303 32.9228 13.1117 32.8097 13.4044 32.8095H24.8993ZM24.8993 32.3965H13.4044C12.9936 32.3965 12.5996 32.5597 12.3091 32.8501C12.0187 33.1406 11.8555 33.5346 11.8555 33.9454V34.3088H26.4482V33.9454C26.4482 33.5346 26.285 33.1406 25.9945 32.8501C25.7041 32.5597 25.3101 32.3965 24.8993 32.3965Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M9.13119 25.7969H7.16512C7.03509 25.7969 6.92969 25.9023 6.92969 26.0323V27.9983C6.92969 28.1284 7.03509 28.2338 7.16512 28.2338H9.13119C9.26122 28.2338 9.36663 28.1284 9.36663 27.9983V26.0323C9.36663 25.9023 9.26122 25.7969 9.13119 25.7969Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M9.13119 29.1006H7.16512C7.03509 29.1006 6.92969 29.206 6.92969 29.336V31.3021C6.92969 31.4321 7.03509 31.5375 7.16512 31.5375H9.13119C9.26122 31.5375 9.36663 31.4321 9.36663 31.3021V29.336C9.36663 29.206 9.26122 29.1006 9.13119 29.1006Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M8.14844 25.7969V28.1594" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M8.14844 29.1348V31.4994" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M31.5179 29.1006H29.5518C29.4218 29.1006 29.3164 29.206 29.3164 29.336V31.3021C29.3164 31.4321 29.4218 31.5375 29.5518 31.5375H31.5179C31.6479 31.5375 31.7533 31.4321 31.7533 31.3021V29.336C31.7533 29.206 31.6479 29.1006 31.5179 29.1006Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M31.5179 25.7969H29.5518C29.4218 25.7969 29.3164 25.9023 29.3164 26.0323V27.9983C29.3164 28.1284 29.4218 28.2338 29.5518 28.2338H31.5179C31.6479 28.2338 31.7533 28.1284 31.7533 27.9983V26.0323C31.7533 25.9023 31.6479 25.7969 31.5179 25.7969Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M30.5312 25.7969V28.1594" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M30.5312 29.1338V31.4984" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M69.5835 22.3711H41.6289V33.2855H69.5835V22.3711Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M41.5427 24.8731C41.4368 24.871 41.3329 24.8435 41.2398 24.7928C41.1467 24.7421 41.0671 24.6698 41.0079 24.5819C40.914 24.4501 40.8518 24.2985 40.826 24.1387C40.8002 23.979 40.8115 23.8155 40.8592 23.6609L41.5097 21.5007C41.5456 21.3423 41.6313 21.1996 41.7544 21.0936C41.8775 20.9877 42.0313 20.924 42.1933 20.9121H69.0244C69.186 20.9245 69.3394 20.9883 69.462 21.0942C69.5847 21.2001 69.6702 21.3426 69.7059 21.5007L70.3564 23.6609C70.4039 23.8154 70.4153 23.9787 70.3899 24.1384C70.3645 24.298 70.3029 24.4498 70.2098 24.5819C70.1498 24.6696 70.0699 24.7417 69.9765 24.7924C69.8831 24.843 69.779 24.8707 69.6729 24.8731H41.5427Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M69.0225 21.1171C69.1398 21.13 69.2499 21.1797 69.3371 21.2591C69.4243 21.3385 69.4841 21.4436 69.5078 21.5591L70.1563 23.7193C70.1951 23.8431 70.2049 23.9742 70.1848 24.1024C70.1648 24.2306 70.1155 24.3525 70.0407 24.4586C69.9991 24.5191 69.9441 24.5693 69.88 24.6051C69.8159 24.6409 69.7444 24.6615 69.671 24.6651H41.5409C41.4675 24.6615 41.396 24.6409 41.3319 24.6051C41.2678 24.5693 41.2128 24.5191 41.1712 24.4586C41.0964 24.3525 41.0471 24.2306 41.027 24.1024C41.007 23.9742 41.0167 23.8431 41.0556 23.7193L41.704 21.5591C41.7278 21.4436 41.7876 21.3385 41.8748 21.2591C41.962 21.1797 42.0721 21.13 42.1894 21.1171H69.0225ZM69.0225 20.7041H42.1894C41.9832 20.7157 41.7862 20.7936 41.6277 20.926C41.4692 21.0584 41.3577 21.2384 41.3096 21.4393L40.6528 23.5995C40.4463 24.3202 40.8883 25.0781 41.5347 25.0781H69.6648C70.3112 25.0781 70.7635 24.3202 70.5466 23.5995L69.8961 21.4393C69.848 21.2384 69.7365 21.0584 69.578 20.926C69.4195 20.7936 69.2225 20.7157 69.0163 20.7041H69.0225Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M47.9514 33.4781C47.6725 33.4859 47.4019 33.3829 47.1987 33.1918C46.9956 33.0006 46.8764 32.7367 46.8672 32.4579V22.2601C46.8719 21.9818 46.9853 21.7165 47.1832 21.5208L54.2048 14.3174C54.3473 14.1704 54.5179 14.0534 54.7065 13.9736C54.895 13.8937 55.0977 13.8525 55.3025 13.8525C55.5073 13.8525 55.71 13.8937 55.8985 13.9736C56.0871 14.0534 56.2577 14.1704 56.4002 14.3174L63.4218 21.5208C63.6197 21.7165 63.7331 21.9818 63.7378 22.2601V32.4579C63.7292 32.7369 63.6101 33.0011 63.4068 33.1924C63.2035 33.3837 62.9326 33.4864 62.6536 33.4781H47.9514Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M55.2987 14.0595C55.4755 14.0592 55.6506 14.0945 55.8134 14.1633C55.9763 14.2321 56.1236 14.3331 56.2466 14.4602L63.2683 21.6655C63.4276 21.8231 63.5194 22.0363 63.5244 22.2603V32.4581C63.5152 32.6822 63.4178 32.8935 63.2533 33.046C63.0889 33.1984 62.8708 33.2796 62.6467 33.2718H47.9486C47.7245 33.2796 47.5064 33.1984 47.3419 33.046C47.1775 32.8935 47.08 32.6822 47.0709 32.4581V22.2603C47.0759 22.0363 47.1677 21.8231 47.327 21.6655L54.3487 14.4602C54.472 14.333 54.6197 14.2321 54.7829 14.1632C54.9461 14.0944 55.1215 14.0591 55.2987 14.0595ZM55.2987 13.6465C55.0674 13.6469 54.8386 13.6937 54.6257 13.7842C54.4129 13.8746 54.2204 14.0069 54.0596 14.1731L47.0379 21.3785C46.8022 21.6119 46.6679 21.9287 46.6641 22.2603V32.4581C46.6727 32.7919 46.8135 33.1086 47.0555 33.3386C47.2976 33.5686 47.621 33.6932 47.9548 33.6848H62.6529C62.9867 33.6932 63.3101 33.5686 63.5521 33.3386C63.7942 33.1086 63.935 32.7919 63.9436 32.4581V22.2603C63.9397 21.9287 63.8055 21.6119 63.5698 21.3785L56.5481 14.1731C56.3873 14.0069 56.1948 13.8746 55.9819 13.7842C55.7691 13.6937 55.5403 13.6469 55.309 13.6465H55.2987Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M55.3291 22.6855C54.9254 22.6834 54.5315 22.5619 54.1969 22.3361C53.8624 22.1103 53.6022 21.7905 53.4492 21.417C53.2963 21.0435 53.2574 20.6331 53.3375 20.2375C53.4177 19.8419 53.6132 19.479 53.8995 19.1944C54.1858 18.9099 54.5499 18.7166 54.9459 18.6388C55.342 18.5611 55.7522 18.6024 56.1248 18.7576C56.4974 18.9128 56.8156 19.1749 57.0393 19.5109C57.2631 19.8468 57.3823 20.2415 57.3818 20.6451C57.3813 20.9139 57.3277 21.18 57.2242 21.4281C57.1207 21.6762 56.9693 21.9015 56.7786 22.091C56.5879 22.2806 56.3618 22.4306 56.113 22.5326C55.8643 22.6346 55.5979 22.6866 55.3291 22.6855Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M55.3285 18.8066C55.6916 18.8066 56.0466 18.9143 56.3485 19.116C56.6504 19.3177 56.8858 19.6045 57.0247 19.9399C57.1637 20.2754 57.2 20.6446 57.1292 21.0007C57.0584 21.3568 56.8835 21.684 56.6267 21.9407C56.37 22.1975 56.0428 22.3723 55.6867 22.4432C55.3305 22.514 54.9614 22.4777 54.6259 22.3387C54.2904 22.1998 54.0037 21.9644 53.802 21.6625C53.6002 21.3606 53.4926 21.0056 53.4926 20.6425C53.4931 20.1558 53.6867 19.6891 54.0309 19.3449C54.3751 19.0007 54.8418 18.8071 55.3285 18.8066ZM55.3285 18.3936C54.8842 18.3956 54.4505 18.5292 54.0821 18.7774C53.7136 19.0257 53.427 19.3775 53.2583 19.7885C53.0896 20.1995 53.0464 20.6513 53.1342 21.0868C53.2219 21.5223 53.4367 21.9221 53.7515 22.2357C54.0662 22.5492 54.4668 22.7626 54.9026 22.8488C55.3384 22.9349 55.7901 22.8901 56.2004 22.7199C56.6108 22.5497 56.9616 22.2617 57.2085 21.8924C57.4554 21.5231 57.5874 21.0889 57.5878 20.6446C57.5878 20.0481 57.3509 19.4761 56.9291 19.0543C56.5074 18.6326 55.9353 18.3956 55.3388 18.3956L55.3285 18.3936Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M55.2617 19.4834V20.8278" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M55.2656 20.8281H56.3994" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M57.5999 8.91504H55.7206C55.5427 8.91504 55.3984 9.05928 55.3984 9.23721V10.8935C55.3984 11.0714 55.5427 11.2156 55.7206 11.2156H57.5999C57.7779 11.2156 57.9221 11.0714 57.9221 10.8935V9.23721C57.9221 9.05928 57.7779 8.91504 57.5999 8.91504Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M57.5994 9.12202C57.6146 9.12202 57.6297 9.12501 57.6437 9.13082C57.6577 9.13663 57.6705 9.14515 57.6812 9.15589C57.6919 9.16663 57.7005 9.17938 57.7063 9.19341C57.7121 9.20744 57.7151 9.22248 57.7151 9.23767V10.8898C57.7151 10.9205 57.7029 10.9499 57.6812 10.9716C57.6595 10.9933 57.6301 11.0055 57.5994 11.0055H55.718C55.6877 11.0049 55.6588 10.9925 55.6376 10.9709C55.6163 10.9492 55.6044 10.9201 55.6045 10.8898V9.23767C55.6044 9.20735 55.6163 9.17824 55.6376 9.15661C55.6588 9.13498 55.6877 9.12256 55.718 9.12202H57.5994ZM57.5994 8.70898H55.718C55.6487 8.70898 55.58 8.72268 55.516 8.74927C55.452 8.77586 55.3938 8.81484 55.3449 8.86396C55.296 8.91308 55.2572 8.97137 55.2309 9.03551C55.2045 9.09964 55.1911 9.16834 55.1914 9.23767V10.8898C55.1911 10.9591 55.2045 11.0278 55.2309 11.092C55.2572 11.1561 55.296 11.2144 55.3449 11.2635C55.3938 11.3126 55.452 11.3516 55.516 11.3782C55.58 11.4048 55.6487 11.4185 55.718 11.4185H57.5994C57.7397 11.4185 57.8741 11.3628 57.9733 11.2636C58.0724 11.1645 58.1281 11.03 58.1281 10.8898V9.23767C58.1281 9.09745 58.0724 8.96298 57.9733 8.86383C57.8741 8.76469 57.7397 8.70898 57.5994 8.70898Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M55.302 13.0426C55.2198 13.0426 55.141 13.0099 55.0829 12.9518C55.0248 12.8937 54.9922 12.8149 54.9922 12.7328V8.71603C54.9922 8.63387 55.0248 8.55507 55.0829 8.49698C55.141 8.43889 55.2198 8.40625 55.302 8.40625C55.3841 8.40625 55.4629 8.43889 55.521 8.49698C55.5791 8.55507 55.6117 8.63387 55.6117 8.71603V12.7328C55.6117 12.8149 55.5791 12.8937 55.521 12.9518C55.4629 13.0099 55.3841 13.0426 55.302 13.0426Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M51.707 33.4496V25.4161C51.707 25.2058 51.7906 25.004 51.9393 24.8553C52.088 24.7066 52.2897 24.623 52.5001 24.623H58.5428C58.7532 24.623 58.9549 24.7066 59.1036 24.8553C59.2523 25.004 59.3359 25.2058 59.3359 25.4161V33.4496H51.707Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M58.5423 24.8239C58.6979 24.8239 58.8471 24.8857 58.9571 24.9957C59.0671 25.1057 59.1288 25.2549 59.1288 25.4105V33.2416H51.913V25.4146C51.913 25.259 51.9748 25.1099 52.0848 24.9999C52.1948 24.8899 52.344 24.8281 52.4996 24.8281H58.5423V24.8239ZM58.5423 24.415H52.4996C52.3682 24.4148 52.2381 24.4404 52.1167 24.4906C51.9953 24.5407 51.885 24.6143 51.7922 24.7072C51.6993 24.8001 51.6257 24.9104 51.5755 25.0318C51.5254 25.1531 51.4997 25.2832 51.5 25.4146V33.6546H59.5419V25.4146C59.5422 25.2832 59.5165 25.1531 59.4664 25.0318C59.4162 24.9104 59.3426 24.8001 59.2497 24.7072C59.1569 24.6143 59.0466 24.5407 58.9252 24.4906C58.8038 24.4404 58.6737 24.4148 58.5423 24.415Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M55.6133 24.6445V33.5372" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M46.0678 23.5362C45.9021 23.5362 45.743 23.4714 45.6245 23.3556C45.506 23.2398 45.4375 23.0822 45.4338 22.9166C45.4299 22.7489 45.4922 22.5864 45.6072 22.4643L54.9006 12.6568C54.96 12.5957 55.0314 12.5474 55.1103 12.515C55.1892 12.4827 55.2739 12.467 55.3591 12.4689C55.4441 12.4673 55.5286 12.483 55.6074 12.5149C55.6862 12.5469 55.7577 12.5944 55.8176 12.6548L65.0511 22.4003C65.1083 22.4608 65.1531 22.5319 65.1828 22.6097C65.2125 22.6875 65.2267 22.7704 65.2244 22.8536C65.2221 22.9368 65.2034 23.0188 65.1694 23.0948C65.1354 23.1708 65.0868 23.2394 65.0263 23.2966C64.9096 23.408 64.754 23.4695 64.5926 23.468C64.5066 23.4681 64.4214 23.4508 64.3423 23.4171C64.2631 23.3834 64.1916 23.334 64.1321 23.2718L55.3591 14.0116L46.5263 23.3296C46.4685 23.3942 46.3979 23.4459 46.3189 23.4815C46.2399 23.5171 46.1544 23.5357 46.0678 23.5362Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M55.3592 12.6673C55.4164 12.666 55.4732 12.6761 55.5264 12.697C55.5796 12.7179 55.6281 12.7493 55.669 12.7892L64.9025 22.5347C64.9796 22.6172 65.021 22.7268 65.0179 22.8396C65.0148 22.9525 64.9674 23.0596 64.886 23.1377C64.8064 23.2121 64.7016 23.2534 64.5927 23.2534C64.5347 23.2544 64.4771 23.2431 64.4238 23.2203C64.3704 23.1975 64.3224 23.1638 64.283 23.1212L55.6587 14.02L55.3592 13.704L55.0598 14.02L46.3859 23.1852C46.3484 23.2273 46.3028 23.2612 46.2517 23.2849C46.2007 23.3086 46.1453 23.3217 46.089 23.3232C46.0327 23.3248 45.9767 23.3148 45.9244 23.2939C45.8721 23.273 45.8247 23.2417 45.785 23.2018C45.7439 23.1635 45.7108 23.1174 45.6878 23.0662C45.6647 23.0151 45.652 22.9598 45.6504 22.9037C45.6489 22.8476 45.6586 22.7917 45.6788 22.7394C45.6991 22.687 45.7295 22.6392 45.7684 22.5987L55.0618 12.7892C55.1024 12.7493 55.1505 12.718 55.2034 12.6971C55.2562 12.6762 55.3127 12.666 55.3695 12.6673H55.3592ZM55.3695 12.2543C55.2545 12.2515 55.1401 12.2725 55.0335 12.3158C54.927 12.3592 54.8304 12.424 54.75 12.5062L45.4566 22.3158C45.3035 22.4774 45.2209 22.6931 45.2269 22.9156C45.2329 23.1382 45.327 23.3492 45.4886 23.5022C45.6502 23.6553 45.866 23.738 46.0885 23.732C46.311 23.726 46.522 23.6318 46.6751 23.4702L55.3489 14.3029L63.9918 23.4041C64.0674 23.4841 64.1581 23.5485 64.2586 23.5934C64.3592 23.6384 64.4676 23.6631 64.5776 23.6662C64.6877 23.6692 64.7973 23.6506 64.9002 23.6113C65.0031 23.572 65.0972 23.5129 65.1772 23.4372C65.2572 23.3615 65.3215 23.2708 65.3665 23.1703C65.4114 23.0698 65.4362 22.9614 65.4392 22.8513C65.4423 22.7413 65.4237 22.6316 65.3844 22.5288C65.3451 22.4259 65.2859 22.3318 65.2102 22.2518L55.9767 12.5062C55.8967 12.4234 55.8003 12.3582 55.6936 12.3148C55.5869 12.2714 55.4723 12.2508 55.3571 12.2543H55.3695Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M54.4141 28.1602V29.9011" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M56.6289 28.1602V29.9011" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M69.9861 32.9717H41.2241C41.1499 32.9717 41.0898 33.0318 41.0898 33.1059V33.9692C41.0898 34.0433 41.1499 34.1034 41.2241 34.1034H69.9861C70.0603 34.1034 70.1204 34.0433 70.1204 33.9692V33.1059C70.1204 33.0318 70.0603 32.9717 69.9861 32.9717Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M69.9133 33.1787V33.8953H41.2959V33.1787H69.9133ZM69.9856 32.7656H41.2236C41.1332 32.7656 41.0465 32.8015 40.9826 32.8654C40.9187 32.9293 40.8828 33.016 40.8828 33.1064V33.9696C40.8828 34.0143 40.8916 34.0585 40.9088 34.0997C40.9259 34.1409 40.9511 34.1784 40.9827 34.2098C41.0144 34.2413 41.052 34.2662 41.0933 34.2831C41.1347 34.3 41.1789 34.3086 41.2236 34.3083H69.9774C70.022 34.3086 70.0663 34.3 70.1076 34.2831C70.1489 34.2662 70.1865 34.2413 70.2182 34.2098C70.2499 34.1784 70.275 34.1409 70.2921 34.0997C70.3093 34.0585 70.3181 34.0143 70.3181 33.9696V33.1064C70.3181 33.0616 70.3093 33.0173 70.2922 32.976C70.2751 32.9346 70.25 32.8971 70.2183 32.8654C70.1867 32.8338 70.1491 32.8087 70.1078 32.7916C70.0664 32.7744 70.0221 32.7656 69.9774 32.7656H69.9856Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M48.2383 34.1019V33.9449C48.2388 33.5891 48.3804 33.2479 48.6321 32.9963C48.8837 32.7447 49.2248 32.6031 49.5807 32.6025H61.0756C61.4314 32.6031 61.7726 32.7447 62.0242 32.9963C62.2758 33.2479 62.4174 33.5891 62.418 33.9449V34.1019H48.2383Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M61.0751 32.8095C61.3676 32.8098 61.6487 32.9229 61.8599 33.1252C62.0711 33.3276 62.1961 33.6036 62.2089 33.8958H48.4443C48.4576 33.6034 48.583 33.3274 48.7946 33.1251C49.0061 32.9228 49.2874 32.8097 49.5802 32.8095H61.0751ZM61.0751 32.3965H49.5802C49.1694 32.3965 48.7754 32.5597 48.4849 32.8501C48.1945 33.1406 48.0312 33.5346 48.0312 33.9454V34.3088H62.624V33.9454C62.624 33.5346 62.4608 33.1406 62.1703 32.8501C61.8798 32.5597 61.4859 32.3965 61.0751 32.3965Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M45.307 25.7969H43.3409C43.2109 25.7969 43.1055 25.9023 43.1055 26.0323V27.9983C43.1055 28.1284 43.2109 28.2338 43.3409 28.2338H45.307C45.437 28.2338 45.5424 28.1284 45.5424 27.9983V26.0323C45.5424 25.9023 45.437 25.7969 45.307 25.7969Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M45.307 29.1006H43.3409C43.2109 29.1006 43.1055 29.206 43.1055 29.336V31.3021C43.1055 31.4321 43.2109 31.5375 43.3409 31.5375H45.307C45.437 31.5375 45.5424 31.4321 45.5424 31.3021V29.336C45.5424 29.206 45.437 29.1006 45.307 29.1006Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M44.3242 25.7969V28.1594" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M44.3242 29.1357V31.5004" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M67.6937 29.1006H65.7276C65.5976 29.1006 65.4922 29.206 65.4922 29.336V31.3021C65.4922 31.4321 65.5976 31.5375 65.7276 31.5375H67.6937C67.8237 31.5375 67.9291 31.4321 67.9291 31.3021V29.336C67.9291 29.206 67.8237 29.1006 67.6937 29.1006Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M67.6937 25.7969H65.7276C65.5976 25.7969 65.4922 25.9023 65.4922 26.0323V27.9983C65.4922 28.1284 65.5976 28.2338 65.7276 28.2338H67.6937C67.8237 28.2338 67.9291 28.1284 67.9291 27.9983V26.0323C67.9291 25.9023 67.8237 25.7969 67.6937 25.7969Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M66.7109 25.7969V28.1594" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M66.7109 29.1357V31.5004" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.413037" stroke-miterlimit="10"/>
  <path d="M65.5026 26.959H11.5391V48.0287H65.5026V26.959Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M11.3667 31.7852C11.1621 31.7811 10.9615 31.728 10.7818 31.6301C10.6021 31.5323 10.4486 31.3927 10.3342 31.223C10.153 30.9686 10.0328 30.6759 9.98302 30.3675C9.93324 30.0592 9.95517 29.7435 10.0471 29.445L11.3029 25.2749C11.3721 24.9691 11.5377 24.6936 11.7753 24.4891C12.0128 24.2845 12.3099 24.1617 12.6225 24.1387H64.4173C64.7292 24.1625 65.0253 24.2857 65.2621 24.4902C65.4989 24.6947 65.6639 24.9697 65.7329 25.2749L66.9886 29.445C67.0802 29.7433 67.1024 30.0586 67.0533 30.3668C67.0042 30.675 66.8853 30.9679 66.7056 31.223C66.5898 31.3923 66.4354 31.5315 66.2552 31.6293C66.075 31.727 65.8741 31.7805 65.6691 31.7852H11.3667Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M64.4185 24.5376C64.6448 24.5624 64.8575 24.6584 65.0258 24.8117C65.1941 24.965 65.3095 25.1677 65.3554 25.3907L66.6072 29.5608C66.6821 29.7999 66.7009 30.053 66.6622 30.3005C66.6235 30.548 66.5283 30.7833 66.384 30.988C66.3037 31.1049 66.1975 31.2017 66.0738 31.2709C65.95 31.34 65.8119 31.3797 65.6703 31.3867H11.368C11.2263 31.3797 11.0883 31.34 10.9645 31.2709C10.8407 31.2017 10.7346 31.1049 10.6543 30.988C10.51 30.7833 10.4147 30.548 10.376 30.3005C10.3373 30.053 10.3562 29.7999 10.4311 29.5608L11.6829 25.3907C11.7288 25.1677 11.8442 24.965 12.0125 24.8117C12.1808 24.6584 12.3934 24.5624 12.6198 24.5376H64.4185ZM64.4185 23.7402H12.6198C12.2217 23.7627 11.8415 23.9129 11.5356 24.1686C11.2296 24.4243 11.0143 24.7718 10.9214 25.1595L9.65369 29.3296C9.25502 30.7209 10.1082 32.1841 11.356 32.1841H65.6584C66.9062 32.1841 67.7793 30.7209 67.3607 29.3296L66.1049 25.1595C66.012 24.7718 65.7967 24.4243 65.4907 24.1686C65.1848 23.9129 64.8046 23.7627 64.4065 23.7402H64.4185Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M23.7414 48.3987C23.2031 48.4138 22.6807 48.215 22.2885 47.846C21.8962 47.4769 21.6661 46.9676 21.6484 46.4293V26.743C21.6576 26.2058 21.8765 25.6936 22.2584 25.3158L35.813 11.4102C36.0881 11.1262 36.4174 10.9005 36.7814 10.7463C37.1454 10.5921 37.5367 10.5127 37.932 10.5127C38.3273 10.5127 38.7185 10.5921 39.0825 10.7463C39.4465 10.9005 39.7758 11.1262 40.0509 11.4102L53.6055 25.3158C53.9874 25.6936 54.2063 26.2058 54.2155 26.743V46.4293C54.1988 46.9679 53.969 47.4779 53.5766 47.8472C53.1841 48.2164 52.6611 48.4148 52.1225 48.3987H23.7414Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M37.93 10.9106C38.2713 10.9099 38.6093 10.9781 38.9237 11.111C39.2381 11.2439 39.5225 11.4388 39.7599 11.684L53.3145 25.5936C53.622 25.8977 53.7993 26.3094 53.8089 26.7418V46.4281C53.7912 46.8606 53.6031 47.2686 53.2857 47.5629C52.9682 47.8572 52.5472 48.014 52.1145 47.9988H23.7414C23.3088 48.014 22.8877 47.8572 22.5703 47.5629C22.2528 47.2686 22.0647 46.8606 22.0471 46.4281V26.7418C22.0566 26.3094 22.2339 25.8977 22.5414 25.5936L36.0961 11.684C36.3342 11.4386 36.6193 11.2437 36.9344 11.1108C37.2495 10.9779 37.5881 10.9099 37.93 10.9106ZM37.93 10.1133C37.4835 10.1141 37.0418 10.2045 36.6309 10.3791C36.22 10.5537 35.8484 10.809 35.538 11.1299L21.9833 25.0395C21.5284 25.49 21.2692 26.1016 21.2617 26.7418V46.4281C21.2784 47.0724 21.5502 47.6838 22.0174 48.1278C22.4846 48.5718 23.109 48.8123 23.7534 48.7962H52.1265C52.7708 48.8123 53.3952 48.5718 53.8624 48.1278C54.3296 47.6838 54.6015 47.0724 54.6182 46.4281V26.7418C54.6107 26.1016 54.3515 25.49 53.8965 25.0395L40.3419 11.1299C40.0315 10.809 39.6598 10.5537 39.249 10.3791C38.8381 10.2045 38.3963 10.1141 37.9499 10.1133H37.93Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M37.9815 27.5634C37.2024 27.5594 36.4419 27.3247 35.796 26.8889C35.1502 26.4531 34.6479 25.8357 34.3527 25.1147C34.0574 24.3937 33.9824 23.6013 34.1371 22.8377C34.2918 22.0741 34.6693 21.3734 35.2219 20.8241C35.7745 20.2749 36.4774 19.9016 37.2419 19.7516C38.0065 19.6015 38.7984 19.6813 39.5176 19.9809C40.2368 20.2805 40.8512 20.7865 41.2831 21.435C41.7149 22.0835 41.945 22.8454 41.9442 23.6245C41.9432 24.1435 41.8398 24.6572 41.64 25.1361C41.4402 25.6151 41.1478 26.05 40.7798 26.4158C40.4117 26.7817 39.9751 27.0714 39.4949 27.2683C39.0147 27.4652 38.5005 27.5655 37.9815 27.5634Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M37.9856 20.0757C38.6866 20.0757 39.3718 20.2835 39.9546 20.673C40.5374 21.0624 40.9917 21.6159 41.26 22.2635C41.5282 22.9111 41.5984 23.6238 41.4616 24.3113C41.3249 24.9988 40.9873 25.6303 40.4917 26.1259C39.996 26.6216 39.3645 26.9591 38.677 27.0959C37.9895 27.2327 37.2769 27.1625 36.6293 26.8942C35.9817 26.626 35.4282 26.1717 35.0387 25.5889C34.6493 25.006 34.4415 24.3208 34.4415 23.6198C34.4425 22.6802 34.8162 21.7793 35.4807 21.1149C36.1451 20.4505 37.046 20.0767 37.9856 20.0757ZM37.9856 19.2783C37.128 19.2823 36.2907 19.5401 35.5795 20.0194C34.8682 20.4987 34.3149 21.1778 33.9892 21.9712C33.6636 22.7647 33.5802 23.6368 33.7496 24.4775C33.9191 25.3182 34.3337 26.09 34.9413 26.6953C35.5488 27.3006 36.3221 27.7125 37.1634 27.8788C38.0048 28.0452 38.8765 27.9586 39.6688 27.6301C40.461 27.3015 41.1381 26.7457 41.6147 26.0327C42.0914 25.3197 42.3462 24.4815 42.347 23.6238C42.347 22.4724 41.8896 21.3681 41.0754 20.5539C40.2612 19.7397 39.1569 19.2823 38.0055 19.2823L37.9856 19.2783Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M37.8516 21.3848V23.9801" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M37.8594 23.9805H40.0481" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M42.367 0.981445H38.7391C38.3956 0.981445 38.1172 1.25989 38.1172 1.60337V4.8007C38.1172 5.14418 38.3956 5.42262 38.7391 5.42262H42.367C42.7104 5.42262 42.9889 5.14418 42.9889 4.8007V1.60337C42.9889 1.25989 42.7104 0.981445 42.367 0.981445Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M42.3672 1.37937C42.3965 1.37937 42.4255 1.38515 42.4526 1.39637C42.4797 1.40759 42.5043 1.42403 42.525 1.44476C42.5458 1.46549 42.5622 1.49011 42.5735 1.51719C42.5847 1.54428 42.5905 1.57331 42.5905 1.60262V4.79198C42.5905 4.85119 42.5669 4.90798 42.525 4.94985C42.4832 4.99171 42.4264 5.01524 42.3672 5.01524H38.7353C38.6768 5.0142 38.621 4.99021 38.58 4.94845C38.539 4.9067 38.5161 4.85051 38.5161 4.79198V1.60262C38.5161 1.5441 38.539 1.48791 38.58 1.44615C38.621 1.4044 38.6768 1.38042 38.7353 1.37937H42.3672ZM42.3672 0.582031H38.7353C38.6015 0.58203 38.469 0.608461 38.3454 0.659799C38.2218 0.711137 38.1095 0.786374 38.0151 0.881198C37.9206 0.976021 37.8458 1.08856 37.795 1.21236C37.7441 1.33616 37.7182 1.46879 37.7188 1.60262V4.79198C37.7182 4.92582 37.7441 5.05844 37.795 5.18224C37.8458 5.30604 37.9206 5.41859 38.0151 5.51341C38.1095 5.60824 38.2218 5.68347 38.3454 5.73481C38.469 5.78615 38.6015 5.81258 38.7353 5.81258H42.3672C42.6379 5.81258 42.8975 5.70505 43.0889 5.51365C43.2803 5.32225 43.3878 5.06266 43.3878 4.79198V1.60262C43.3878 1.33195 43.2803 1.07236 43.0889 0.88096C42.8975 0.689562 42.6379 0.582031 42.3672 0.582031Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M37.93 8.95013C37.7714 8.95013 37.6193 8.88712 37.5072 8.77497C37.395 8.66283 37.332 8.51073 37.332 8.35212V0.598004C37.332 0.439404 37.395 0.287295 37.5072 0.175148C37.6193 0.0630003 37.7714 0 37.93 0C38.0886 0 38.2407 0.0630003 38.3528 0.175148C38.465 0.287295 38.528 0.439404 38.528 0.598004V8.35212C38.528 8.51073 38.465 8.66283 38.3528 8.77497C38.2407 8.88712 38.0886 8.95013 37.93 8.95013Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M30.9922 48.3428V32.8346C30.9922 32.4286 31.1534 32.0392 31.4405 31.7521C31.7276 31.465 32.117 31.3037 32.5231 31.3037H44.188C44.5941 31.3037 44.9835 31.465 45.2705 31.7521C45.5576 32.0392 45.7189 32.4286 45.7189 32.8346V48.3428H30.9922Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M44.1883 31.6946C44.4886 31.6946 44.7765 31.8139 44.9889 32.0263C45.2012 32.2386 45.3205 32.5266 45.3205 32.8269V47.9444H31.3911V32.8348C31.3911 32.5346 31.5104 32.2466 31.7227 32.0342C31.935 31.8219 32.223 31.7026 32.5233 31.7026H44.1883V31.6946ZM44.1883 30.9053H32.5233C32.2697 30.9048 32.0186 30.9543 31.7843 31.0511C31.5499 31.1479 31.337 31.29 31.1577 31.4693C30.9785 31.6485 30.8363 31.8615 30.7396 32.0958C30.6428 32.3301 30.5932 32.5813 30.5938 32.8348V48.7418H46.1178V32.8348C46.1183 32.5813 46.0688 32.3301 45.972 32.0958C45.8752 31.8615 45.7331 31.6485 45.5538 31.4693C45.3745 31.29 45.1616 31.1479 44.9273 31.0511C44.693 30.9543 44.4418 30.9048 44.1883 30.9053Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M38.5312 31.3467V48.5134" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M20.1031 29.2058C19.7833 29.2059 19.4762 29.0808 19.2474 28.8573C19.0187 28.6337 18.8865 28.3296 18.8792 28.0098C18.8718 27.6861 18.9921 27.3724 19.2141 27.1367L37.1541 8.20391C37.2687 8.0858 37.4065 7.99261 37.5588 7.93018C37.7111 7.86776 37.8746 7.83744 38.0391 7.84112C38.2032 7.8381 38.3663 7.86833 38.5184 7.93C38.6705 7.99166 38.8085 8.08348 38.9242 8.19992L56.7486 27.0131C56.859 27.1299 56.9454 27.2673 57.0028 27.4174C57.0602 27.5675 57.0874 27.7275 57.083 27.8882C57.0785 28.0488 57.0425 28.207 56.9769 28.3538C56.9113 28.5005 56.8175 28.6329 56.7007 28.7434C56.4754 28.9585 56.175 29.0772 55.8635 29.0743C55.6974 29.0745 55.533 29.0411 55.3802 28.976C55.2274 28.9109 55.0894 28.8155 54.9745 28.6955L38.0391 10.8192L20.9882 28.8072C20.8767 28.9318 20.7404 29.0316 20.5879 29.1003C20.4355 29.169 20.2703 29.2049 20.1031 29.2058Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M38.0405 8.22386C38.1508 8.22131 38.2606 8.2408 38.3633 8.28119C38.466 8.32158 38.5595 8.38206 38.6385 8.45908L56.4629 27.2723C56.6117 27.4316 56.6917 27.643 56.6857 27.8609C56.6798 28.0787 56.5882 28.2855 56.431 28.4364C56.2774 28.5799 56.0751 28.6597 55.8649 28.6597C55.7529 28.6615 55.6418 28.6398 55.5387 28.5958C55.4357 28.5518 55.3431 28.4867 55.2669 28.4045L38.6186 10.8352L38.0405 10.2252L37.4625 10.8352L20.7185 28.5281C20.6462 28.6092 20.558 28.6747 20.4594 28.7205C20.3609 28.7663 20.254 28.7915 20.1453 28.7945C20.0367 28.7975 19.9286 28.7782 19.8276 28.7379C19.7267 28.6976 19.6351 28.637 19.5584 28.56C19.4791 28.4861 19.4153 28.3972 19.3708 28.2984C19.3262 28.1996 19.3017 28.0929 19.2987 27.9846C19.2958 27.8763 19.3144 27.7684 19.3535 27.6674C19.3926 27.5663 19.4514 27.474 19.5265 27.3959L37.4665 8.45908C37.5448 8.38218 37.6377 8.32174 37.7397 8.28134C37.8417 8.24094 37.9508 8.2214 38.0605 8.22386H38.0405ZM38.0605 7.42653C37.8384 7.42119 37.6176 7.46162 37.4119 7.5453C37.2061 7.62898 37.0198 7.7541 36.8645 7.9129L18.9245 26.8497C18.629 27.1616 18.4695 27.5782 18.4811 28.0077C18.4926 28.4372 18.6744 28.8445 18.9863 29.1401C19.2982 29.4356 19.7147 29.5951 20.1443 29.5835C20.5738 29.5719 20.9811 29.3902 21.2766 29.0783L38.0206 11.3813L54.7048 28.9507C54.8509 29.1051 55.0259 29.2293 55.22 29.3161C55.414 29.4029 55.6233 29.4506 55.8358 29.4565C56.0483 29.4624 56.2598 29.4265 56.4584 29.3506C56.657 29.2748 56.8387 29.1606 56.9931 29.0145C57.1476 28.8684 57.2717 28.6934 57.3585 28.4993C57.4453 28.3052 57.4931 28.096 57.499 27.8835C57.5049 27.671 57.4689 27.4594 57.3931 27.2609C57.3172 27.0623 57.203 26.8806 57.0569 26.7261L39.2325 7.9129C39.0781 7.75293 38.892 7.62702 38.686 7.54326C38.48 7.4595 38.2588 7.41973 38.0365 7.42653H38.0605Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M36.2148 38.1328V41.4936" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M40.4883 38.1328V41.4936" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10" stroke-linecap="round"/>
  <path d="M66.2814 47.4209H10.7591C10.616 47.4209 10.5 47.5369 10.5 47.68V49.3465C10.5 49.4896 10.616 49.6056 10.7591 49.6056H66.2814C66.4245 49.6056 66.5406 49.4896 66.5406 49.3465V47.68C66.5406 47.5369 66.4245 47.4209 66.2814 47.4209Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M66.1421 47.8188V49.2022H10.8989V47.8188H66.1421ZM66.2817 47.0215H10.7594C10.5849 47.0215 10.4176 47.0908 10.2942 47.2142C10.1709 47.3375 10.1016 47.5048 10.1016 47.6793V49.3457C10.1016 49.4319 10.1186 49.5173 10.1517 49.5969C10.1848 49.6764 10.2333 49.7487 10.2945 49.8095C10.3556 49.8702 10.4282 49.9183 10.5079 49.9509C10.5877 49.9836 10.6732 50.0001 10.7594 49.9995H66.2657C66.3519 50.0001 66.4373 49.9836 66.5171 49.9509C66.5969 49.9183 66.6695 49.8702 66.7306 49.8095C66.7917 49.7487 66.8402 49.6764 66.8733 49.5969C66.9064 49.5173 66.9235 49.4319 66.9235 49.3457V47.6793C66.9235 47.5929 66.9065 47.5074 66.8734 47.4276C66.8404 47.3477 66.7919 47.2752 66.7308 47.2142C66.6698 47.1531 66.5973 47.1046 66.5174 47.0716C66.4376 47.0385 66.3521 47.0215 66.2657 47.0215H66.2817Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M24.2969 49.6024V49.2994C24.2979 48.6124 24.5713 47.9539 25.057 47.4682C25.5428 46.9824 26.2013 46.7091 26.8882 46.708H49.078C49.7649 46.7091 50.4234 46.9824 50.9092 47.4682C51.3949 47.9539 51.6683 48.6124 51.6693 49.2994V49.6024H24.2969Z" fill="var(--zbp-svg-secondary-color, white)"/>
  <path d="M49.0782 47.105C49.6429 47.1054 50.1856 47.3237 50.5933 47.7144C51.001 48.105 51.2423 48.6379 51.2669 49.202H24.6958C24.7214 48.6375 24.9636 48.1046 25.3719 47.7141C25.7803 47.3236 26.3234 47.1054 26.8884 47.105H49.0782ZM49.0782 46.3076H26.8884C26.0954 46.3076 25.335 46.6226 24.7742 47.1834C24.2135 47.7441 23.8984 48.5046 23.8984 49.2976V49.9993H52.0682V49.2976C52.0682 48.5046 51.7532 47.7441 51.1925 47.1834C50.6318 46.6226 49.8712 46.3076 49.0782 46.3076Z" fill="var(--zbp-svg-primary-color, black)"/>
  <path d="M18.6404 33.5723H14.8451C14.5941 33.5723 14.3906 33.7757 14.3906 34.0267V37.8221C14.3906 38.0731 14.5941 38.2766 14.8451 38.2766H18.6404C18.8914 38.2766 19.0949 38.0731 19.0949 37.8221V34.0267C19.0949 33.7757 18.8914 33.5723 18.6404 33.5723Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M18.6404 39.9473H14.8451C14.5941 39.9473 14.3906 40.1507 14.3906 40.4017V44.1971C14.3906 44.4481 14.5941 44.6516 14.8451 44.6516H18.6404C18.8914 44.6516 19.0949 44.4481 19.0949 44.1971V40.4017C19.0949 40.1507 18.8914 39.9473 18.6404 39.9473Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M16.7422 33.5732V38.134" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M16.7422 40.0146V44.5794" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M61.8513 39.9473H58.056C57.805 39.9473 57.6016 40.1507 57.6016 40.4017V44.1971C57.6016 44.4481 57.805 44.6516 58.056 44.6516H61.8513C62.1024 44.6516 62.3058 44.4481 62.3058 44.1971V40.4017C62.3058 40.1507 62.1024 39.9473 61.8513 39.9473Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M61.8513 33.5723H58.056C57.805 33.5723 57.6016 33.7757 57.6016 34.0267V37.8221C57.6016 38.0731 57.805 38.2766 58.056 38.2766H61.8513C62.1024 38.2766 62.3058 38.0731 62.3058 37.8221V34.0267C62.3058 33.7757 62.1024 33.5723 61.8513 33.5723Z" fill="var(--zbp-svg-secondary-color, white)" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M59.9531 33.5732V38.134" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
  <path d="M59.9531 40.0146V44.5794" stroke="var(--zbp-svg-primary-color, black)" stroke-width="0.797336" stroke-miterlimit="10"/>
</svg>`;
