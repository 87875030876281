<table id="licenseTable" class="table styled-table">
  <thead>
    <tr>
      <th scope="col">Product Name</th>
      <th scope="col">Qty</th>
      <th scope="col">Duration</th>
      <th scope="col">Activates</th>
      <th scope="col">Expires</th>
      <th scope="col" class="text-center">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="!isSearching && licenseGroups?.length === 0" class="text-center py-5">
      <td colspan="8">There are no licenses for the selected school year.</td>
    </tr>
    <tr *ngFor="let group of licenseGroups; index as n" id="licenseRow{{n}}" class="text-uppercase">
      <td>{{ group.name }}</td>
      <td>{{ group.quantity }}</td>
      <td >{{ group.duration }}</td>
      <td >{{ group.activationDateTime }}</td>
      <td >{{ group.expirationDateTime }}</td>
      <td>{{ group.status }}</td>
    </tr>
  </tbody>
</table>
