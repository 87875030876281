import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigService } from '@core/appconfig.service';
import { IAnnouncementRequest } from '@models/announcement';
import { ApiResponse } from '@models/api-response';
import { KeyValuePair } from '@models/key-value-pair';
import { Helpers } from '@shared/helpers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementApiService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  private getAnnouncementUrl(): string {
    return `${this.appConfig.apiUrl}/announcement`;
  }

  private getUrl(announcementId: string): string {
    return `${this.getAnnouncementUrl()}/${announcementId}`;
  }

  createAnnouncement(values: IAnnouncementRequest): Observable<any> {
    const url = this.getAnnouncementUrl();
    return this.http.post(url, values);
  }

  saveAnnouncement(announcementId: string, values: IAnnouncementRequest): Observable<any> {
    return this.http.patch<any>(this.getUrl(announcementId), values);
  }

  getAnnouncement(announcementId: string): Observable<ApiResponse<any>> {
    return this.http.get<any>(this.getUrl(announcementId));
  }

  deleteAnnouncement(announcementId: string): Observable<any> {
    return this.http.delete<any>(this.getUrl(announcementId));
  }

  searchAnnouncements(params: KeyValuePair[]): Observable<any> {
    return this.http.get<any>(Helpers.buildUrlWithParameters(this.appConfig.apiUrl, '/announcement', params));
  }

}
