import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { ApiResponse } from '@models/api-response';
import { OauthResponse } from '@models/oauth-response';
import { UserStatusResponse } from '@models/user-response';
import { OAuth2Provider } from '@shared/enums/oauth2-provider.enum';
import { Helpers } from '@shared/helpers';
import { of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { OAuth2Service } from './oauth2.service';

@Component({
  selector: 'zbp-oauth2',
  templateUrl: './oauth2.component.html',
  styleUrls: ['../login.component.scss', './oauth2.component.scss'],
  providers: [OAuth2Service],
})
export class OAuth2Component implements OnInit {
  completed = false;
  errors: string[] = [];
  provider: OAuth2Provider = null;

  get isClever(): boolean {
    return this.provider && this.provider === OAuth2Provider.clever;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private oauth2Service: OAuth2Service
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        withLatestFrom(this.route.params),
        filter(([qp, params]: Params[]) => !!qp && !!params),
        mergeMap(([qp, params]: Params[]) => {
          this.provider = this.oauth2Service.getProvider(params.provider);
          if (!qp.code) {
            return of(new ApiResponse<UserStatusResponse>(false, {
              response: null,
              messages: ['Missing required parameters'],
            }));
          }
          const state = qp.state ? qp.state : null;
          return this.oauth2Service.login(this.provider, qp.code, state);
        })
      )
      .subscribe((res: ApiResponse<UserStatusResponse>) => {
        this.completed = true;
        if (res.success) {
          this.router.navigate(['/']);
        } else {
          if (res.messages.length === 1 && res.messages[0] === Helpers.oAuthMissingStateMessage) {
            this.getOauthStateAndRelog();
          }

          this.errors = res.messages || ['An unknown error occurred attempting to authorize access to MyZBPortal.'];
        }
      });
  }

  getOauthStateAndRelog(): void {
    this.oauth2Service.getSsoUrl(this.provider)
      .subscribe((res: OauthResponse) => {
        if (res.url) {
          window.location.href = res.url;
        }
      });
  }
}
