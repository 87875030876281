<div [ngClass]="containerClass" *ngIf="!isHighlights">
  <div class="login-container">
    <img id="primaryLogo" [src]="logoSrc" [alt]="logoAlt" class="logo-image">
    <nav id="chooseRole">
      <h1 class="headline">Please select your user type:</h1>
      <div class="role-icon-container">
        <a *ngFor="let button of roleLinkButtons" [id]="button.Id" [attr.aria-labelledby]="button.Id + '-label'" class="choose-role-link" [routerLink]="button.Route">
          <zbp-role-button [iconName]="button.Icon"></zbp-role-button>
          <span [id]="button.Id + '-label'" class="role-label">{{button.Label}}</span>
        </a>
      </div>
    </nav>
  </div>
  <zbp-footer></zbp-footer>
</div>