<div class="loginHeader fixed-top bg-primary">&nbsp;</div>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <div class="loginFormContainerWrapper text-center">
    <section class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 id="pageTitle" class="formTitle text-center text-uppercase mt-5">Login from LMS</h1>
      <aside id="errors" *ngIf="errors.length > 0" class="alert alert-danger my-3 text-center w-100">
        <ng-container *ngFor="let error of errors">
          <div>{{ error }}</div>
        </ng-container>
      </aside>
      <nav *ngIf="errors.length > 0" class="my-3 text-center w-100">
        <a id="backToLoginPage" class="text-uppercase fw-bold" [routerLink]="['..', 'login']">
          <i class="fa fa-chevron-left" aria-hidden="true"></i> Back to Login Page
        </a>
      </nav>
      <div id="ltiLoadingBlock"></div>
    </section>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
