import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zbp-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  @Input()
    title: string;

  @Input()
    message: string;

  @Input()
    confirmButtonText: string;

  @Input()
    displayCancelButton = true;

  @Input()
    displayFooter = true;

  constructor(public activeModal: NgbActiveModal) {
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  confirm(): void {
    this.activeModal.close(true);
  }

}
