import { FeatureFlagType } from '@shared/enums/feature-flag-type';

/**
 * Describes a feature flag and its status set by the API.
 *
 * This does not necessarily mean that the front-end supports a given feature flag.
 *
 * @see FeatureFlagType
 */
export class FeatureFlag {
  featureName: string | FeatureFlagType;
  enabled: boolean = false;
}
