<main class="forgot-password-container bg-login" [class]="containerClass">
  <zbp-back-to-website-link *ngIf="!isHighlights" class="fixed-position-top-left"></zbp-back-to-website-link>
  <div
    id="forgotPasswordBody"
    [class.password-reset-flow-form]="!isHighlights"
    class="align-items-center col-md-8 col-lg-6 col-xl-6 col-xxl-6 col-sm-10 col-xs-12"
  >
    <figure class="text-center mx-2">
      <img
        class="brand-logo"
        id="highlightsLogo"
        alt="Highlights Portal"
      /> 
    </figure>
    <div
      *ngIf="error.processed"
      id="error"
      class="alert alert-danger mx-auto"
      [innerHTML]="error.message"
    ></div>
    <div *ngIf="formSubmitted.value && !success.processed && !error.processed">
      <div class="preloader spinner">
        <zbp-preloader></zbp-preloader>
      </div>
    </div>
    <div
      id="successContainer"
      class="alert alert-transparent text-center"
      role="alert"
      *ngIf="success?.processed"
    >
      <zbp-login-information-cta
        iconSrc="assets/img/envelope.png"
        iconAlt="Envelop Icon"
        headingText="Check your email"
        [showSendAgainSection]="true"
        [showBackToLoginLinkButton]="true"
        [bodyHTML]="success?.message"
        (sendAgainClickedHandler)="showForm()"
      ></zbp-login-information-cta>
    </div>
    <form #ngForm="ngForm"  novalidate="false" (ngSubmit)="triggerSubmitForm$.next(true)"
      *ngIf="!formSubmitted.value"
      id="passwordResetPanel"
      [formGroup]="resetForm"
      [class.px-5]="isHighlights"
      class="w-100"
    >
      <header class="row mt-auto">
        <h1 id="pageTitle" class="h1 text-center">Password Reset</h1>
        <h4 id="titleSubtext" class="h4 text-center">
          Enter the email address associated with your account to reset your password.
        </h4>
      </header>
      <div class="row input-group email-input-section">
        <label for="email" class="form-label form-label-hfc">Email</label>
        <input
          type="text"
          class="form-control form-control-hfc"
          id="email"
          name="email"
          formControlName="email"
        />
        <div class="email-error" *ngIf="email.invalid && ngForm.submitted">
          <p class="is-invalid-text" *ngIf="email.errors?.['required']">Email is required.</p>
          <p class="is-invalid-text" *ngIf="email.errors?.['email']">Invalid Email Format.</p>
        </div>
      </div>
      <div id="forgotPasswordButtonSection" class="row">
        <button
          [attr.aria-disabled]="!ngForm.form.valid"
          [disabled]="!ngForm.form.valid"
          [class.button-disabled]="!ngForm.form.valid"
          id="resetSubmit"
          type="submit"
          class="w-100 btn text-uppercase reset-button mx-0"
        >
          Reset Password
        </button>
        <button
          id="resetCancel"
          type="button"
          class="btn btn-link btn-sm mx-2 back-button"
          (click)="cancelResetPasswordMode()"
        >
          <span class="back-arrow">&larr;</span> Back to Login
        </button>
      </div>
    </form>
    <zbp-footer id="forgotPasswordFooter" class="w-100"></zbp-footer>
  </div>
</main>
