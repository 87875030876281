import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

/**
 * RedirectIfActive forwards users with active sessions away from the respective login to the root of the application
 */
@Injectable()
export class RedirectIfActive implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.hasActiveLogin(state.url);
  }

  hasActiveLogin(url: string): Observable<boolean> {
    const onLoginPage = url.includes('/login');
    return this.authService.authStatus
      .pipe(
        skipWhile(status => status === null),
        map((status) => {
          if (status && onLoginPage) {
            this.router.navigateByUrl('');
          }
          return !status;
        }),
      );
  }
}
