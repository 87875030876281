import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

@Component({
  standalone: true,
  selector: 'zbp-lti-advantage-request',
  templateUrl: './lti-advantage-request.component.html',
  styleUrls: ['../login.component.scss', './lti-advantage-request.component.scss'],
  imports: [SharedModule],
})
export class LtiAdvantageRequestComponent implements OnInit {
  portalUrl: string;
  errors: string[] = [];

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((queryParams) => {
      this.portalUrl = queryParams.issuer;
      const localMessage = [queryParams.messages];
      this.errors = localMessage || ['An unknown error occurred attempting to authorize access to MyZBPortal.'];
    });
  }
}
