import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ApiResponse } from '@models/api-response';
import { FeatureFlag } from '@models/feature-flag';
import { FeatureFlagType } from '@shared/enums/feature-flag-type';
import { apiResponseFromArray } from '@shared/zb-object-helper/zb-object-helper-functions';
import { BehaviorSubject, catchError, map, of } from 'rxjs';
import { AppConfigService } from './appconfig.service';
import { CacheService } from './cache.service';

/**
 * Provides access to checking available and active feature flags in the app instance.
 *
 * ZBPortal-Api provides feature flags that are activated immediately.
 *
 * Active feature flags persist in until one of the following has occurred:
 *
 * 1. The featureFlags query parameter is provided when routing with an empty string.
 * 2. Browser history is cleared (which will also log the user out).
 * 3. The user refreshes the page to reset the active feature flags from ZBPortal-Api as
 *    log as a query parameter is not provided on non-production environments.
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private availableFlags: string[] = Object.values(FeatureFlagType);

  /**
   * Whether feature flags have been loaded and app is bootstrapped.
   */
  featureFlagsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private cache: CacheService,
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) {
    // Initializes allowed feature flags from the API on instantiation once.
    this.initializeAvailableFeatureFlags();
  }

  /**
   * Checks if a given feature flag is currently active and allowed.
   *
   * This is meant to be a quick method that gets the latest active from local storage.
   *
   * This method should only be called from components or non-core services to avoid race conditions.
   */
  isActive(featureFlag: FeatureFlagType): boolean {
    const flags = this.cache.featureFlags?.split(',') || [];
    return flags.some(name => name === featureFlag && this.availableFlags.includes(featureFlag));
  }

  /**
   * Gets the currently active flags from storage.
   */
  getActiveFlags(): string {
    return this.cache.featureFlags;
  }

  /**
   * Sets provided active feature flags if those flags are allowed feature flags.
   *
   * @param {String} toActivate one or more feature flags separated by a comma.
   */
  private setFeatureFlags(toActivate: string): void {
    const flags = toActivate.split(',') || [];
    this.cache.featureFlags = this.availableFlags.filter(flag => flags.includes(flag)).join(',');
  }

  /**
   * Initializes the allowed and available feature flags from the API for pre-production environments.
   */
  initializeAvailableFeatureFlags(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const featureFlags = urlParams.get('featureFlags');
    if (environment.environment !== 'prod' && (featureFlags || featureFlags === '')) {
      this.setFeatureFlags(featureFlags);
      this.featureFlagsLoaded$.next(true);
    } else {
      const url = `${this.appConfig.apiUrl}/application/feature/portal`;
      this.http.get<ApiResponse<FeatureFlag[]>>(url)
        .pipe(
          map(res => apiResponseFromArray(FeatureFlag, res)),
          catchError((err: HttpErrorResponse) => {
            console.log('Error in initialize', err);
            return (
              of(new ApiResponse<FeatureFlag[]>(false, { response: [], messages: [err.error] }))
            );
          }
          ),
          map((allFlags: ApiResponse<FeatureFlag[]>) => {
            const activatedFlags = allFlags.response.filter(flag => flag.enabled);
            this.availableFlags = environment.environment === 'prod'
              ? activatedFlags.map(flag => flag.featureName)
              : allFlags.response.map(flag => flag.featureName);
            return activatedFlags;
          }),
          map((activatedFlags: FeatureFlag[]) => activatedFlags.map(flag => flag.featureName)),
        )
        .subscribe((flagNames: string[]) => {
          this.cache.featureFlags = flagNames.join(',');
          this.featureFlagsLoaded$.next(true);
        });
    }
  }
}
