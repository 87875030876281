export const cogIcon = `
<svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
x="0px" y="0px" viewBox="0 0 64 64" overflow="visible" xml:space="preserve">
<g>
<g>
<path fill="#020000" d="M32,42c-5.5,0-10-4.5-10-10c0-5.5,4.5-10,10-10c5.5,0,10,4.5,10,10C42,37.5,37.5,42,32,42z M32,24
c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S36.4,24,32,24z"/>
</g>
<g>
<path fill="#020000" d="M36.9,63h-9.8l-1-8.7c-2-0.6-3.8-1.3-5.5-2.3l-7,5.4l-7-6.9l5.4-6.9c-1-1.7-1.8-3.6-2.3-5.5L1,36.9v-9.8
L9.8,26c0.6-2,1.3-3.8,2.3-5.5l-5.4-7l6.9-6.9l6.9,5.4c1.7-1,3.6-1.8,5.5-2.3L27.1,1h9.8L38,9.8c2,0.6,3.8,1.3,5.5,2.3l7-5.4
l6.8,6.9l-5.4,6.9c1,1.7,1.8,3.6,2.3,5.5l8.8,1.1v9.8l-8.7,1c-0.6,2-1.3,3.8-2.3,5.5l5.4,7l-6.9,7l-6.9-5.4
c-1.7,1-3.6,1.8-5.5,2.3L36.9,63z M28.9,61h6.2l1.1-8.4l0.7-0.2c2.2-0.5,4.3-1.3,6.2-2.6l0.6-0.4l6.7,5.2l4.4-4.5l-5.2-6.6
l0.4-0.6c1.1-1.9,2-4,2.6-6.2l0.2-0.7l8.3-1v-6.2l-8.4-1.1l-0.2-0.7c-0.5-2.2-1.3-4.3-2.6-6.2l-0.4-0.6l5.2-6.7l-4.3-4.4l-6.6,5.2
l-0.6-0.4c-1.9-1.1-4-2-6.2-2.6l-0.7-0.2L35.1,3h-6.2l-1.1,8.4l-0.7,0.1c-2.2,0.5-4.3,1.3-6.2,2.6l-0.6,0.4l-6.7-5.2l-4.4,4.4
l5.2,6.6l-0.4,0.6c-1.1,1.9-2,4-2.6,6.2l-0.2,0.7L3,28.9v6.2l8.4,1.1l0.1,0.7c0.5,2.2,1.3,4.3,2.6,6.2l0.4,0.6l-5.2,6.7l4.5,4.4
l6.6-5.2l0.6,0.4c1.9,1.1,4,2,6.2,2.6l0.7,0.2L28.9,61z M62,36L62,36L62,36z"/>
</g>
</g>
</svg>
`;
