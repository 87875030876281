import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

/**
 * This object is used for reporting front errors to the backend api for reporting and troubleshooting
 *
 * @class ErrorForLogging
 * @property {string} referenceNumber - uuid generated by front end
 * @property {string} stack - stack trace of the error
 * @property {string} message - the error message
 * @property {number} httpErrorCode - http code associated with the error
 * @property {string} userName - the username of the person using the application
 * @property {string} timestamp - ISO timestamp when the error was generated
 * @property {string} uri - the uri route where the error was generated from
 */
export class ErrorForLogging {
  referenceNumber: string = uuidv4() as string;
  stack: string = undefined;
  httpErrorCode: number = undefined;
  userName: string = undefined;
  timestamp: string = moment().toISOString();
  uri: string = undefined;
  name: string = undefined;
  message: string = undefined;
  originalErrorMessage: string = undefined;
}
