import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import {
  BrandedAccountSetupResetComponent
} from '../branded-account-setup-reset/branded-account-setup-reset.component';
import { SetupAccountPasswordComponent } from '../setup-account-password/setup-account-password.component';

@Component({
  selector: 'zbp-setup-account-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class SetupAccountHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedAccountSetupResetComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: SetupAccountPasswordComponent,
    },
    ZbRedesign: {
      component: BrandedAccountSetupResetComponent,
    }
  };
}
