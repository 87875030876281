import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { ScoreBenchmark } from '@shared/enums/score-benchmark';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { ISuperkidsLegacyAssessmentPartProgressDetail } from './score';
import { StudentAssessmentProgress } from './student-progress-report';
import { UserPartial } from './user';

export class UserReportPartial extends UserPartial {
  userId: string = undefined;
  activityId: string = undefined;
  isOverriddenByTeacher: boolean = undefined;
  isInProgress: boolean = undefined;
  @ClassCast(ISuperkidsLegacyAssessmentPartProgressDetail)
    skillGrid: ISuperkidsLegacyAssessmentPartProgressDetail[] = [];
  classroomId: string = undefined;

  get skillGridHasScores() {
    return this.skillGrid.some(s => s.benchmark !== ScoreBenchmark.NotScored);
  }
}

export class ClassroomProgressReport {
  userId: string = undefined;
  firstName: string = undefined;
  lastName: string = undefined;
  assessmentKey: string = undefined;
  description: string = undefined;
  productType: ProductType = undefined;
  grade: GradeType = undefined;
  objectives: string[] = [];
  @ClassCast(UserReportPartial)
    students: UserReportPartial[] = [];
  assessments: StudentAssessmentProgress[] = [];
}
