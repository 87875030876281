<div id="qrCodeHelpModalContainer">
  <div class="modal-header" zbpZbSwirl>
    <h4 class="modal-title" id="qrCodeHelpModalTitle">QR Codes</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="dismiss()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="qr-help-iframe-wrapper">
    <iframe src="https://zanerbloser.wistia.com/medias/h6py4c2ae5" class="qr-help-iframe"></iframe>
  </div>
</div>