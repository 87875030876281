<ng-container *ngIf="!isHighlights">
  <nav class="loginHeader fixed-top bg-primary" aria-hidden="true">&nbsp;</nav>
  <div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
    <div class="backToZanerBloserContainer align-self-start">
      <div id="backToZanerBloserLinkContainer" class="text-bold ms-4 mt-2 mvBoli">
        <span class="d-block loginText">back to</span>
        <a id="backToZanerBloserLink" class="d-block ps-1 btn-link" [attr.href]="backToZanerBloserLink">zaner-bloser.com</a>
      </div>
    </div>
    <div class="loginFormContainerWrapper text-center">
      <div class="loginFormContainer mx-auto">
        <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
        <h1 class="subLogo">Choose your role to log in:</h1>
        <nav id="chooseRole" class="d-flex flex-nowrap justify-content-center align-items-start">
          <a id="teacherLoginLink" class="chooseRoleLink d-block me-5" routerLink="/login/user">
            <span id="teacherLoginLogo" class="loginLogo" aria-hidden="true">&nbsp;</span>
            <span>Teacher or Admin</span>
          </a>
          <a id="studentLoginLink" class="chooseRoleLink d-block" routerLink="/login/student">
            <span id="studentLoginLogo" class="loginLogo" aria-hidden="true">&nbsp;</span>
            <span>Student</span>
          </a>
          <a id="parentLoginLink" class="chooseRoleLink d-block ms-5" routerLink="/login/parent">
            <span id="parentLoginLogo" class="loginLogo" aria-hidden="true">&nbsp;</span>
            <span>Parents & Guardians</span>
          </a>
        </nav>
      </div>
    </div>
    <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
      <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
    </div>
  </div>
  <zbp-footer></zbp-footer>
</ng-container>