import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { Helpers } from '@shared/helpers';
import { ClassCast } from '@shared/zb-object-helper/class-cast';

export class IProductProfile {
  productType: ProductType;
  grade: GradeType | string;
  productName?: string;
  description: string;
}

export class ISupplementalProductProfile extends IProductProfile {
  enableTopicLocking: boolean;
  disableRemedialWork?: boolean;
  preventRetakingPretestsAndPosttests?: boolean;
}

export class ISuperkidsLegacyProductProfile extends IProductProfile {
  topicKey: string;
  avatarCustomization: boolean;
  hideQuests: boolean;
}

export class ClassroomProfile {
  enableLeaderboard: boolean;
  enableDailyRandomQuests: boolean;
  enableParentPortalStudentScores: boolean;
  enableStudentAudioPrompts: boolean;
  @ClassCast(ISupplementalProductProfile)
    gumProfiles: ISupplementalProductProfile[] = [];
  @ClassCast(ISupplementalProductProfile)
    spellingProfiles?: ISupplementalProductProfile[] = [];
  @ClassCast(ISupplementalProductProfile)
    handwritingProfiles?: ISupplementalProductProfile[] = [];
  @ClassCast(ISuperkidsLegacyProductProfile)
    superkidsProfiles?: ISuperkidsLegacyProductProfile[] = [];

  sortProfiles() {
    this.gumProfiles = Helpers.sortClassroomProfilesByProductAndGrade(
      this.gumProfiles) as ISupplementalProductProfile[];
    this.superkidsProfiles = Helpers.sortClassroomProfilesByProductAndGrade(
      this.superkidsProfiles) as ISuperkidsLegacyProductProfile[];
    this.spellingProfiles = Helpers.sortClassroomProfilesByProductAndGrade(
      this.spellingProfiles) as ISupplementalProductProfile[];
    this.handwritingProfiles = Helpers.sortClassroomProfilesByProductAndGrade(
      this.handwritingProfiles) as ISupplementalProductProfile[];
  }
}
