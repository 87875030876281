import { ViewerLanguage } from '@shared/enums/viewer-asset';

export class QuickLessonData {
  weight: number;
  unit: string;
  unit_name: string;
  lesson: string;
  lesson_name: string;
  id: string;
  filename: string;
  type: string;
  language: ViewerLanguage;
  edition: string;
}
