import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { ErrorHandlingService } from '@core/services/error-handling/error-handling.service';
import { ThemeService } from '@core/theme.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/button/button.component';
import { HeaderBrandHandlerComponent } from '@shared/header/header-brand-handler/header-brand-handler.component';
import { SharedModule } from '@shared/shared.module';
import { ZbpIconComponent } from '@shared/zbp-icon/zbp-icon';
import { ToastrModule } from 'ngx-toastr';
import { BrandedAccountComponent } from './account/branded-account/branded-account.component';
import {
  BrandedConfirmPasswordComponent
} from './account/branded-account/branded-confirm-password-modal/branded-confirm-password-modal.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmailNotificationsModalComponent }
  from './email-notifications/email-notifications-modal/email-notifications-modal.component';
import { EmailNotificationsComponent } from './email-notifications/email-notifications.component';
import { LoginModule } from './login/login.module';
import { LogoutComponent } from './logout/logout.component';
import { PreloaderModule } from './shared/preloader/preloader.module';
import { BrandedSiteDownComponent } from './site-down/branded-site-down/branded-site-down.component';
import { SiteDownBrandHandlerComponent } from './site-down/site-down-brand-handler.component';
import { SiteDownComponent } from './site-down/site-down.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule.forBrowser(),
    CoreModule, // singleton module, import only once
    NgbModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      preventDuplicates: true,
      autoDismiss: true,
    }),
    LoginModule, // with routing
    AppRoutingModule,
    ButtonComponent,
    HeaderBrandHandlerComponent,
    ZbpIconComponent,
    PreloaderModule
  ],
  declarations: [
    AppComponent,
    LogoutComponent,
    SiteDownComponent,
    SiteDownBrandHandlerComponent,
    BrandedSiteDownComponent,
    EmailNotificationsComponent,
    EmailNotificationsModalComponent,
    BrandedConfirmPasswordComponent,
    BrandedAccountComponent,
  ],
  providers: [
    ErrorHandlingService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlingService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ts: ThemeService) => () => ts,
      deps: [ThemeService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
