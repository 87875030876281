/* eslint-disable no-param-reassign */
import { Remarkable, utils } from 'remarkable';
import { UNESCAPE_RE } from './helpers';

export interface BracketToken extends Remarkable.ContentToken {
  className?: string;
}

/**
 * ZB Bracket syntax.
 *
 * Formats text according to content sheet rules.
 *
 * This is slightly different from Superkids bracket syntax necessitated because for compatiblity with
 * CommonMark (Markdown). See below.
 *
 * Examples:
 *   - "[fgBlue]ca[fgBlue]t" => '<span class="fgBlue">ca</span>t'.
 *   - "ca[fgBlue]t[fgBlue]" => 'ca<span class="fgBlue">t</span>'.
 *   - "cat" => "cat".
 *   - "[][][]" => "[][][]"
 *   - "[something weird]" => "[something weird]"
 *   - "<span>blah</span>[] [fgBlue]ca[fgBlue]t" => '<span>blah</span>[] <span class="fgBlue">ca</span>t'.
 */
export const bracketPlugin: Remarkable.Plugin = (md: Remarkable, options?: any): void => {
  // eslint-disable-next-line prefer-regex-literals
  const pattern: RegExp = new RegExp(`^\\[([a-zA-Z0-9_-]{1,})\\]([^\\[]+)\\[([a-zA-Z0-9_-]{1,})\\]`, '');

  /**
   * ZB Bracket syntax parser.
   *
   */
  const parser = (state: Remarkable.StateInline, silent: boolean): boolean => {
    const max: number = state.posMax;
    const start: number = state.pos;
    let found = false;
    let match: RegExpMatchArray;

    if (silent
        || (state.pos + 6 >= state.posMax)
        || (options && options.maxNesting && state.level >= options.maxNesting)) {
      // Only continue to parse if we're not validating, the length of the string is acceptable.
      return false;
    }

    while (state.pos < max) {
      const toMatch: string = state.src.slice(state.pos);
      match = toMatch.match(pattern);
      if (match && match.length === 4 && match[1] === match[3]) {
        found = true;
        break;
      }

      state.parser.skipToken(state);
    }

    if (!found || start + 1 === state.pos) {
      // Returns false and resets the position when there is no bracket pair.
      state.pos = start;
      return false;
    }

    // Grabs the content between the bracket pairs based upon the start of the state source.
    const bracketLength = match[1].length + 2;
    const content = state.src.slice(start + bracketLength, start + match[0].length - bracketLength);
    const className = match[1];

    // Sets the position state before pushing a new token.
    state.posMax = state.pos;
    state.pos = start + bracketLength;

    if (!silent) {
      // Adds the content to replace and escape characters to be replaced later.
      state.push({
        className,
        content: content.replace(UNESCAPE_RE, '$1'),
        type: 'bracket',
        level: state.level,
      } as BracketToken);
    }

    // Resets the position state after pushing a new token.
    state.pos = state.posMax + match[0].length;
    state.posMax = max;

    return true;
  };

  // Bracket syntax needs to act as a sort of pre-processor before any normal text operations.
  md.inline.ruler.push('bracket', parser, options);

  md.renderer.rules.bracket = (tokens: any[], index: number): string => {
    const token: BracketToken = tokens[index];
    return `<span class="${utils.escapeHtml(token.className)}">${tokens[index].content}</span>`;
  };
};
