import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache.service';
import { UserService } from '@core/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfirmationModalComponent } from '../../../shared/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root'
})
export class StudentDashboardReturnService {
  modalRef: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private cacheService: CacheService) { }

  returnToStudentDashboard() {
    // open modal for when the user returns from the vendor to reload the page
    this.modalRef = this.modalService.open(ConfirmationModalComponent, { size: 'md' });
    this.modalRef.componentInstance.title = `Welcome Back ${this.userService.user.firstName}!`;
    this.modalRef.componentInstance.message = 'Click the button below to reload your dashboard.';
    this.modalRef.componentInstance.displayCancelButton = false;
    this.modalRef.componentInstance.confirmButtonText = 'OK';

    from(this.modalRef.result)
      .pipe(
        catchError(() => of(false))
      )
      .subscribe((response) => {
        if (response) {
          this.modalRef.dismiss();
          if (this.cacheService.redirectToDR) {
            this.cacheService.clearCachedPattern('/student');
            this.cacheService.clearRedirectToDR();
          }
          window.location.reload();
        }
        this.modalRef.dismiss();
      });

  }
}
