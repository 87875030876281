export enum TopicType {
  Assessment = 'Assessment',
  Lesson = 'Lesson',
  Practice = 'Practice',
  Game = 'Game',
  Writing = 'Writing',

  // Handwriting.
  CustomFreeWrite = 'CustomFreeWrite',
  FreeWrite = 'FreeWrite',
  Trace = 'Trace',

  // Spelling.
  WordSort = 'WordSort',
  WordList = 'WordList',
  Pretest = 'Pretest',
  Posttest = 'Posttest',
  // Note that PracticeTest has a different case than Pretest/Posttest.
  PracticeTest = 'PracticeTest',
  PatternActivity = 'PatternActivity',
  ExtraPatternPractice = 'ExtraPatternPractice',

  // Superkids.
  DailyLesson = 'DailyLesson',
  Book = 'Book',
}

export enum TopicActivityType {
  FillInTheBlank = 'FillInTheBlank',
  Identify = 'Identify',
  Highlight = 'Highlight',
  SentenceManipulation = 'SentenceManipulation',
  MultipleChoice = 'MultipleChoice',
  Rewrite = 'Rewrite',
  Video = 'Video',
  Writing = 'Writing',
  Assessment = 'Assessment',
  Trace = 'Trace',
  FreeWrite = 'FreeWrite',
  Completion = 'Completion',
  Test = 'Test',
  PatternActivity = 'PatternActivity',
  Hangman = 'Hangman',
  LetterSearch = 'LetterSearch',
  Proofreading = 'Proofreading',
  Unscramble = 'Unscramble',
  WordSpellTimed = 'WordSpellTimed',
  WordSpellUntimed = 'WordSpellUntimed',
  WordSort = 'WordSort',
  PreTest = 'PreTest',
  PracticeTest = 'PracticeTest',
  PostTest = 'PostTest',
  Book = 'Book',
}
