import { SpellingLevelType } from '@shared/enums/spelling-level-type';
import { TopicType  } from '@shared/enums/topic-type';
import { Quest } from '../quest';

export class SpellingQuest extends Quest {
  detailKey = 'digitalResourcesSpellingActivityQuestDetail';
  topicKey: string;
  topicType: TopicType;
  name?: string;
  score?: string;
  activityId?: string;
  wordListLevel: SpellingLevelType;
  updatedOn: Date | string;

  constructor(data?: {[key: string]: any}) {
    super(data);
    if (data) {
      this.setAllowedProperties(data, this.detailKey);
    }
  }

  getAllowedPropertyNames(): string[] {
    return [
      'activityId',
      'name',
      'topicKey',
      'topicType',
      'wordListLevel',
    ];
  }

  label(): string {
    return this.description ? this.description : `${this.topicType} - ${this.name}`;
  }

  typeLabel(): string {
    return 'Spelling Connections Activity';
  }
}
