import { Quest } from '../quest';

import { AggregateQuest } from './aggregate';
import { AssessmentQuest } from './assessment';
import { AvatarQuest } from './avatar';
import { GumQuest } from './gum-activity';
import { HandwritingQuest } from './handwriting-activity';
import { SpellingQuest } from './spelling-activity';

export type QuestClass = Quest
  | AggregateQuest
  | AssessmentQuest
  | AvatarQuest
  | GumQuest
  | HandwritingQuest
  | SpellingQuest;

export default {
  AggregateQuest,
  AssessmentQuest,
  AvatarQuest,
  GumQuest,
  HandwritingQuest,
  SpellingQuest,
};
