import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ApiResponse } from '@models/api-response';
import { KeyValuePair } from '@models/key-value-pair';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryApiService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) { }

  getStates(): Observable<ApiResponse<KeyValuePair[]>> {
    const url = `${this.appConfig.apiUrl}/application/country/us/state`;
    return this.http.get(url)
      .pipe(
        map((res: ApiResponse<any[]>) => new ApiResponse<KeyValuePair[]>(true, {
          response: res.response.map(v => ({ key: `US-${v.code}`, value: v.displayValue })),
          messages: res.messages,
        })),
      );
  }

  getCountries(): Observable<ApiResponse<KeyValuePair[]>> {
    const url = `${this.appConfig.apiUrl}/application/country`;
    return this.http.get(url)
      .pipe(
        map((res: ApiResponse<any[]>) => new ApiResponse<KeyValuePair[]>(true, {
          response: res.response.map(v => ({ key: v.code, value: v.displayValue })),
          messages: res.messages,
        }))
      );
  }

}
