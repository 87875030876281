export interface Global {
  tinymce?: any;
}

export abstract class GlobalRef {
  abstract get nativeGlobal(): Global;
}

export class BrowserGlobalRef extends GlobalRef {
  get nativeGlobal(): Global { return (window as any) as Global; }
}
