/**
 * Function prevents a module from being loaded by anthing other than AppModule
 * See: https://angular.io/docs/ts/latest/guide/style-guide.html#04-12
 * @param parentModule
 * @param moduleName
 */
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}
