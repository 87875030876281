import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@core/user.service';
import { IUser } from '@models/user';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private userService: UserService) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permissions = route.data.permissions ? route.data.permissions : [];
    return of(this.userHasPermissions(permissions, this.userService.user));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  userHasPermissions(permissions: string[], user: IUser): boolean {
    if (permissions.length === 0) {
      return true;
    }

    return permissions.reduce((ret, permission) => (ret ? user.profileDetail?.[permission] : ret), true);
  }
}
