<div class="d-flex align-items-center text-center separator mt-3">
  <span class="line"></span>
  <span class="mx-3">OR</span>
  <span class="line"></span>
</div>
<div class="d-flex flex-column align-items-center my-3">
  <button class="btn-sso my-1 btn-google" (click)="ssoLogin(OAuth2Provider.google)"></button>
  <button class="btn-sso my-1 btn-clever" (click)="ssoLogin(OAuth2Provider.clever)"></button>
  <button class="btn-sso my-1 btn-classlink" (click)="ssoLogin(OAuth2Provider.classlink)"></button>
</div>
