import { Injectable } from '@angular/core';

import { AgreementApiService } from '@core/services/application/agreement/agreement-api.service';
import { UserService } from '@core/user.service';
import { Agreement } from '@models/agreement';
import { ApiResponse } from '@models/api-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AgreementService {
  constructor(private agreementApiService: AgreementApiService, private userService: UserService) { }

  getDefaultAgreement(): Observable<ApiResponse<Agreement[]>> {
    return this.agreementApiService.getDefaultAgreement()
      .pipe(
        map(res => new ApiResponse<Agreement[]>(true, res)),
      );
  }

  agreeToDefaultAgreement(agreementId: number): Observable<ApiResponse<boolean>> {
    return this.agreementApiService.agreeToDefaultAgreement(agreementId)
      .pipe(
        map(() => {
          this.userService.hasAgreed = true;
          this.userService.firstLogin = true;
          return new ApiResponse<boolean>(true, { response: true, messages: [] });
        }),
      );
  }

}
