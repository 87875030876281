import { StandardResponseMessage } from './standard-response-message';

export class TypeResponseMessage<T> extends StandardResponseMessage {
  errors: string[];
  success: boolean;
  result: T;

  constructor(success?: boolean, errors?: string[], result?: T) {
    super(success, errors);
    this.result = result;
  }
}
