export const schoolAdminAreaIcon = `<svg xmlns="http://www.w3.org/2000/svg"
     width="76"
     height="66"
     viewBox="0 0 76 66"
     fill="none">
  <g clip-path="url(#clip0_12380_4232)">
    <path d="M72.4685 35.8604H2.58203V63.147H72.4685V35.8604Z"
          fill="var(--zbp-svg-secondary-color, white)"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
    <path
        d="M2.35686 42.1117C2.09193 42.1065 1.83213 42.0376 1.5994 41.9109C1.36667 41.7842 1.16784 41.6034 1.01966 41.3837C0.78499 41.0542 0.629376 40.6751 0.564908 40.2758C0.50044 39.8764 0.528836 39.4676 0.647912 39.081L2.27427 33.6805C2.36389 33.2845 2.57832 32.9277 2.88599 32.6628C3.19366 32.3979 3.57831 32.2388 3.98322 32.209H71.061C71.465 32.2399 71.8484 32.3994 72.1551 32.6643C72.4617 32.9291 72.6754 33.2852 72.7648 33.6805L74.3911 39.081C74.5097 39.4673 74.5384 39.8757 74.4748 40.2749C74.4113 40.674 74.2573 41.0533 74.0245 41.3837C73.8746 41.6029 73.6746 41.7832 73.4412 41.9098C73.2078 42.0364 72.9476 42.1056 72.6822 42.1117H2.35686Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M71.0641 32.725C71.3572 32.7572 71.6326 32.8815 71.8506 33.08C72.0686 33.2785 72.218 33.5411 72.2774 33.8299L73.8986 39.2304C73.9956 39.54 74.02 39.8678 73.9699 40.1884C73.9197 40.5089 73.7965 40.8136 73.6095 41.0788C73.5056 41.2302 73.3681 41.3555 73.2078 41.4451C73.0475 41.5347 72.8687 41.586 72.6853 41.5951H2.36002C2.17662 41.586 1.99781 41.5347 1.83751 41.4451C1.67721 41.3555 1.53976 41.2302 1.43585 41.0788C1.2489 40.8136 1.12555 40.5089 1.07541 40.1884C1.02527 39.8678 1.04968 39.54 1.14672 39.2304L2.76792 33.8299C2.82731 33.5411 2.97674 33.2785 3.19475 33.08C3.41276 32.8815 3.68811 32.7572 3.98121 32.725H71.0641ZM71.0641 31.6924H3.98121C3.46569 31.7215 2.97328 31.9161 2.57708 32.2472C2.18087 32.5783 1.90198 33.0283 1.78176 33.5304L0.139932 38.931C-0.376368 40.7329 0.728525 42.6277 2.34455 42.6277H72.6698C74.2858 42.6277 75.4165 40.7329 74.8744 38.931L73.2481 33.5304C73.1278 33.0283 72.849 32.5783 72.4527 32.2472C72.0565 31.9161 71.5641 31.7215 71.0486 31.6924H71.0641Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M18.3863 63.6274C17.6891 63.6469 17.0126 63.3895 16.5047 62.9116C15.9967 62.4336 15.6987 61.774 15.6758 61.0769V35.5818C15.6876 34.8862 15.9711 34.2228 16.4657 33.7334L34.0199 15.7248C34.3761 15.3571 34.8026 15.0647 35.274 14.8651C35.7454 14.6654 36.2521 14.5625 36.7641 14.5625C37.276 14.5625 37.7827 14.6654 38.2541 14.8651C38.7255 15.0647 39.152 15.3571 39.5082 15.7248L57.0624 33.7334C57.557 34.2228 57.8405 34.8862 57.8524 35.5818V61.0769C57.8308 61.7744 57.5331 62.4349 57.0249 62.9131C56.5166 63.3914 55.8393 63.6483 55.1418 63.6274H18.3863Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M36.7623 15.0785C37.2044 15.0776 37.6421 15.1659 38.0492 15.338C38.4564 15.5101 38.8247 15.7625 39.1321 16.0801L56.6863 34.094C57.0846 34.4878 57.3142 35.021 57.3265 35.5809V61.076C57.3037 61.6362 57.0601 62.1645 56.649 62.5457C56.2378 62.9268 55.6926 63.1298 55.1323 63.1102H18.3872C17.8269 63.1298 17.2816 62.9268 16.8705 62.5457C16.4593 62.1645 16.2157 61.6362 16.1929 61.076V35.5809C16.2053 35.021 16.4348 34.4878 16.8331 34.094L34.3873 16.0801C34.6957 15.7623 35.0649 15.5099 35.473 15.3378C35.881 15.1657 36.3195 15.0775 36.7623 15.0785ZM36.7623 14.0459C36.1842 14.047 35.612 14.164 35.0799 14.3902C34.5478 14.6163 34.0665 14.947 33.6645 15.3625L16.1103 33.3763C15.5211 33.9598 15.1854 34.7518 15.1758 35.5809V61.076C15.1974 61.9105 15.5494 62.7022 16.1545 63.2773C16.7596 63.8523 17.5682 64.1637 18.4027 64.1428H55.1478C55.9823 64.1637 56.7909 63.8523 57.3959 63.2773C58.001 62.7022 58.353 61.9105 58.3747 61.076V35.5809C58.365 34.7518 58.0293 33.9598 57.4401 33.3763L39.8859 15.3625C39.4839 14.947 39.0026 14.6163 38.4705 14.3902C37.9384 14.164 37.3663 14.047 36.7881 14.0459H36.7623Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M36.8304 36.6431C35.8214 36.638 34.8365 36.334 34.0001 35.7696C33.1637 35.2052 32.5132 34.4057 32.1309 33.4719C31.7485 32.5381 31.6513 31.512 31.8517 30.523C32.052 29.5341 32.5409 28.6267 33.2565 27.9153C33.9722 27.204 34.8825 26.7206 35.8726 26.5263C36.8628 26.3319 37.8883 26.4353 38.8198 26.8233C39.7512 27.2113 40.5469 27.8666 41.1062 28.7064C41.6655 29.5463 41.9634 30.533 41.9624 31.542C41.9611 32.2141 41.8272 32.8794 41.5684 33.4997C41.3096 34.12 40.9311 34.6831 40.4544 35.157C39.9777 35.6308 39.4122 36.0059 38.7904 36.2609C38.1685 36.5159 37.5025 36.6458 36.8304 36.6431Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M36.8272 26.9486C37.735 26.9486 38.6224 27.2178 39.3772 27.7222C40.132 28.2265 40.7203 28.9434 41.0677 29.7821C41.4151 30.6208 41.506 31.5436 41.3289 32.434C41.1518 33.3244 40.7146 34.1422 40.0727 34.7841C39.4308 35.4261 38.6129 35.8632 37.7226 36.0403C36.8322 36.2174 35.9094 36.1265 35.0707 35.7791C34.232 35.4317 33.5151 34.8434 33.0108 34.0886C32.5064 33.3338 32.2373 32.4464 32.2373 31.5386C32.2386 30.3217 32.7226 29.155 33.5831 28.2945C34.4436 27.434 35.6103 26.95 36.8272 26.9486ZM36.8272 25.916C35.7165 25.9211 34.6321 26.2551 33.711 26.8757C32.7899 27.4964 32.0733 28.376 31.6516 29.4035C31.2298 30.4311 31.1219 31.5605 31.3413 32.6493C31.5607 33.7381 32.0977 34.7376 32.8845 35.5215C33.6713 36.3055 34.6728 36.8388 35.7624 37.0543C36.852 37.2697 37.981 37.1576 39.007 36.7321C40.0329 36.3066 40.9098 35.5867 41.5271 34.6633C42.1444 33.74 42.4744 32.6544 42.4755 31.5437C42.4755 30.0525 41.8831 28.6224 40.8287 27.568C39.7743 26.5135 38.3441 25.9212 36.8529 25.9212L36.8272 25.916Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path d="M36.6602 28.6416V32.0027"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
          stroke-linecap="round"
    />
    <path d="M36.668 32.0049H39.5025"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
          stroke-linecap="round"
    />
    <path
        d="M42.5038 2.2168H37.8054C37.3606 2.2168 37 2.5774 37 3.02223V7.16299C37 7.60782 37.3606 7.96842 37.8054 7.96842H42.5038C42.9486 7.96842 43.3092 7.60782 43.3092 7.16299V3.02223C43.3092 2.5774 42.9486 2.2168 42.5038 2.2168Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M42.5006 2.7328C42.5385 2.7328 42.5761 2.74028 42.6112 2.75481C42.6462 2.76934 42.6781 2.79064 42.7049 2.81749C42.7318 2.84433 42.7531 2.87621 42.7677 2.91129C42.7822 2.94637 42.7897 2.98396 42.7897 3.02193V7.15236C42.7897 7.22904 42.7592 7.30259 42.7049 7.35681C42.6507 7.41103 42.5772 7.4415 42.5006 7.4415H37.797C37.7212 7.44014 37.649 7.40908 37.5959 7.355C37.5428 7.30093 37.5131 7.22816 37.5131 7.15236V3.02193C37.5131 2.94614 37.5428 2.87337 37.5959 2.81929C37.649 2.76521 37.7212 2.73416 37.797 2.7328H42.5006ZM42.5006 1.7002H37.797C37.6237 1.70019 37.4521 1.73442 37.292 1.80091C37.1319 1.8674 36.9866 1.96483 36.8642 2.08764C36.7419 2.21044 36.645 2.35618 36.5792 2.51652C36.5133 2.67685 36.4798 2.8486 36.4805 3.02193V7.15236C36.4798 7.32569 36.5133 7.49745 36.5792 7.65778C36.645 7.81811 36.7419 7.96386 36.8642 8.08667C36.9866 8.20947 37.1319 8.3069 37.292 8.37339C37.4521 8.43988 37.6237 8.47411 37.797 8.47411H42.5006C42.8511 8.47411 43.1873 8.33485 43.4352 8.08697C43.683 7.8391 43.8223 7.50291 43.8223 7.15236V3.02193C43.8223 2.67138 43.683 2.3352 43.4352 2.08733C43.1873 1.83945 42.8511 1.7002 42.5006 1.7002Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M36.7627 12.5383C36.5573 12.5383 36.3603 12.4567 36.2151 12.3114C36.0699 12.1662 35.9883 11.9692 35.9883 11.7638V1.72172C35.9883 1.51632 36.0699 1.31933 36.2151 1.17409C36.3603 1.02886 36.5573 0.947266 36.7627 0.947266C36.9681 0.947266 37.1651 1.02886 37.3103 1.17409C37.4555 1.31933 37.5372 1.51632 37.5372 1.72172V11.7638C37.5372 11.9692 37.4555 12.1662 37.3103 12.3114C37.1651 12.4567 36.9681 12.5383 36.7627 12.5383Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M27.7734 63.5541V43.4699C27.7734 42.9441 27.9823 42.4398 28.3541 42.068C28.7259 41.6962 29.2302 41.4873 29.756 41.4873H44.863C45.3888 41.4873 45.8931 41.6962 46.2649 42.068C46.6367 42.4398 46.8455 42.9441 46.8455 43.4699V63.5541H27.7734Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M44.8636 41.9949C45.2525 41.9949 45.6255 42.1494 45.9005 42.4244C46.1755 42.6994 46.3299 43.0724 46.3299 43.4612V63.0395H28.2904V43.4716C28.2904 43.0827 28.4449 42.7097 28.7199 42.4347C28.9949 42.1598 29.3678 42.0053 29.7567 42.0053H44.8636V41.9949ZM44.8636 40.9727H29.7567C29.4283 40.972 29.1031 41.0362 28.7996 41.1615C28.4961 41.2868 28.2204 41.4709 27.9882 41.7031C27.756 41.9352 27.572 42.211 27.4466 42.5145C27.3213 42.818 27.2571 43.1432 27.2578 43.4716V64.0721H47.3625V43.4716C47.3632 43.1432 47.299 42.818 47.1737 42.5145C47.0484 42.211 46.8643 41.9352 46.6321 41.7031C46.3999 41.4709 46.1242 41.2868 45.8207 41.1615C45.5173 41.0362 45.192 40.972 44.8636 40.9727Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path d="M37.5352 41.5439V63.776"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
    <path
        d="M13.6714 38.7716C13.2572 38.7718 12.8594 38.6097 12.5632 38.3202C12.2669 38.0308 12.0958 37.6368 12.0864 37.2227C12.0767 36.8035 12.2325 36.3973 12.52 36.092L35.7535 11.5728C35.902 11.4198 36.0804 11.2991 36.2777 11.2183C36.4749 11.1374 36.6866 11.0982 36.8997 11.1029C37.1122 11.099 37.3234 11.1382 37.5204 11.218C37.7174 11.2979 37.8962 11.4168 38.046 11.5676L61.1297 35.932C61.2728 36.0832 61.3847 36.2611 61.459 36.4555C61.5333 36.6499 61.5686 36.8571 61.5628 37.0652C61.5571 37.2733 61.5104 37.4782 61.4254 37.6682C61.3405 37.8582 61.219 38.0297 61.0678 38.1727C60.776 38.4513 60.387 38.6051 59.9836 38.6013C59.7684 38.6016 59.5555 38.5584 59.3576 38.474C59.1597 38.3897 58.981 38.2662 58.8322 38.1108L36.8997 14.9597L14.8176 38.2553C14.6732 38.4167 14.4967 38.5461 14.2992 38.635C14.1018 38.7239 13.8879 38.7705 13.6714 38.7716Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M36.9019 11.598C37.0448 11.5947 37.1869 11.62 37.3199 11.6723C37.4529 11.7246 37.5741 11.8029 37.6764 11.9026L60.7602 36.267C60.9528 36.4733 61.0565 36.7472 61.0487 37.0293C61.041 37.3114 60.9225 37.5792 60.7189 37.7746C60.52 37.9604 60.258 38.0638 59.9858 38.0638C59.8407 38.0662 59.6967 38.038 59.5633 37.9811C59.4299 37.9241 59.3099 37.8397 59.2113 37.7333L37.6506 14.9798L36.9019 14.1899L36.1533 14.9798L14.4687 37.8934C14.375 37.9984 14.2608 38.0832 14.1332 38.1425C14.0055 38.2019 13.8671 38.2345 13.7264 38.2383C13.5857 38.2422 13.4457 38.2173 13.315 38.165C13.1843 38.1128 13.0656 38.0344 12.9663 37.9347C12.8636 37.839 12.781 37.7238 12.7233 37.5959C12.6656 37.4679 12.6339 37.3298 12.63 37.1895C12.6262 37.0492 12.6503 36.9096 12.7009 36.7787C12.7516 36.6478 12.8277 36.5283 12.925 36.4271L36.1585 11.9026C36.2599 11.8031 36.3802 11.7248 36.5123 11.6725C36.6444 11.6201 36.7857 11.5948 36.9278 11.598H36.9019ZM36.9278 10.5654C36.6402 10.5585 36.3542 10.6109 36.0878 10.7192C35.8213 10.8276 35.58 10.9897 35.3789 11.1953L12.1454 35.7197C11.7626 36.1237 11.5561 36.6631 11.5711 37.2194C11.5861 37.7757 11.8215 38.3032 12.2254 38.6859C12.6293 39.0686 13.1688 39.2752 13.725 39.2602C14.2813 39.2452 14.8088 39.0098 15.1915 38.6059L36.8762 15.6871L58.4833 38.4407C58.6725 38.6407 58.8992 38.8015 59.1505 38.9139C59.4018 39.0263 59.6728 39.0881 59.948 39.0957C60.2232 39.1034 60.4972 39.0568 60.7544 38.9586C61.0115 38.8603 61.2469 38.7124 61.4469 38.5233C61.6469 38.3341 61.8077 38.1074 61.9201 37.8561C62.0325 37.6048 62.0943 37.3338 62.102 37.0586C62.1096 36.7834 62.063 36.5094 61.9648 36.2522C61.8666 35.995 61.7187 35.7597 61.5295 35.5597L38.4457 11.1953C38.2457 10.9881 38.0046 10.8251 37.7378 10.7166C37.4711 10.6081 37.1846 10.5566 36.8968 10.5654H36.9278Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path d="M34.543 50.3311V54.6835"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
          stroke-linecap="round"
    />
    <path d="M40.0742 50.3311V54.6835"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
          stroke-linecap="round"
    />
    <path
        d="M73.479 62.3623H1.57388C1.38853 62.3623 1.23828 62.5126 1.23828 62.6979V64.8561C1.23828 65.0414 1.38853 65.1917 1.57388 65.1917H73.479C73.6644 65.1917 73.8146 65.0414 73.8146 64.8561V62.6979C73.8146 62.5126 73.6644 62.3623 73.479 62.3623Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M73.2951 62.8783V64.6699H1.75135V62.8783H73.2951ZM73.4758 61.8457H1.57066C1.34472 61.8457 1.12803 61.9355 0.968266 62.0952C0.808504 62.255 0.71875 62.4717 0.71875 62.6976V64.8557C0.718748 64.9674 0.740822 65.0779 0.783697 65.181C0.826572 65.2841 0.889407 65.3776 0.968581 65.4563C1.04775 65.535 1.14171 65.5973 1.24504 65.6395C1.34837 65.6818 1.45903 65.7032 1.57066 65.7025H73.4551C73.5668 65.7032 73.6774 65.6818 73.7807 65.6395C73.8841 65.5973 73.9781 65.535 74.0572 65.4563C74.1364 65.3776 74.1992 65.2841 74.2421 65.181C74.285 65.0779 74.3071 64.9674 74.3071 64.8557V62.6976C74.3071 62.5857 74.285 62.4749 74.2422 62.3716C74.1994 62.2682 74.1367 62.1743 74.0575 62.0952C73.9784 62.0161 73.8845 61.9534 73.7812 61.9106C73.6778 61.8677 73.567 61.8457 73.4551 61.8457H73.4758Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M19.1055 65.1859V64.7935C19.1068 63.9038 19.4609 63.051 20.0899 62.422C20.719 61.7929 21.5718 61.4389 22.4614 61.4375H51.1987C52.0883 61.4389 52.9412 61.7929 53.5702 62.422C54.1993 63.051 54.5533 63.9038 54.5547 64.7935V65.1859H19.1055Z"
        fill="var(--zbp-svg-secondary-color, white)"
    />
    <path
        d="M51.1955 61.9535C51.9267 61.9541 52.6296 62.2368 53.1576 62.7427C53.6856 63.2486 53.9981 63.9387 54.03 64.6693H19.6185C19.6517 63.9383 19.9654 63.2481 20.4942 62.7424C21.023 62.2366 21.7264 61.9541 22.4582 61.9535H51.1955ZM51.1955 60.9209H22.4582C21.4312 60.9209 20.4463 61.3289 19.7201 62.0551C18.9939 62.7813 18.5859 63.7662 18.5859 64.7932V65.7019H55.0677V64.7932C55.0677 63.7662 54.6598 62.7813 53.9336 62.0551C53.2074 61.3289 52.2225 60.9209 51.1955 60.9209Z"
        fill="var(--zbp-svg-primary-color, black)"
    />
    <path
        d="M11.7811 44.4268H6.86593C6.54086 44.4268 6.27734 44.6903 6.27734 45.0153V49.9306C6.27734 50.2556 6.54086 50.5191 6.86593 50.5191H11.7811C12.1062 50.5191 12.3697 50.2556 12.3697 49.9306V45.0153C12.3697 44.6903 12.1062 44.4268 11.7811 44.4268Z"
        fill="var(--zbp-svg-secondary-color, white)"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.0326"
        stroke-miterlimit="10"
    />
    <path
        d="M11.7811 52.6807H6.86593C6.54086 52.6807 6.27734 52.9442 6.27734 53.2692V58.1845C6.27734 58.5095 6.54086 58.773 6.86593 58.773H11.7811C12.1062 58.773 12.3697 58.5095 12.3697 58.1845V53.2692C12.3697 52.9442 12.1062 52.6807 11.7811 52.6807Z"
        fill="var(--zbp-svg-secondary-color, white)"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.0326"
        stroke-miterlimit="10"
    />
    <path d="M9.31641 44.4268V50.3333"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
    <path d="M9.31641 52.7695V58.6812"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
    <path
        d="M67.742 52.6807H62.8269C62.5018 52.6807 62.2383 52.9442 62.2383 53.2692V58.1845C62.2383 58.5095 62.5018 58.773 62.8269 58.773H67.742C68.0671 58.773 68.3306 58.5095 68.3306 58.1845V53.2692C68.3306 52.9442 68.0671 52.6807 67.742 52.6807Z"
        fill="var(--zbp-svg-secondary-color, white)"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.0326"
        stroke-miterlimit="10"
    />
    <path
        d="M67.742 44.4258H62.8269C62.5018 44.4258 62.2383 44.6893 62.2383 45.0144V49.9296C62.2383 50.2546 62.5018 50.5182 62.8269 50.5182H67.742C68.0671 50.5182 68.3306 50.2546 68.3306 49.9296V45.0144C68.3306 44.6893 68.0671 44.4258 67.742 44.4258Z"
        fill="var(--zbp-svg-secondary-color, white)"
        stroke="var(--zbp-svg-primary-color, black)"
        stroke-width="1.0326"
        stroke-miterlimit="10"
    />
    <path d="M65.2812 44.4258V50.3323"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
    <path d="M65.2812 52.7695V58.6812"
          stroke="var(--zbp-svg-primary-color, black)"
          stroke-width="1.0326"
          stroke-miterlimit="10"
    />
  </g>
  <defs>
    <clipPath id="clip0_12380_4232">
      <rect width="75"
            height="64.7556"
            fill="var(--zbp-svg-secondary-color, white)"
            transform="translate(0.0117188 0.947266)"
      />
    </clipPath>
  </defs>
</svg>`;
