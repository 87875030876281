import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { School } from '@models/school';
import { AccessDeniedReason } from '@shared/enums/access-denied-reason';

@Injectable({
  providedIn: 'root',
})
export class AccessControlService {
  constructor(
    private router: Router,
  ) { }

  isSchoolAllowed(school: School): boolean {
    // Scenario 1 - No Licenses
    if (this.noLicensesForSchool(school)) {
      this.routeToNoAccessPageWithReason(school, AccessDeniedReason.NoLicenses);
      return false;
    }

    // Scenario 2 - Future Licenses
    if (this.onlyFutureLicenses(school)) {
      this.routeToNoAccessPageWithReason(school, AccessDeniedReason.OnlyFutureLicenses);
      return false;
    }

    // Scenario 3 - No School ID Set, No Valid Licenses, No Distributed Licences
    if (this.noSchoolIdNorActiveNorDistributedLicenses(school)) {
      this.routeToNoAccessPageWithReason(school, AccessDeniedReason.NoSchoolIdNorActiveLicenses);
      return false;
    }

    //Scenario 4 - No Active Licenses, No Distributed Licenses, School ID is set
    if (this.noActiveNorDistributedLicensesHasSchoolId(school)) {
      this.routeToNoAccessPageWithReason(school, AccessDeniedReason.NoSchoolIdNorActiveLicenses);
      return false;
    }

    //Scenario 5 - No Distributed Licenses, Has Active License, School ID is set
    if (this.noDistributedLicensesHasSchoolIdAndActiveLicense(school)) {
      this.routeToNoAccessPageWithReason(
        school,
        AccessDeniedReason.NoDistributedLicensesHasSchoolIdAndSActiveLicense);
      return false;
    }

    return true;
  }

  routeToNoAccessPageWithReason(school: School, accessDeniedReason: AccessDeniedReason) {
    if (school) {
      this.router.navigate(
        [`schools/${school.schoolId}/no-access`],
        { state: { reason: accessDeniedReason, school } });
    }
  }

  private noLicensesForSchool(school: School) {
    return school && !school.hasAvailableLicenses;
  }

  private onlyFutureLicenses(school: School) {
    return school?.hasFutureActivation;
  }

  private noSchoolIdNorActiveNorDistributedLicenses(school: School) {
    return school && !school.schoolId && !school.hasLicensesAssigned && !school.hasLicensesDesignated;
  }

  private noActiveNorDistributedLicensesHasSchoolId(school: School) {
    return school?.schoolId && !school.hasLicensesAssigned && !school.hasLicensesDesignated;
  }

  private noDistributedLicensesHasSchoolIdAndActiveLicense(school: School) {
    return school?.schoolId && school.hasLicensesAssigned && !school.hasLicensesDesignated;
  }

  isUnauthorizedRoute(url: string, additionalRoutes: string[] = []): boolean {
    const unAuthorizedRoutes: string[] = [
      '/logout',
      '/login',
      '/login/user',
      '/login/teacher',
      '/login/student',
      '/login/skof',
      '/login/forgot-password',
      '/invalid-token',
      '/set-password',
      '/account-setup',
      '/clever',
      '/lti-token-exchange',
    ].concat(additionalRoutes);
    return unAuthorizedRoutes.includes(url.replace(/\?.*$/, ''));
  }
}
