import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfigService } from '@core/appconfig.service';
import { CacheService } from '@core/cache.service';
import { UserService } from '@core/user.service';
import { ApiResponse } from '@models/api-response';
import { IUser } from '@models/user';
import { UserStatusResponse } from '@models/user-response';
import { UserRoleDomains } from '@models/user-role-company-codes';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyCode } from '@shared/enums/company-code';
import { MasqueradeAbilities } from '@shared/enums/masquerade-abilities';
import {
  rolesDigitalAdminCanMasqueradeAs, externalRolesThatCanMasquerade,
  internalRolesThatCanMasquerade, RoleType
} from '@shared/enums/role-type';
import { FormHelpers } from '@shared/form-helpers';
import { Helpers } from '@shared/helpers';
import { MasqueradeTextHelpers } from '@shared/masquerade-helpers/masquerade-text-helper';
import { GenericConfirmModalComponent } from '@shared/modals/generic-confirm-modal/generic-confirm-modal.component';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, catchError, filter, from,  map,  mergeMap, of } from 'rxjs';
import { AuthenticationService } from '../../authentication.service';
import { resetStore } from '../../store';
import { CoreFacade } from '../../store/facade';

/**
 * Manages user masquerading functionalities including login and exiting masquerade.
 * Determines role availability per domain.
 * Responsible for updating AuthenticationService and UserService based on masquerade data.
 * MasqueradeService should never be referenced within AuthenticationService or UserService.
 */
@Injectable({
  providedIn: 'root',
})
export class MasqueradeService {
  constructor(
    private authService: AuthenticationService,
    private appConfig: AppConfigService,
    private userService: UserService,
    private coreFacade: CoreFacade,
    private toastr: ToastrService,
    private router: Router,
    private cacheService: CacheService,
    public modalService: NgbModal,
    private http: HttpClient,
  ) { }

  masqueradeOriginUrl: string = null;
  externalRolesThatCanMasquerade: RoleType[] = externalRolesThatCanMasquerade.roles;
  internalRolesThatCanMasquerade: RoleType[] = internalRolesThatCanMasquerade.roles;
  rolesDigitalAdminCanMasqueradeAs:RoleType[] = rolesDigitalAdminCanMasqueradeAs.roles;

  siteNames = {
    [CompanyCode.HighlightsPortal]: 'Highlights Portal',
    [CompanyCode.ZBPortal]: 'ZB Portal',
  };

  private navigationSubscription: Subscription = null;

  /* eslint-disable max-len*/
  /**
   * Used for masquerading as other users
   * @param {string} email User to masquerade as
   * @param {boolean} verifyDomainAllowed Multi-Level Masquerade prevents masquerading on domains when user under masquerade does not have roles for that domain.
   */
  /* eslint-enable max-len*/
  loginAs(email: string, verifyDomainAllowed: boolean = false): Observable<ApiResponse<boolean>> {
    return this.http.post(this.appConfig.getAuthUrl(`user/masquerade/${encodeURIComponent(email)}`), '')
      .pipe(
        map((res: ApiResponse<UserStatusResponse>) => res.response),
        map((status: UserStatusResponse) => {
          const response = new ApiResponse<boolean>(true, {});
          if (status.user) {
            if (verifyDomainAllowed) {
              let hasRoleOnCurrentDomain = false;

              status.user?.userMasqueradeCompanyCodes.forEach((role: UserRoleDomains) => {
                if (role.companyCodes.includes(this.appConfig.loginBrand)) {
                  hasRoleOnCurrentDomain = true;

                }
              });

              if (hasRoleOnCurrentDomain) {
                if (this.userService.user.isInternal) {
                  this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade = true;
                }
                this.userService.clearUserState(false, false);
                this.appConfig.clearCustomData();
                this.userService.isLoggingOut = false;
                this.cacheService.coreApiToken = status.token;
                this.userService.isMasqueraded = true;
                this.userService.setUserData(status);
                this.appConfig.setCustomUrls(status);

              } else {
                response.messages = [MasqueradeTextHelpers.getUnableToMasqueradeText(this.appConfig.loginBrand)];
                response.success = false;
              }
            } else {
              if (this.userService.user.isInternal) {
                this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade = true;
              }
              this.userService.clearUserState(false, false);
              this.appConfig.clearCustomData();
              this.userService.isLoggingOut = false;
              this.cacheService.coreApiToken = status.token;
              this.userService.isMasqueraded = true;
              this.userService.setUserData(status);
              this.appConfig.setCustomUrls(status);
            }
          } else {
            response.success = false;
            response.response = false;
            Helpers.setMessages(['User data not returned']);
          }

          return response;
        }),
      );
  }

  /**
   * Handles quick masquerade login flow.
   * Quick Masquerade is only available to CX/App Admins
   * @param {string} email - The email of the user to login as.
   * @param {FormGroup} form - The form group containing email validation controls.
   */
  beginQuickMasquerade(email: string, form: FormGroup) {
    // Track which Role started this current level of masquerading
    this.cacheService.usersLastRole = this.userService.user.profileDetail.viewingAsRole;

    if (form.valid) {
      const trimmed = email.trim();

      this.loginAs(trimmed, true)
        .subscribe((res: ApiResponse<boolean>) => {
          if (res.success) {
            this.afterSuccessfulMasqueradeLogin();
          } else {
            this.toastr.error(res.messages[0]);
          }
        });
    } else {
      const errors = FormHelpers.getAllFormErrors(form).reduce((message, error) => (
        `${message}<div>${error}</div>`
      ), '');
      this.toastr.error(errors, '', { enableHtml: true });
    }
  }

  /**
   * Handles masquerade login flow.
   * @param {string} email - The email of the user to login as.
   */
  beginMasquerade(email: string) {
    this.masqueradeOriginUrl = this.router.url;
    this.cacheService.masqueradeOriginUrl = this.router.url;
    // Track which Role started this current level of masquerading
    this.cacheService.usersLastRole = this.userService.user.profileDetail.viewingAsRole;

    this.loginAs(email)
      .subscribe((res: ApiResponse<boolean>) => {
        if (res.success) {
          this.afterSuccessfulMasqueradeLogin();
        } else {
          this.toastr.error(res.messages[0]);
        }
      });
  }

  private afterSuccessfulMasqueradeLogin() {
    this.coreFacade.dispatch(resetStore());
    this.userService.user.profileDetail.viewingAsRole = null;
    this.router.navigate(['/']);
  }

  get userRoleCxSupportOrAboveBeforeMasquerade(): boolean {
    return this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade;
  }

  set userRoleCxSupportOrAboveBeforeMasquerade(value: boolean) {
    this.cacheService.userRoleCxSupportOrAboveBeforeMasquerade = value;
  }

  /**
   * Determines if the user can masquerade on the current domain.
   * @param {UserRoleDomains[]} userRolesWithDomainList - Array of user roles with associated domains,
   * typically will be user's `userMasqueradeCompanyCodes` property.
   * @returns {boolean} Whether the user has a role on the current domain.
   */
  canMasqueradeOnDomain(userRolesWithDomainList: UserRoleDomains[] = []): boolean {
    let hasRoleOnCurrentDomain = false;

    userRolesWithDomainList.forEach((role) => {
      if (role.companyCodes.includes(this.appConfig.loginBrand)) {
        hasRoleOnCurrentDomain = true;
      }
    });

    return hasRoleOnCurrentDomain;
  }

  /**
   * Determines if a specific role can be masqueraded.
   * Will check for domain and role authentication.
   * A user can have multiple roles where certain roles are only available on certain domains.
   * Depending on current user's role, only certain roles can be masqueraded.
   * If a user has multiple roles, they are only able to be masqueraded with the authorized roles.
   * @param {RoleType} roleToCheck - The role type to check.
   * @param {UserRoleDomains[]} userRolesWithDomainList - Array of user roles with associated domains,
   * typically will be user's `userMasqueradeCompanyCodes` property.
   * @returns {boolean} Whether the specified role can be masqueraded on the current domain.
   */
  roleCanBeMasqueraded(roleToCheck: RoleType, userRolesWithDomainList: UserRoleDomains[]): boolean {
    if (!this.isRoleAuthorized(roleToCheck)) {
      return false;
    }

    const canBeMasqueraded = userRolesWithDomainList?.find(
      x => x.roleType === roleToCheck && x.companyCodes.includes(this.appConfig.loginBrand)
    );

    return !!canBeMasqueraded;
  }

  /**
   * Checks to see if the current user's role is allowed to masquerade as other Roles.
   * This is entirely role-based and has no considerations for domain.
   * Digital Admins (District and School) can masquerade only as a Teacher
   * Internal Admins do not have role limitations
   * @param {RoleType} roleToCheck The role to check if current masquerade user is authorized
   * @returns {boolean}
   */
  isRoleAuthorized(roleToCheck: RoleType): boolean {
    const roleMasqueradingFrom = this.cacheService.usersLastRole as RoleType;

    // Internal Roles are authorized
    if (this.internalRolesThatCanMasquerade.includes(roleMasqueradingFrom)) {
      return true;
    }

    // Only certain External Roles can masquerade
    if (this.externalRolesThatCanMasquerade.includes(roleMasqueradingFrom)) {
      // Digital Admins can only masquerade as a Teacher
      if (this.rolesDigitalAdminCanMasqueradeAs.includes(roleToCheck)) {
        return true;
      }
      // All other roles are unauthorized for Digital Admins
      return false;
    }

    return false;
  }

  /**
   * Determines the masquerade ability of a user.
   * Used to quickly determine if user can masquerade on the current domain, different domain, both domains, or neither.
   * @param {IUser} user - The user object to check.
   * @returns {MasqueradeAbilities} The masquerade abilities of the user.
   */
  getMasqueradeAbility(user: IUser): MasqueradeAbilities {
    if (!user.canMasquerade) {
      return MasqueradeAbilities.None;
    }

    const notCurrentUser = user.userId !== this.userService.user.userId;

    if (notCurrentUser) {
      const canMasqueradeOnCurrentDomain = this.canMasqueradeOnDomain(user.userMasqueradeCompanyCodes);

      if (user.canMasquerade) {
        if (canMasqueradeOnCurrentDomain) {
          return MasqueradeAbilities.CurrentDomain;
        }

        return MasqueradeAbilities.DifferentDomain;
      }
    }

    return MasqueradeAbilities.None;
  }

  confirmLoginAsModal(email: string) {
    const confirmMasqModalRef = GenericConfirmModalComponent.open(this.modalService, {
      title: null,
      body: MasqueradeTextHelpers.ADMIN_TO_TEACHER_CONFIRMATION_TEXT,
      confirm: { label: 'Confirm' },
      options: { size: 'lg' }
    });

    return from(confirmMasqModalRef.result)
      .pipe(
        catchError(() => of(false)),
        mergeMap((confirmed: any) => {
          if (confirmed) {
            return of(this.beginMasquerade(email));
          }

          return of(null);
        })
      );
  }

  confirmStopMasqueradeModal() {
    if (this.userRoleCxSupportOrAboveBeforeMasquerade) {
      return of(this.exitMasquerade());
    }

    const verifyStopMasqueradeModalRef = GenericConfirmModalComponent.open(this.modalService, {
      title: null,
      body: MasqueradeTextHelpers.EXIT_MASQUERADE_DESCRIPTION_TEXT,
      confirm: { label: MasqueradeTextHelpers.CONFIRM_EXIT_MASQUERADE_TEXT },
      options: { size: 'lg' }
    });

    return from(verifyStopMasqueradeModalRef.result)
      .pipe(
        catchError(() => of(false)),
        mergeMap((confirmed: any) => {
          if (confirmed) {
            return of(this.exitMasquerade());
          }
          return of(null);
        })
      );
  }

  private pathByRole = {
    [RoleType.SchoolAdministrator]: 'schools',
    [RoleType.DistrictAdministrator]: 'districts',
    [RoleType.ApplicationAdministrator]: 'admin/app',
    [RoleType.CustomerServiceAdministrator]: 'admin/cx',
    [RoleType.CustomerServiceElevatedAdministrator]: 'admin/cx',
  };

  /**
   * Handles multi-level exit masquerade functionality.
   * The API will return the original user data for the user who initiated the entire masquerade process.
   * Depending on the role that initiated the masquerade session,
   * the user will be navigated to either a specific page for that role,
   * or be navigated back to the page where the masquerade session started.
   */
  exitMasquerade(): void {
    if (this.userService.isMasqueraded) {
      this.masqueradeOriginUrl = this.cacheService.masqueradeOriginUrl;
      this.restoreLogin()
        .pipe(
          mergeMap((res: any) => {
            const path: string[] = [];
            if (res.success) {
              this.userService.setUserData(res.response);

              const backToRole: RoleType = this.userService.user.profileDetail?.viewingAsRole;
              const isInternalAdmin = backToRole === RoleType.ApplicationAdministrator
                || backToRole === RoleType.CustomerServiceAdministrator
                || backToRole === RoleType.CustomerServiceElevatedAdministrator;

              if (isInternalAdmin) {
                return this.routeCxAdminBack(backToRole);
              }

              return this.routeDigitalAdminsBack(backToRole);
            }

            // Error handling if restoreLogin call didn't complete correctly
            // Display an error, but logout so state isn't borked.
            path.push('/login');
            this.toastr.error(res.messages[0]);
            this.userService.isMasqueraded = false;
            this.authService.logout();
            return from(this.router.navigate(path));
          })
        )
        .subscribe(() => {
          this.coreFacade.dispatch(resetStore());
        });
    }
  }

  // A Masquerading User has the ability to click a "logout" button (different than the 'Stop Masquerading' buttons)
  // The logout button is tied to the user under masquerade, but also needs to stop the masquerade session.
  // Because of the ability to click the 'logout' button, this masquerade call has to be kept here.
  // TODO: ZBP-12590 will handle gathering business requirements and organizing the code based on requirements.
  restoreLogin(): Observable<ApiResponse<UserStatusResponse>> {
    return this.http.request<ApiResponse<UserStatusResponse>>('delete', this.appConfig.getAuthUrl('user/masquerade'))
      .pipe(
        map(res => new ApiResponse<UserStatusResponse>(true, res)),
        map((res) => {
          // Clear the masquerading data, but do not clear the feature flags
          this.userService.isLoggingOut = true;
          this.userService.clearUserState(false, true);
          this.appConfig.clearCustomData();
          this.userService.isLoggingOut = false;

          this.cacheService.coreApiToken = res.response.token;

          return res;
        }),
      );
  }

  /**
   * Routes CX Admin back to the home page after exiting masquerade.
   * @param {RoleType} backToRole - The role type of the user to navigate.
   */
  private routeCxAdminBack(backToRole: RoleType) {
    const homePageByRole = this.pathByRole[backToRole] ?? '';
    return from(this.router.navigate([homePageByRole]));
  }

  /**
   * Routes Digital Admins back to their User Management page after exiting masquerade.
   * Uses the masqueradeOriginUrl which was set at the start of masquerading and saved in local storage.
   * Configures the routes and navigates the user to the necessary routes.
   * @param {RoleType} backToRole - The role type of the user to navigate.
   */
  private routeDigitalAdminsBack(backToRole: RoleType) {
    let backToAdminRouteComponents: string[] = [];
    let hasGuidForRoute: RegExpMatchArray;
    const [masqueradeOriginUrl, originQueryParamString] = this.masqueradeOriginUrl
      ? this.masqueradeOriginUrl.split('?')
      : [null, null];

    const queryParamsForAdminRoute = this.parseQueryParams(originQueryParamString);

    if (backToRole === RoleType.DistrictAdministrator) {
      const districtRouteData = this.configureDistrictRoute(masqueradeOriginUrl, backToAdminRouteComponents);
      hasGuidForRoute = districtRouteData?.match;

      if (hasGuidForRoute) {
        backToAdminRouteComponents = districtRouteData.goBackPath;
      }
    } else if (backToRole === RoleType.SchoolAdministrator) {
      const schoolRouteData = this.configureSchoolRoute(masqueradeOriginUrl, backToAdminRouteComponents);
      hasGuidForRoute = schoolRouteData.match;

      if (hasGuidForRoute) {
        backToAdminRouteComponents = schoolRouteData.goBackPath;
      }
    }

    // If custom route cannot be configured with a GUID, then use the default landing page by role.
    if (!hasGuidForRoute) {
      return this.navigateBackToDefaultPage(backToRole);
    }

    return this.navigateBackToManageUsers(backToRole, backToAdminRouteComponents, queryParamsForAdminRoute);
  }

  /**
   * Navigates back to the default page if a custom route cannot be configured.
   * @param {RoleType} backToRole - The role type of the user to navigate.
   */
  private navigateBackToDefaultPage(backToRole: RoleType) {
    const pathRouteByRole = this.pathByRole[backToRole] ?? '';

    return from(this.router.navigate([pathRouteByRole]));
  }

  /**
   * Handles the actual navigation back to the User Management page after exiting masquerade.
   * School and District Admins are routed back to the page where masqueraded initiated.
   * User cannot directly navigate to the User Management page with the current state of the guards and page,
   * We must first go to their overview page to get all the data.
   * Once the Admin overview page loads the data, the user is redirected to the User Management page.
   * @param {RoleType} backToRole - The role type of the user to navigate.
   * @param {RoleType} goBackRouteArray - Array of route segments for navigation.
   * @param {RoleType} queryParamsToNavWith - Query parameters to use during navigation.
   */
  private navigateBackToManageUsers(backToRole: RoleType, goBackRouteArray: string[], queryParamsToNavWith: {}) {
    const digitalAdminModuleRoute = backToRole === RoleType.DistrictAdministrator ? '/districts' : '/schools';

    return from(this.router.navigate([digitalAdminModuleRoute]).then(() => {
      this.navigationSubscription = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        if (event.url === `${digitalAdminModuleRoute}/${goBackRouteArray[1]}/overview`) {
          // Module overview has loaded with data, now go to the users page
          this.router.navigate(goBackRouteArray, { queryParams: queryParamsToNavWith });

          // Unsubscribe after navigating to avoid memory leaks
          this.navigationSubscription.unsubscribe();
        }
      });
    }));
  }

  /**
   * Configures the route for District Administrators.
   * Used when District Admins stop masquerading.
   * @param {string} masqueradeOriginUrl - The original URL before masquerading.
   * @param {string[]} goBackPath - Array to hold the route segments.
   * @returns An object with the regex match if it exists and an Array of route segments for District module navigation.
   */
  private configureDistrictRoute(masqueradeOriginUrl: string, goBackPath: string[]) {
    // "/districts/{{GUID}}"
    const regex = /\/districts\/([0-9a-fA-F-]{36})/;


    if (masqueradeOriginUrl) {
      const match = masqueradeOriginUrl.match(regex);
      if (match) {
        goBackPath.push('districts');
        goBackPath.push(match[1]);
        goBackPath.push('users');
      }

      return { match, goBackPath };
    }

    return null;
  }

  /**
   * Configures the route for School Administrators.
   * Used when School Admins stop masquerading.
   * @param {string} masqueradeOriginUrl - The original URL before masquerading.
   * @param {string[]} goBackPath - Array to hold the route segments.
   * @returns An object with the regex match if it exists and an Array of route segments for School module navigation.
   */
  private configureSchoolRoute(masqueradeOriginUrl: string, goBackPath: string[]) {
    // "/schools/{{GUID}}"
    const regex = /\/schools\/([0-9a-fA-F-]{36})/;

    if (masqueradeOriginUrl) {
      const match = masqueradeOriginUrl.match(regex);
      if (match) {
        goBackPath.push('schools');
        goBackPath.push(match[1]);
        goBackPath.push('users');
      }

      return { match, goBackPath };
    }
    return null;
  }

  /**
   * Parses the query parameters from a query string.
   * @param {string} queryString - The query string to parse.
   * @returns {string} An object representing the parsed query parameters.
   */
  private parseQueryParams(queryString: string): any {
    if (queryString) {
      return queryString
        .split('&')
        .map(param => param.split('='))
        .reduce((params, [key, value]) => {
        // eslint-disable-next-line no-param-reassign
          params[key] = value;
          return params;
        }, {});
    }

    return null;
  }

  createTitleTextForRoleButtons(roleToCheck: RoleType) {
    if (this.userService.isMasqueraded) {
      const userRolesByDomain = this.userService.user.userMasqueradeCompanyCodes;

      // If not an Internal Admin, Teacher role can only be masqueraded
      if (!this.isRoleAuthorized(roleToCheck)) {
        return MasqueradeTextHelpers.getUnableToMasqueradeText(this.appConfig.loginBrand, true);
      }

      return this.roleCanBeMasqueraded(roleToCheck, userRolesByDomain)
        ? ''
        : MasqueradeTextHelpers.getUnableToMasqueradeText(this.appConfig.loginBrand);
    }

    return '';
  }
}
