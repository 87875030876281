import { AnnouncementStatus } from '@shared/enums/announcement-status';
import { RoleType } from '@shared/enums/role-type';

import { IProductVariant } from './product-variant';

// Used for getting and saving one announcement because variants property is different than getting multiple
// announcements.
export interface IAnnouncementRequest {
  announcementId: string;
  productLineId: string;
  title: string;
  message: string;
  variants: IProductVariant[];
  userRoles: RoleType[];
}

export interface IAnnouncement {
  announcementId: string;
  productLineId?: string;
  productLine?: string;
  productDescription?: string;
  title: string;
  message: string;
  variants?: IProductVariant[];
  userRoles?: RoleType[];
  status?: AnnouncementStatus;
}

export class Announcement implements IAnnouncement {
  announcementId: string = null;
  productLineId?: string = null;
  productLine?: string = null;
  productDescription?: string = null;
  title: string = null;
  message: string = null;
  variants?: IProductVariant[] = [];
  userRoles?: RoleType[] = [];
  status?: AnnouncementStatus = null;

  constructor(values: Partial<IAnnouncement>) {
    Object.keys(values).forEach((key) => {
      this[key] = values[key];
    });
  }
}
