import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { ApiResponse } from '@models/api-response';
import { IProductComponent } from '@models/product-component';
import { IProductLine } from '@models/product-line';
import { GradeType } from '@shared/enums/grade-type';
import { LaunchType } from '@shared/enums/launch-type';
import { ProductType } from '@shared/enums/product-type';
import { BehaviorSubject, Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EceProductService {
  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
  ) {}

  productLine$: BehaviorSubject<IProductLine> = new BehaviorSubject<IProductLine>(null);
  themeId: string = null;

  set productLine(productLine: IProductLine) {
    if (productLine !== this.productLine$.value) {
      this.productLine$.next(productLine);
    }
  }

  isMaterialComponent(productComponent: IProductComponent): boolean {
    return [
      LaunchType.DailyRoutine,
      LaunchType.SuperKidsOnlineFun,
      LaunchType.SuperkidsOwnWebPages,
      LaunchType.SuperSmart,
      LaunchType.Viewer,
      LaunchType.WhiteboardActivity,
      LaunchType.NumberPractice,
    ].includes(productComponent.launchType);
  }

  getMaterial(queryString: string) {
    const url: string = `${this.getMaterialUrl()}?${queryString}`;

    return this.http.get<ApiResponse<any>>(url)
      .pipe(
        map(res => new ApiResponse<any>(true, { response: true, messages: [...res.response] })),
      );
  }

  private getMaterialUrl(): string {
    return `${this.appConfig.apiUrl}/digital-resources/material`;
  }

  pinMaterial(productType: ProductType, gradeType: GradeType | string, componentId: string): Observable<ApiResponse<boolean>> {
    const data = { productType, gradeType, componentId };
    return this.http.patch<ApiResponse<any>>(this.getMaterialUrl(), data)
      .pipe(
        map(res => new ApiResponse<boolean>(true, { response: true, messages: [...res.messages] })),
      );
  }

  unPinMaterial(productType: ProductType, gradeType: GradeType | string, componentId: string): Observable<ApiResponse<boolean>> {
    const body = { productType, gradeType, componentId };
    return this.http.request<ApiResponse<any>>('delete', this.getMaterialUrl(), { body })
      .pipe(
        map(res => new ApiResponse<boolean>(true, { response: false, messages: [...res.messages] })),
      );
  }
}
