import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { BrowserService } from '@core/browser.service';

@Component({
  selector: 'zbp-invalid-token',
  templateUrl: './invalid-token.component.html',
  styleUrls: ['../login.component.scss', './invalid-token.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvalidTokenComponent {
  constructor(public browserService: BrowserService, private router: Router) { }

  goToForgotPassword(): void {
    this.router.navigateByUrl('/login/forgot-password');
  }
}
