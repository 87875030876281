<button
  id="{{ audio?.audioId }}Button"
  type="button"
  class="btn btn-xs mb-1 buttonIconCircleTeal"
  tabindex="0"
  [class.buttonIconHidden]="!isLoaded"
  [attr.aria-label]="audio?.label"
  [attr.aria-controls]="audio?.audioId"
  [disabled]="!isLoaded"
  (click)="playOrStop()">
  <i class="fa fa-volume-up" [class.faVolumeAnimated]="isPlaying" aria-hidden="true"></i>
</button>
<audio #audioEl id="{{ audio?.audioId }}" [src]="audio?.fileName">
  <caption class="sr-only">{{ audio?.caption }}</caption>
</audio>
