import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';

@Directive({
  selector: '[zbpZbSwirl]',
})
export class ZbSwirlDirective implements OnDestroy, OnInit {
  private element: ElementRef<HTMLElement>;
  private asset = 'assets/img/zb_greyswirl.svg';
  private _lastHeight: number = 0;
  private _checker: Subscription;

  @Input() zbSwirlPosition = 'left 0';
  @Input() zbSwirlColor = '#4596ab';

  constructor(el: ElementRef<HTMLElement>, private http: HttpClient, private changeDetector: ChangeDetectorRef) {
    this.element = el;
  }

  ngOnInit() {
    if (this.element) {
      this.loadSwirl()
        .subscribe(() => {
          this.setBackgroundPosition();
        });

      // We need to continually check the scrollHeight of the parent element as content that "pops in" such as images,
      // which are outside of the angular lifecycle hooks, can change the height of the page. This also solves not
      // having to listen to click events, which also may be affected by arbitrary animation/transition times.
      this._checker = interval(100).pipe(
        takeWhile(() => this.element !== null),
      ).subscribe(() => {
        this.setBackgroundPosition();
      });
    }
  }

  ngOnDestroy() {
    this.element = null;
    if (this._checker) {
      this._checker.unsubscribe();
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.setBackgroundPosition();
  }

  get checker(): Subscription {
    return this._checker;
  }

  get lastHeight(): number {
    return this._lastHeight;
  }

  set lastHeight(value: number) {
    this._lastHeight = value;
  }

  private setBackgroundPosition() {
    // Prevent this from being called excessively because we're in an interval loop.
    if (this.element && !this.checker.closed && this.lastHeight !== this.element.nativeElement.scrollHeight) {
      // Swirl SVG is 792px tall and we roughly want the position a little before halfway.
      const swirlTopPosition = this.element.nativeElement.scrollHeight - (792 / 2);
      this.element.nativeElement.style.backgroundPosition = `${this.zbSwirlPosition} top ${swirlTopPosition}px`;
      this.lastHeight = this.element.nativeElement.scrollHeight;
      this.changeDetector.detectChanges();
    }
  }

  private loadSwirl(): Observable<boolean> {
    const pattern = /#DCDCDC/gi;
    const headers = new HttpHeaders({ 'Cache-Control': 'public' });
    return this.http.get(this.asset, { headers, responseType: 'text' })
      .pipe(
        // Do a quick regular expression replace on the color pattern. Anything more complex will require
        // loading the SVG as an object, and doing manipulation that way.
        map((data: string) => window.btoa(data.replace(pattern, this.zbSwirlColor))),
        map((encoded: string) => {
          this.element.nativeElement.style.backgroundImage = `url(data:image/svg+xml;base64,${encoded})`;
          return true;
        }),
      );
  }
}
