import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import { BrandedLoginComponent } from '../../branded-login/branded-login.component';
import { ChooseLoginComponent } from '../choose-login.component';
import { RedesignedChooseLoginComponent } from '../redesigned-choose-login/redesigned-choose-login.component';

@Component({
  selector: 'zbp-choose-login-redesign-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class ChooseLoginRedesignHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedLoginComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: ChooseLoginComponent,
    },
    ZbRedesign: {
      component: RedesignedChooseLoginComponent,
    }
  };
}
