<div class="d-flex flex-column justify-content-between h-100 siteDownPage">
  <img id="highlightsLogo" src="assets/img/highlights-logo.svg" alt="Highlights Portal">
  <section class="d-flex flex-column h-100 siteDownBody">
    <zbp-icon [iconName]="zbpIconNames.wrenches" iconSize="default"></zbp-icon>
    <header class="text-center">
      <div class="siteDownTitle" [innerHTML]="this.title"></div>
    </header>
    <article class="text-center siteDownMessage" [innerHTML]="this.message"></article>
  </section>
  <zbp-footer></zbp-footer>
</div>
