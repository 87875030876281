export enum IntegrationProfileType {
  Clever = 'Clever',
  LegacyApp = 'LegacyApp',
  OneRoster = 'OneRoster',
  LTI = 'LTI',
  LTIAdvantage = 'LTIAdvantage'
}

export enum IntegrationType {
  'OneRosterCSV' = 'OneRoster CSV',
}
