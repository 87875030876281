import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  get bowser(): Bowser.Parser.Parser {
    return Bowser.getParser(window.navigator.userAgent, false);
  }

  get isSafari(): Boolean {
    return this.bowser.isBrowser('safari', true);
  }

  get unsupported(): Boolean {
    return this.bowser.satisfies({ msie: '11', chrome: '80', firefox: '78', safari: '12' });
  }

  get isMac() {
    return this.bowser.is('macOS');
  }

  get isIEorEdge() {
    return this.bowser.isBrowser('edge', true) || this.bowser.isBrowser('msie', true);
  }

  get isChrome() {
    return this.bowser.is('chrome');
  }

  get isNotChromeBased() {
    return this.bowser.is('firefox') || this.isIE || this.isSafari;
  }

  get isIE() {
    return this.bowser.isBrowser('msie', true) && !this.bowser.isBrowser('edge', true);
  }

  get isLegacyIOSDevice() {
    return this.bowser.is('iOS');
  }

  get isIOSDevice() {
    // Apple decided that iOS 13 and above should be more difficult to detect because.
    return this.bowser.is('iOS')
      || (this.isMac && navigator.maxTouchPoints && navigator.maxTouchPoints > 1);
  }

  get checkSafariVersions() {
    const version: number = +this.bowser.getBrowserVersion();
    return version < 11 && version >= 7;
  }
  get isAndroid() {
    return this.bowser.is('Android');
  }
}
