import { Component } from '@angular/core';
import { OauthResponse } from '@models/oauth-response';
import { OAuth2Provider } from '@shared/enums/oauth2-provider.enum';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { OAuth2Service } from '../../oauth2/oauth2.service';

export type SSOProvider = {
  oauth2Provider: OAuth2Provider,
  displayName: string,
  idSuffix: string,
  imgPath: string,
};

@Component({
  selector: 'zbp-sso-form',
  templateUrl: './sso-form.component.html',
  styleUrl: './sso-form.component.scss'
})
export class SsoFormComponent {

  ssoProviders: Array<SSOProvider> = [
    {
      oauth2Provider: OAuth2Provider.google,
      displayName: 'Google',
      idSuffix: 'google',
      imgPath: '/assets/img/sso/google_logo.svg',
    },
    {
      oauth2Provider: OAuth2Provider.clever,
      displayName: 'Clever',
      idSuffix: 'clever',
      imgPath: '/assets/img/sso/clever_logo.svg',
    },
    {
      oauth2Provider: OAuth2Provider.classlink,
      displayName: 'Class Link',
      idSuffix: 'classlink',
      imgPath: '/assets/img/sso/class_link_logo.svg',
    },
  ];

  ssoProviderSelected: SSOProvider | null = null;

  constructor(
    private oauth2Service: OAuth2Service,
    private toastService: ToastrService,
  ) {
  }

  get isLoadingSsoRedirect(): boolean {
    return Boolean(this.ssoProviderSelected);
  }

  onSsoButtonClick(provider: SSOProvider) {
    this.ssoProviderSelected = provider;
    this.oauth2Service.getSsoUrl(provider.oauth2Provider)
      .pipe(finalize(() => {
        this.ssoProviderSelected = null;
      }))
      .subscribe({
        next: res => this.redirectToSsoUrl(res, provider),
        error: (err) => {
          console.error(err);
          this.showSsoFailureToast(provider);
        },
      });
  }

  redirectToSsoUrl(res: OauthResponse, provider: SSOProvider): void {
    if (res && res.url) {
      window.location.href = res.url;
    } else {
      this.showSsoFailureToast(provider);
    }
  }

  showSsoFailureToast(provider: SSOProvider): void {
    this.toastService.error(`Unable to connect to ${provider.displayName} SSO.`);
  }

}
