import { RosterImportError } from '@shared/enums/roster-import-error.enum';
import { RosterImportSeverity } from '@shared/enums/roster-import-severity.enum';
import { RosterImportSheet } from '@shared/enums/roster-import-sheet.enum';

export class RosterImportAlert {
  /**
   * Identifies the type of error, not a unique identifier.
   */
  errorType: RosterImportError = undefined;
  /**
   * The severity of the error.
   */
  severity: RosterImportSeverity = undefined;
  /**
   * Either the spreadsheet or a lower-level data type.
   */
  impactedSheet: RosterImportSheet = undefined;
  /**
   * The impacted row in the sheet starting at 1.
   */
  row?: number = undefined;
  /**
   * The username, class name, organization name, etc...
   */
  impactedName?: string = undefined;
  /**
   * The unique identifier for the row depending on its roster import data type.
   */
  sourcedId?: string = undefined;
  /**
   * Describes the errorId above.
   */
  errorDescription: string = undefined;
  /**
   * Describes steps the user should take to fix the alert.
   */
  remediation: string = undefined;
}
