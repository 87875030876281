/**
 * Some users have no masquerade abilities.
 * Some users can only masquerade on a different domain.
 * Some users can be masquerade on current domain.
 */
export enum MasqueradeAbilities {
  None = 'None',
  DifferentDomain = 'DifferentDomain',
  CurrentDomain = 'CurrentDomain',
  CurrentDomainAndDifferent = 'CurrentDomainAndDifferent',
}
