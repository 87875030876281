import { Injectable } from '@angular/core';
import { Script } from '@models/script';
import { of, BehaviorSubject, Observable } from 'rxjs';


export interface HTMLIEScriptElement extends HTMLScriptElement {
  // Internet Explorer only properties.
  readonly readyState?: string;
  onreadystatechange?: any;
}

@Injectable()
export class WistiaService {
  private _el: HTMLIEScriptElement;
  private _script: Script = {
    name: 'wistia',
    charset: 'ISO-8859-1',
    src: '//fast.wistia.com/assets/external/E-v1.js',
    loaded: new BehaviorSubject<boolean>(false),
  };

  // For test mocking.
  set script(value: Script) {
    this._script = value;
  }

  unloadScript(): void {
    if (this._script.loaded.getValue()) {
      document.getElementsByTagName('head')[0].removeChild(this._el);
    }
  }

  /**
   * Loads a script dynamically.
   */
  loadScript(): Observable<boolean> {
    if (!this._script.loaded.getValue()) {
      const el: HTMLIEScriptElement = document.createElement('script');
      el.type = 'text/javascript';
      el.src = this._script.src;
      el.async = true;

      if (el.readyState) {
        // IE behaves differently.
        el.onreadystatechange = () => {
          if (el.readyState === 'loaded' || el.readyState === 'complete') {
            this._script.loaded.next(true);
          }
        };
      } else {
        el.onload = () => this._script.loaded.next(true);
      }
      el.onerror = (err: any) => {
        throw err;
      };

      document.getElementsByTagName('head')[0].appendChild(el);
      this._el = el;
      return this._script.loaded.asObservable();
    }
    return of(true);
  }
}
