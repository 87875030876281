import { NewClassroomPin } from '@models/new-classroom-pin';
import { ObjectHelperBaseObject } from '@models/object-helper-base';

export class UserPrefs extends ObjectHelperBaseObject {
  private _classroomPins: Map<string, NewClassroomPin> = new Map<string, NewClassroomPin>();

  set classroomPins(classroomPins: Object) {
    const map = new Map<string, NewClassroomPin>(Object.entries(classroomPins));
    this._classroomPins = map;
  }

  get classroomPins(): Map<string, NewClassroomPin> {
    return this._classroomPins;
  }
}
