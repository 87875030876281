<div [ngClass]="[
        'zbp-icon-component',
        iconSize,
        'hover-style-' + hoverStyle,
        disabled ? 'disabled-style': '',
        _iconName + '-icon',
     ]"
     [innerHTML]="svgIcon"
     [style.fill]="iconFillColor">
</div>

