
import { Classroom } from '@models/classroom';
import { IUser } from '@models/user';
import { createAction, props } from '@ngrx/store';

export enum PARENT_ACTIONS {
  initChildren = '[Parent] Init Children',
  loadChildren = '[Parent] Load Children',
  setChildrenLoaded = '[Parent] Set Children Loaded',
  setChildren = '[Parent] Set Children',
  loadClassroom = '[Parent] Load Classroom',
  setClassroom = '[Parent] Set Classroom',
  setMessages = '[Parent] Set Messages',
}

export const initChildren = createAction(PARENT_ACTIONS.initChildren);
export const loadChildren = createAction(PARENT_ACTIONS.loadChildren);
export const setChildrenLoaded = createAction(PARENT_ACTIONS.setChildrenLoaded, props<{ loaded: boolean }>());
export const setChildren = createAction(PARENT_ACTIONS.setChildren, props<{ children: IUser[] }>());
export const loadClassroom = createAction(PARENT_ACTIONS.loadClassroom, props<{ classroomId: string }>());
export const setClassroom = createAction(PARENT_ACTIONS.setClassroom, props<{ classroom: Classroom }>());
export const setMessages = createAction(PARENT_ACTIONS.setMessages, props<{ messages: string[] }>());
