export enum QuestType {
  GumActivity = 'GumActivity',
  SpellingActivity = 'SpellingActivity',
  HandwritingActivity = 'HandwritingActivity',
  Assessment = 'Assessment',
  AvatarChangeSticker = 'AvatarChangeSticker',
  AvatarTease = 'AvatarTease',
  AggregateQuest = 'AggregateQuest',
}

export enum QuestClassMap {
  GumActivity = 'GumQuest',
  SpellingActivity = 'SpellingQuest',
  HandwritingActivity = 'HandwritingQuest',
  Assessment = 'AssessmentQuest',
  AvatarChangeSticker = 'AvatarQuest',
  AvatarTease = 'AvatarQuest',
  AggregateQuest = 'AggregateQuest',
}

export enum QuestStatus {
  Active = 'Active',
  Closed = 'Closed',
}

export enum QuestCompletionStatus {
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
}

export enum QuestAssignedByType {
  Teacher = 1,
  AssessmentRemediation,
  Random,
}

export enum HandwritingContentType {
  None = 'None',
  Basic = 'Basic',
  Cursive = 'Cursive',
  Manuscript = 'Manuscript',
}
