import { IntegrationProfileType } from '@shared/enums/integration-profile-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';

export class EducationalUnitIntegrationProfileData {
  newVersionEnabled: boolean = null;
  preventAdminDeletion: boolean = null;
  fileProcessingEnabled: boolean = null;
}

export class EducationalUnitIntegrationProfile {
  abbreviatedSchoolId: string = undefined;
  name: string = undefined;
  educationalUnitId: string = undefined;
  integrationId: string = undefined;
  profileType: IntegrationProfileType = null;
  lastUpdatedOn: string = undefined;
  @ClassCast(EducationalUnitIntegrationProfileData)
    data: EducationalUnitIntegrationProfileData = undefined;
  deletedOn: string = undefined;
  educationalUnitType: 'District' | 'School' = undefined;
}
