
import { District } from '@models/district';
import { IProductLineConfiguration } from '@models/product-line';
import { School } from '@models/school';
import { ISearch } from '@models/search';
import { createReducer, on, Action } from '@ngrx/store';

import * as AdminActions from './admin.actions';

export const adminFeatureKey = 'admin';

export interface AdminState {
  openAlertsLength: number;
  productLines: IProductLineConfiguration[];
  alertsLoadInProgress: boolean;
  productLinesLoadInProgress: boolean;
  recentSearches: ISearch[];
  account: District | School;
  errors: string[];
}

export const initialState: AdminState = {
  openAlertsLength: null,
  productLines: null,
  alertsLoadInProgress: false,
  productLinesLoadInProgress: false,
  recentSearches: [],
  errors: [],
  account: null,
};

const adminReducer = createReducer(
  initialState,
  on(AdminActions.refreshOpenAlerts, state => state),
  on(AdminActions.resetAlertsLoading, state => ({ ...state, alertsLoadInProgress: true })),
  on(AdminActions.resetProductLinesLoading, state => ({ ...state, productLinesLoadInProgress: true })),
  on(AdminActions.setOpenAlerts, (state, { payload }) => ({
    ...state,
    alertsLoadInProgress: false,
    openAlertsLength: payload,
  })),
  on(AdminActions.setProductLines, (state, { payload }) => ({
    ...state,
    productLinesLoadInProgress: false,
    productLines: [...payload],
  })),
  on(AdminActions.setMessages, (state, { payload }) => (
    { ...state, alertsLoadInProgress: false, errors: [...payload] }
  )),
  on(AdminActions.setAccount, (state, { payload }) => ({ ...state, account: payload })),
  on(AdminActions.setSearch, (state, { search }) => {
    // Replaces search by name or adds search to searches.
    const exists = state.recentSearches.findIndex(s => s.name === search.name) !== -1;
    const recentSearches = exists
      ? state.recentSearches.map(s => (s.name === search.name ? search : s))
      : [...state.recentSearches, search];

    return { ...state, recentSearches };
  }),
);

export function reducer(state: AdminState | undefined, action: Action) {
  return adminReducer(state, action);
}
