import { hlFooterLinks, zbFooterLinks } from '@shared/enums/footer-links';

export const contactUsMenuId = 'contactUs';

export const hlContactUsMenu = {
  id: contactUsMenuId,
  isRouted: true,
  isExternal: true,
  route: hlFooterLinks.support,
  label: 'Help',
  weight: 10,
};

export const zbContactUsMenu = {
  id: contactUsMenuId,
  isRouted: true,
  isExternal: true,
  route: zbFooterLinks.support,
  label: 'Help',
  weight: 10,
};
