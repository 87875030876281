export const smileyFaceIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
    <g clip-path="url(#clip0_3595_20643)">
      <path d="M8.49121 16C12.6333 16 15.9912 12.6421 15.9912 8.5C15.9912 4.35786 12.6333 1 8.49121 1C4.34908 1 0.991211 4.35786 0.991211 8.5C0.991211 12.6421 4.34908 16 8.49121 16Z" stroke="#212121" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M5.49121 7.5C6.0435 7.5 6.49121 7.05228 6.49121 6.5C6.49121 5.94772 6.0435 5.5 5.49121 5.5C4.93893 5.5 4.49121 5.94772 4.49121 6.5C4.49121 7.05228 4.93893 7.5 5.49121 7.5Z" fill="#212121"/>
      <path d="M11.4912 7.5C12.0435 7.5 12.4912 7.05228 12.4912 6.5C12.4912 5.94772 12.0435 5.5 11.4912 5.5C10.9389 5.5 10.4912 5.94772 10.4912 6.5C10.4912 7.05228 10.9389 7.5 11.4912 7.5Z" fill="#212121"/>
      <path d="M8.49121 10C8.88904 10 9.27057 10.158 9.55187 10.4393C9.83318 10.7206 9.99121 11.1022 9.99121 11.5C9.99121 11.8978 9.83318 12.2794 9.55187 12.5607C9.27057 12.842 8.88904 13 8.49121 13" stroke="#212121" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_3595_20643">
        <rect width="16" height="16" fill="white" transform="translate(0.491211 0.5)"/>
      </clipPath>
    </defs>
  </svg>
`;

