import { ScoreBenchmark } from '@shared/enums/score-benchmark';
import { LegacySkofGameName } from '@shared/enums/skof-game-name';
import { SpellingLevelType } from '@shared/enums/spelling-level-type';
import { TopicActivityType, TopicType } from '@shared/enums/topic-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { copyObject } from '@shared/zb-object-helper/object-helper';
import moment, { Moment } from 'moment';
import { IHandwritingFreeWriteDetail, IHandwritingTraceScoreDetail } from './activity/handwriting';
import {
  ICompletionActivityScore,
  ILetterSearchActivityScore,
  IUnscrambleActivityScore,
  IWordActivityScore
} from './activity/spelling';

// Make this TopicActivityType if ZBPortal-Api returns consistent TopicActivityType.
export const scoreDetailActivityTypes: string[] = [
  'Completion',
  'Hangman',
  'LetterSearch',
  'Posttest',
  'Pretest',
  'Proofreading',
  'PracticeTest',
  'PatternActivity',
  'Unscramble',
  'WordSort',
  'WordSpellTimed',
  'WordSpellUntimed',
  // Spelling special snow flake.
  'PreTest',
  'PostTest',
];

export interface IUserActivityScore {
  correct: boolean;
  sentence: string;
  attempts: number;
}

export interface IUserActivityScoreCollection {
  fillInTheBlankResults?: IUserActivityScore[];
  highlightResults?: IUserActivityScore[];
  identifyResults?: IUserActivityScore[];
  rewriteResults?: IUserActivityScore[];
  sentenceManipulationResults?: IUserActivityScore[];
  multipleChoiceResults?: IUserActivityScore[];
  completionResult?: ICompletionActivityScore;
  patternMatchingResults?: IUserActivityScore[];
  letterResults?: ILetterSearchActivityScore[];
  wordAttemptResults?: IWordActivityScore[];
  wordAttemptCountResults?: IUnscrambleActivityScore[];
  wordSortResults?: IWordActivityScore[];
}

export interface AssessmentQuestionAnswerAttempt {
  attempt: string[];
}

export class AssessmentQuestionAnswer {
  // Common properties for Assessment Question Answers.
  correct: boolean = undefined;

  // Standard properties that should be common properties seen in GUM and assessment products.
  questionKey?: string = undefined;
  questionNumber?: number = undefined;

  // Non-standard properties seen in pseudo-Assessment Spelling pretest, posttest, practice tests saved as Assessments.
  submittedOn?: string = undefined;
  attempt?: string = undefined;
  answer?: string = undefined;
  prompt?: string = undefined;
  choices?: string[] = [];

  // Inconsistent property because.
  attempts?: AssessmentQuestionAnswerAttempt[] | string[] = [];
}

// Represents a consistent model for actual Assessment/Challenge scores seen in GUM and assessment products.
export class IAssessmentScoreDetail {
  activityType?: TopicType | TopicActivityType = undefined;
  assessmentKey: string = undefined;
  score: string = undefined;
  @ClassCast(AssessmentQuestionAnswer)
    submittedQuestionAttempts: AssessmentQuestionAnswer[] = [];
}

export class IAssessmentObjectiveManualPart {
  part: string = undefined;
  totalCorrect: string = undefined;
  deleteScore: boolean = undefined;
}

export class ISuperkidsLegacyAssessmentPart {
  assessmentKey: string = undefined;
  objective?: string = undefined;
  part: string = undefined;
  description?: string = undefined;
  cutoffMetAt: number = undefined;
  cutoffExceededAt: number = undefined;
  score: string = undefined;
  benchmark: ScoreBenchmark = undefined;
  @ClassCast(AssessmentQuestionAnswer)
    submittedQuestionAttempts?: AssessmentQuestionAnswer[] = [];
  @ClassCast(AssessmentQuestionAnswer)
    questions?: AssessmentQuestionAnswer[] = [];
  scoreIsOverridden: boolean = undefined;
  isManualSection: boolean = undefined;
}

export class ISuperkidsLegacyAssessmentPartProgressDetail extends ISuperkidsLegacyAssessmentPart {
  isInProgress: boolean = undefined;
  isManualSection: boolean = undefined;
  totalQuestions: number = undefined;
  totalCorrect: string = undefined;
}

export class ISuperkidsLegacyAssessmentPartManualScore {
  activityId: string = undefined;
  assessmentKey: string = undefined;
  objective: string = undefined;
  description: string = undefined;
  cutoffMetAt: number = undefined;
  cutoffExceededAt: number = undefined;
  benchmark: ScoreBenchmark = undefined;
  score: string = undefined;
  totalCorrect: number = undefined;
  totalQuestions: number = undefined;
  @ClassCast(ISuperkidsLegacyAssessmentPartProgressDetail)
    parts: ISuperkidsLegacyAssessmentPartProgressDetail[] = [];
}

// Represents a consistent model for Superkids Legacy Assessment scores.
export class ISuperkidsLegacyAssessmentScoreDetail {
  score: string = undefined;
  benchmark: ScoreBenchmark = undefined;
  @ClassCast(ISuperkidsLegacyAssessmentPart)
    parts: ISuperkidsLegacyAssessmentPart[] = [];
  activityType?: any = undefined;
  productName?: string = undefined;
  studentName?: string = undefined;
  questName?: string = undefined;
  assessmentKey?: string = undefined;
  activityId?: string = undefined;
  private _completedOn?: Moment;

  set completedOn(completedOn: string | moment.Moment) {
    if (completedOn && !moment.isMoment(completedOn)) {
      this._completedOn = moment(completedOn);
    }
  }

  get completedOn() { return this._completedOn; }
}

/*
 * Multiple choice, completion, completion2.
 */
export class ISkofLegacyMultipleChoiceScore {
  correctAnswers: string[] = [];
  answerAttempt: string = undefined;
  promptText: string = undefined;
  promptTranscript: string = undefined;
}

export class ISkofLegacySpellingScore {
  answer: string = undefined;
  attempt: string = undefined;
}

export class ISkofLegacyWordSortAttempt {
  correctAnswer: string = undefined;
  answerAttempt: string = undefined;
}

export class ISkofLegacyWordSortScore {
  answerAttempts: ISkofLegacyWordSortAttempt[] = [];
  bucketId: string = undefined;
  bucketLabel: string = undefined;
}

export class ISkofLegacySentenceManipulationAttempt {
  correct: boolean = undefined;
  wordType: string = undefined;
  attempt: string = undefined;
}

export class ISkofLegacySentenceManipulationScore {
  completedSentence: string = undefined;
  @ClassCast(ISkofLegacySentenceManipulationAttempt)
    answerAttempts: ISkofLegacySentenceManipulationAttempt[] = [];
}

export class ISkofLegacyGameScoreDetail {
  private _scores: (
    ISkofLegacySpellingScore
    | ISkofLegacyMultipleChoiceScore
    | ISkofLegacyWordSortScore
    | ISkofLegacySentenceManipulationScore)[] = [];
  activityType?: any = undefined;
  totalQuestions: number = undefined;
  totalCorrect: number = undefined;
  gameName: LegacySkofGameName = undefined;
  gameType: string = undefined;
  gameDescription: string = undefined;
  skill: string = undefined;

  set scores(scores: Object[]) {
    const castScores: (ISkofLegacySpellingScore
      | ISkofLegacyMultipleChoiceScore
      | ISkofLegacyWordSortScore
      | ISkofLegacySentenceManipulationScore)[] = [];
    scores.forEach((score) => {
      if (Object.prototype.hasOwnProperty.call(score, 'answer')) {
        castScores.push(copyObject(score, ISkofLegacySpellingScore));
      }
      if (Object.prototype.hasOwnProperty.call(score, 'correctAnswers')) {
        castScores.push(copyObject(score, ISkofLegacyMultipleChoiceScore));
      }
      if (Object.prototype.hasOwnProperty.call(score, 'bucketLabel')) {
        castScores.push(copyObject(score, ISkofLegacyWordSortScore));
      }
      if (Object.prototype.hasOwnProperty.call(score, 'completedSentence')) {
        castScores.push(copyObject(score, ISkofLegacySentenceManipulationScore));
      }
    });
    this._scores = castScores;
  }
}

// Represents the non-Assessment Spelling pretest, posttest, and practicetest scores that have odd property names
// compared to the standard assessment model.
export class ISpellingTestScoreDetail {
  // Note: ZBPortal-Api returns TopicType.PreTest instead of TopicActivityTYpe.Pretest so this must be a combined
  // type on the front end.
  activityType: TopicType | TopicActivityType = undefined;
  wordListLevel: SpellingLevelType = undefined;
  @ClassCast(AssessmentQuestionAnswer)
    attempts: AssessmentQuestionAnswer[] = [];
}

export class IQuestScoreDetail {
  activityType: TopicActivityType = undefined;
  totalQuestions: number = undefined;
  totalCorrect: number = undefined;
  gameResult?: IUserActivityScoreCollection = undefined;
  wordListLevel?: SpellingLevelType = undefined;
}

export type ScoreDetail = IHandwritingTraceScoreDetail
  | IHandwritingFreeWriteDetail
  | IAssessmentScoreDetail
  | ISuperkidsLegacyAssessmentScoreDetail
  | ISkofLegacyGameScoreDetail
  | ISpellingTestScoreDetail
  | IQuestScoreDetail;
