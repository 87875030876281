import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControlStatus, FormGroup, ValidationErrors } from '@angular/forms';
import { AppConfigService } from '@core/appconfig.service';
import { BrowserService } from '@core/browser.service';
import { CompanyCode } from '@shared/enums/company-code';
import {
  ApiPasswordErrorMessageMapping, PasswordRequirementsText, PasswordValidationLabels
} from '@shared/enums/password-requirements';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';
import { map, BehaviorSubject, Subscription, Observable } from 'rxjs';

interface PasswordRequirementFeedback {
  id: string
  requirement: PasswordRequirementsText;
  valid?: boolean;
}

/**
 * Used when a password field and confirm password field are needed with validation
 * Must supply the formGroup to it from the parent form.
 * The API response for setting a password will return a list of messages if validation fails from API.
 * API validates for common passwords and being different than previously used passwords.
 * UI validates for min length, unique characters, and password/confirm password equality.
 */
@Component({
  selector: 'zbp-password-requirements',
  templateUrl: './branded-password-requirements.component.html',
  styleUrls: ['./branded-password-requirements.component.scss'],
})
export class BrandedPasswordRequirementsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormGroup;
  @Input() apiResponseMessages: string[];
  @Input() centerRequirements: boolean = false;

  constructor(
    public browserService: BrowserService,
    private appConfigService: AppConfigService,
    private cdr: ChangeDetectorRef) { }

  formControlNames = {
    ConfirmPassword: 'confirmPassword',
    Password: 'password'
  };

  private subscriptions: Subscription[] = [];
  showPassword:boolean = false;
  showCurrentPassword:boolean = false;
  showConfirmPassword:boolean = false;
  titleText: string = 'Set a new password';
  formErrors: string[] = [];
  loginBrand: CompanyCode = null;
  isHighlights: boolean = false;
  containerClass: string = '';
  successfulSave: boolean = false;
  formSubmitted: boolean = false;
  newPasswordMeetsRequirements: boolean = false;
  passwordRequirementsList;
  iconNames = zbpIconNames;
  _passValidationSubscription: BehaviorSubject<PasswordRequirementFeedback[]>;

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private removeMatchPasswordRequirementFromList(): any {
    return Object.keys(PasswordRequirementsText)
      .filter(key => key !== PasswordValidationLabels.passwordsMustMatch)
      .reduce((acc, key) => {
        acc[key] = PasswordRequirementsText[key];
        return acc;
      }, {});
  }

  ngOnInit() {
    this.setupBrandData();

    // TODO: ZBP-13144 Will wire up the new password requirement text for passwordsMustMatch
    // Until then, it is filtered out from the feedback list
    this.passwordRequirementsList = this.removeMatchPasswordRequirementFromList();

    this._passValidationSubscription = new BehaviorSubject<PasswordRequirementFeedback[]>(
      Object.keys(this.passwordRequirementsList).map(req => (
        {
          id: req,
          requirement: PasswordRequirementsText[req],
          valid: null,
        }
      ))
    );

    this.subscriptions.push(
      this.handleNewPasswordFieldChanges$().subscribe(),
      this._passValidationSubscription.subscribe(),
    );
    this.form.markAsPristine();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apiResponseMessages?.currentValue) {
      this.updatePasswordValidationWithApiResponse(this.apiResponseMessages);
    }

    this.cdr.detectChanges();
  }

  /**
   * Updates Password Requirement Feedback List according to list of error messages
   * Used when API returns errors about password validation
   * @param {string[]} messages - list of messages to use to update Password requirement list
   */
  updatePasswordValidationWithApiResponse(messages: string[]): void {
    // Set all as valid, then update with invalid responses
    this._passValidationSubscription.next(Object.keys(PasswordRequirementsText).map(req => (
      {
        id: req,
        requirement: PasswordRequirementsText[req],
        valid: true,
      }
    )));

    if (messages) {
      messages.forEach((msg: string) => {
        if (msg.toLowerCase().includes(ApiPasswordErrorMessageMapping.commonPassword)) {
          this.updatePasswordRequirementFeedbackList(PasswordValidationLabels.commonPassword, false);
        } else if (msg.toLowerCase().includes(ApiPasswordErrorMessageMapping.newPasswordNotDifferent)) {
          this.updatePasswordRequirementFeedbackList(PasswordValidationLabels.newPasswordNotDifferent, false);
        }
      });
    }
  }

  /**
   * Observes changes in the Password field
   * Will update UI password feedback list based on field changes
   * @returns Observable<FormControlStatus> with current form control status for password field
   */
  handleNewPasswordFieldChanges$(): Observable<FormControlStatus> {
    return this.form.controls.password.statusChanges.pipe(
      map((values: FormControlStatus) => {
        const currentErrors = this.form.controls.password.errors;

        this.checkForUiPasswordErrors(PasswordValidationLabels.passwordLength, currentErrors);
        this.checkForUiPasswordErrors(PasswordValidationLabels.uniqueCharacters, currentErrors);

        if (!currentErrors) {
          this.newPasswordMeetsRequirements = true;
        }
        return values;
      })
    );
  }

  /**
   * UI can check for some errors before sending to API
   * The validity of the password requirements is displayed in the user's list of requirements
   * Will update the list of requirements with validity
   * @param {string} requirementName - Password requirement to check
   * @param {ValidationErrors} currentErrors - Current form errors
   */
  private checkForUiPasswordErrors(requirementName: string, currentErrors: ValidationErrors) {
    if (currentErrors && currentErrors[requirementName]) {
      this.updatePasswordRequirementFeedbackList(requirementName, false);
    } else {
      this.updatePasswordRequirementFeedbackList(requirementName, true);
    }
  }

  /**
   * Updates the list of password requirements that is shown to the user
   * @param {string} requirementName - requirement to update
   * @param {boolean} isValid - if requirement should be marked as valid
   */
  private updatePasswordRequirementFeedbackList(requirementName: string, isValid: boolean) {
    const currentPasswordFeedback = this._passValidationSubscription.getValue();
    const index = currentPasswordFeedback?.findIndex(obj => obj.id === requirementName);
    const requirement = index !== -1 ? currentPasswordFeedback[index] : {} as PasswordRequirementFeedback;

    if (index !== -1) {
      // Create a new array with the updated object
      const updatedArray = [
        ...currentPasswordFeedback.slice(0, index),
        {
          ...requirement,
          valid: isValid,
        },
        ...currentPasswordFeedback.slice(index + 1)
      ];

      // Emit the updated array
      this._passValidationSubscription.next(updatedArray);
    }
  }

  setupBrandData() {
    this.loginBrand = this.appConfigService.loginBrand;

    if (this.loginBrand === CompanyCode.HighlightsPortal) {
      this.isHighlights = true;
      this.containerClass = 'hl-portal-password-requirements-container';
    } else {
      this.isHighlights = false;
      this.containerClass = 'zb-portal-password-requirements-container';
    }
  }

  /**
   * Used to validate if a password form field has not been touched
   * If it has been touched, checks if field is valid
   * @returns {boolean} Whether form is valid
   */
  isPasswordControlValid(): boolean {
    const passwordControl = this.form.controls[this.formControlNames.Password];
    return passwordControl && (!passwordControl.touched || passwordControl.valid);
  }

  /**
   * Provides real-time feedback for the Confirm Password field
   * Allows the user knows when Confirm Password matches the New Password field
   * Show error when user updates New Password field and it doesn't match existing Confirm Password value
   * Do not show error when the Confirm Password field doesn't have a value
   * @returns {boolean} if field should be marked as valid
   */
  isConfirmPasswordValid() {
    const confirmPasswordFormControl = this.form.controls[this.formControlNames.ConfirmPassword];

    // Start validation as soon as the field is not pristine
    if (!confirmPasswordFormControl?.pristine) {
      confirmPasswordFormControl.updateValueAndValidity();
      return confirmPasswordFormControl.valid;
    }

    // return true while there is no value & field has not been interacted with yet
    return true;
  }

  /**
  * Toggles whether to show password or not.
  */
  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
