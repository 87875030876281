<div id="confirmationModalContainer">
  <div class="modal-header" zbpZbSwirl>
    <h4 class="modal-title" id="confirmationModalTitle">{{title}}</h4>
    <button type="button" class="btn close" aria-label="Close" (click)="dismiss()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="modal-body">
    <span id="confirmationModalMessage" [innerHtml]=message></span>
  </div>
  <div *ngIf="displayFooter" class="modal-footer">
    <button id="confirmButton" type="button" class="btn btn-outline-tertiary text-uppercase btn-sm" (click)="confirm()">{{confirmButtonText}}</button>
    <button *ngIf="displayCancelButton" id="cancelButton" type="button" class="btn btn-outline-tertiary text-uppercase btn-sm" (click)="dismiss()">Cancel</button>
    <button *ngIf="!displayCancelButton" class="invisible" aria-hidden="true"></button>
  </div>
</div>
