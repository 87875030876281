import { GradeType } from '@shared/enums/grade-type';
import { ProductType } from '@shared/enums/product-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { ISuperkidsLegacyAssessmentPartProgressDetail } from './score';

export class StudentAssessmentProgress {
  activityId: string = undefined;
  assessmentKey: string = undefined;
  description: string = undefined;
  isOverriddenByTeacher: boolean = undefined;
  isInProgress: boolean = undefined;
  @ClassCast(ISuperkidsLegacyAssessmentPartProgressDetail)
    skillGrid: ISuperkidsLegacyAssessmentPartProgressDetail[] = [];
  classroomId: string = undefined;
}

export class StudentProgressReport {
  userId: string = undefined;
  firstName: string = undefined;
  lastName: string = undefined;
  productType: ProductType = undefined;
  grade: GradeType = undefined;
  objectives: string[] = [];
  @ClassCast(StudentAssessmentProgress)
    assessments: StudentAssessmentProgress[] = [];
}
