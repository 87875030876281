<div class="search-container">
  <div class="text-search-input-container">
    <input
      #searchInput
      id="searchInput"
      type="text"
      class="input-text"
      [placeholder]="searchTermPlaceholderText"
      [attr.aria-label]="inputTextboxAriaLabel"
      [formControl]="searchFormControl">
    <button
      *ngIf="searchInput.value?.length > 0"
      class="btn buttonIcon reset-search-button"
      aria-label="Reset Search Criteria"
      type="button"
      tabindex="0"
      (click)="reset()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <button
      class="input-group-append search-button"
      [attr.aria-label]="inputTextboxAriaLabel"
      type="submit"
      tabindex="0">
      <i class="fa fa-search" aria-hidden="true"></i>
    </button>
  </div>
</div>