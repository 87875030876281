// Represents legacy asset taxonomy only found in data.json. Hence "JsonType".
export enum ViewerAssetJsonType {
  canvas = 'canvas',
  image = 'image',
  pdf = 'pdf',
  pdfLocked = 'pdf_locked',
  video = 'video',
  video_canvas = 'video_canvas',
}

// Represents asset taxonomy provided by ZBPortal-Api.
export enum ViewerAssetType {
  Audio = 'Audio',
  Canvas = 'Canvas',
  Download = 'Download',
  Dr = 'Dr',
  EBook = 'EBook',
  Ilb = 'Ilb',
  Image = 'Image',
  ImagePrint = 'ImagePrint',
  Iwb = 'Iwb',
  Pdf = 'Pdf',
  PdfLocked = 'PdfLocked',
  Set = 'Set',
  SuperSmart = 'SuperSmart',
  Video = 'Video',
  VideoCanvas = 'VideoCanvas',
}

export enum ViewerLanguage {
  English = 'English',
  Spanish = 'Spanish',

  // The following **should** only represent language codes used in front-end only content sheets for Handwriting
  // products. If this is/was used for anything else it is a mistake that should be fixed.
  en = 'en',
  es = 'es',
}

export enum ViewerEdition {
  Texas = 'tx',
  National = 'ntl',
  Any = 'any',
}
