import { GradeType } from '@shared/enums/grade-type';
import { boxFencePlugin } from '@shared/markdown-plugins/box-fence';
import { bracketPlugin } from '@shared/markdown-plugins/bracket';
import { underlinePlugin } from '@shared/markdown-plugins/underline';
import { Remarkable } from 'remarkable';

export class ClassroomQuestReport {
  questId: string = undefined;
  classroomId: string = undefined;
  private _description: string = undefined;
  private _descriptionAsMarkdown: string = undefined;
  productName: string = undefined;
  grade: GradeType = undefined;
  gradeDisplay: string = undefined;
  groupId: string = undefined;
  questKey: string = undefined;
  createdOn: string = undefined;
  completedOn?: string = undefined;
  expiresOn?: string = undefined;
  isSummary: boolean = undefined;
  userGroupId?: string = undefined;

  set description(description: string) {
    this._description = description;
    const md = new Remarkable('full', { breaks: true, typographer: true, quotes: '“”‘’' });
    // Adds Z-B markdown plugins.
    md.use(underlinePlugin);
    md.use(bracketPlugin);
    md.use(boxFencePlugin);
    this._descriptionAsMarkdown = md.renderInline(description);
  }

  get description() {
    return this._description;
  }

  get descriptionAsMarkdown() {
    return this._descriptionAsMarkdown;
  }
}
