import { DominantHandType } from '@shared/enums/dominant-hand-type';
import { GradeType } from '@shared/enums/grade-type';
import { SpellingLevelType } from '@shared/enums/spelling-level-type';
import { TopicType } from '@shared/enums/topic-type';

export const assignableTopicTypes: TopicType[] = [
  TopicType.Lesson,
  TopicType.Assessment,
  TopicType.Practice,
  TopicType.Game,
  TopicType.Writing,
  TopicType.PatternActivity,
  TopicType.Pretest,
  TopicType.Posttest,
  TopicType.PracticeTest,
  TopicType.WordSort,
  TopicType.ExtraPatternPractice,
  TopicType.FreeWrite,
  TopicType.CustomFreeWrite,
  TopicType.Trace,
];

export interface TopicProductMetadata {
  wordListLevel?: SpellingLevelType;
  dominantHand?: DominantHandType;
}

export interface Topic {
  topicKey: string;
  topicType: TopicType;
  isLocked?: boolean;
  isPinned?: boolean;
  lockedReason?: string;
  isAssignedByQuest?: boolean;
  starEarned?: boolean;
  topicWeight?: number;
  topicName?: string;
  grade?: string | number;
  unit?: number;
  unitWeight?: number;
  unitName?: string;
  productMetadata?: TopicProductMetadata;
  topicKeySuffix?: string;
}

export interface NavigationPagedItemResponse {
  unit: number;
  isNext: boolean;
  isLocked: boolean;
}

export interface TopicGroup {
  topicName?: string;
  unitName?: string;
  unit?: number;
  unitWeight?: number;
  semester?: string;
  grade?: GradeType;
  locale?: string;
  items?: Topic[];
  // Because...
  lessons?: Topic[];
  isLocked?: boolean;
  firstTopicKeySuffix?: string;
  pagedItems?: NavigationPagedItemResponse[];
}

export interface NavigationItemResponse {
  topicKey: string;
  topicType: TopicType;
  isLocked?: boolean;
  lockedReason?: string;
  isAssignedByQuest?: boolean;
  starEarned?: boolean;
  topicKeySuffix?: string;
  topicName?: string;
  unitName?: string;
  isPinned?: boolean;
  productMetadata?: TopicProductMetadata;
  grade?: string | number;
  unit?: number;
  unitWeight?: number;
}

export interface NavigationResponse {
  topicName?: string;
  locale?: string;
  unit?: number;
  items: NavigationItemResponse[];
}

export interface NavigationPagedResponse {
  navigationItems?: NavigationResponse[];
  pagedItems?: NavigationPagedItemResponse[];
}
