<div id="studentSettingsModalContainer" class="studentRoundedModal">
  <div class="modal-body">
    <h2 class="text-center mb-5">
      <i class="fa fa-cog circleIcon" aria-hidden="true"></i>
      <span class="sr-only">Settings</span>
    </h2>
    <div class="mb-3 text-center">
      <label for="enableStudentAudioPrompts" class="h3 d-block fw-normal mb-3">
        Portal navigation audio:
        <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_navaudio"></zbp-student-audio-button>
      </label>
      <div class="input-group justify-content-center">
        <div class="input-group-prepend mx-2 my-0 fw-bold text-uppercase">
          On
          <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_on"></zbp-student-audio-button>
        </div>
        <input
          id="enableStudentAudioPrompts"
          type="range"
          min="0"
          max="1"
          name="enableStudentAudioPrompts"
          class="form-control-range form-control--tertiary"
          [ngModel]="enableStudentAudioPrompts"
          (ngModelChange)="updateAudioSupport($event)">
        <div class="input-group-append mx-2 my-0 fw-bold text-uppercase">
          Off
          <zbp-student-audio-button *ngIf="hasAudioSupport" audioId="landing_off"></zbp-student-audio-button>
        </div>
      </div>
    </div>
  </div>
  <footer class="modal-footer flex-column justify-content-center align-items-start">
    <div *ngIf="errors.length > 0" class="alert alert-danger text-center mx-auto">
      <span *ngFor="let error in errors">{{ error }}</span>
    </div>
    <div *ngIf="showSuccess" class="alert alert-success text-center mx-auto">
      Successfully updated user settings!
    </div>
    <button id="studentSettingsFormButton" type="submit" class="btn text-uppercase btn-sm mx-auto fw-normal text-uppercase px-4" (click)="close()">Close</button>
  </footer>
</div>
