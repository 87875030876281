import { Component } from '@angular/core';
import { CompanyCode } from '@shared/enums/company-code';
import { ViewHandlerComponent } from '@shared/view-handler/view-handler.component';
import { BrandedSiteDownComponent } from './branded-site-down/branded-site-down.component';
import { SiteDownComponent } from './site-down.component';

@Component({
  selector: 'zbp-site-down-brand-handler',
  template: '<ng-container *ngComponentOutlet="componentToUse | async"></ng-container>',
})
export class SiteDownBrandHandlerComponent extends ViewHandlerComponent {
  componentMap = {
    [CompanyCode.HighlightsPortal]: {
      component: BrandedSiteDownComponent,
    },
    [CompanyCode.ZBPortal]: {
      component: SiteDownComponent,
    }
  };
}
