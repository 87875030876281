<ng-container *ngIf="!isError; else error">
  <div class="spinner">
    <img src="assets/img/Spinner-1s-200px.svg"
         class="spinner"
         [ngClass]="responsive ? 'responsive' : ''"
         [ngStyle]="{
        height: responsive ? '' : spinnerHeight,
        width: responsive ? '' : spinnerWidth
      }"/>
  </div>

</ng-container>
<ng-template #error>{{errorMessage}}</ng-template>
