<nav class="loginHeader fixed-top bg-primary" aria-hidden="true">&nbsp;</nav>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <div class="backToZanerBloserContainer align-self-start">
    <div id="backToZanerBloserLinkContainer" class="text-bold ms-4 mt-2 mvBoli">
      <span class="d-block loginText">back to</span>
      <a id="backToZanerBloserLink" class="d-block ps-1 btn-link" [attr.href]="backToZanerBloserLink">zaner-bloser.com</a>
    </div>
  </div>
  <div class="loginFormContainerWrapper text-center">
    <div class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 class="subLogo mvBoli">for teachers and school administrators</h1>
      <div *ngIf="error" id="error" class="alert alert-danger mx-auto" [innerHTML]="error"></div>
      <div *ngIf="success" id="success" class="alert alert-success mx-auto" [innerHTML]="success"></div>
      <form  id="passwordResetPanel" (ngSubmit)="resetPassword()" #PasswordReset="ngForm" class="loginForm text-center">
        <h4 class="text-danger">Password Reset</h4>
        <span class="text-danger">Enter the email address associated with your account to reset your password.</span>
        <div class="mb-3 mt-5 mb-5">
          <input placeholder="e-mail address" type="text" class="form-control" id="resetEmail" required [(ngModel)]="email" name="email" #emailV="ngModel" [class.is-invalid]="!(emailV.valid || emailV.touched)" />
        </div>
        <button id="resetSubmit" type="submit" class="btn btn-outline-tertiary text-uppercase btn-sm mx-2" [attr.aria-disabled]="!PasswordReset.form.valid" [disabled]="!PasswordReset.form.valid">Reset Password</button>
        <button id="resetCancel" type="button" class="btn btn-outline-tertiary text-uppercase btn-sm mx-2" (click)="cancelResetPasswordMode()">Cancel</button>
      </form>
    </div>
  </div>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>
