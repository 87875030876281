import { EducationalUnitType } from '@shared/enums/educational-unit-type';
import { ClassCast } from '@shared/zb-object-helper/class-cast';
import { EducationalUnitBase } from './educational-unit';
import { SchoolLicense } from './license/school-license';

export class School extends EducationalUnitBase {
  educationalUnitType: EducationalUnitType = EducationalUnitType.School;
  name: string;
  districtZbNum?: string = null;
  @ClassCast(SchoolLicense)
    licenses?: SchoolLicense[];
  digitalAdmins: string[];

  integrationId?: string;
  classroomId?: string;

  onInit(): void {
    this.hasRosteringIntegrations = this.districtId == null
      && !this.districtZbNum
      && this.getHasRosteringIntegrations();
  }

  get schoolId(): string {
    return this.educationalUnitId;
  }

  set schoolId(id: string) {
    this.educationalUnitId = id;
  }
}
