export const deleteAllIcon = `
  <svg version="1.2" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px" viewBox="0 0 27 27" overflow="visible" xml:space="preserve">
  <g>
    <rect x="2.6" y="5.7" width="4" height="3.9"/>
    <rect x="2.6" y="11.6" width="4" height="3.9"/>
    <rect x="2.6" y="17.4" width="4" height="3.9"/>
    <path d="M14.1,17.4H7.6v3.9h12v0C17.3,20.8,15.3,19.4,14.1,17.4z"/>
    <path d="M13.3,11.6H7.6v3.9h5.7c-0.2-0.6-0.2-1.3-0.2-1.9C13.1,12.8,13.2,12.2,13.3,11.6z"/>
    <path d="M19.6,5.7L19.6,5.7l-12,0v3.9h6.5C15.3,7.6,17.3,6.2,19.6,5.7z"/>
    <path d="M17.3,10.3c-0.2,0.2-0.2,0.6,0,0.9l2.6,2.6l-2.6,2.6c-0.2,0.2-0.2,0.6,0,0.9c0.2,0.2,0.6,0.2,0.9,0l2.6-2.6l2.6,2.6
      c0.2,0.2,0.6,0.2,0.9,0c0.2-0.2,0.2-0.6,0-0.9l-2.6-2.6l2.6-2.6c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0l-2.6,2.6l-2.6-2.6
      C17.9,10.1,17.5,10.1,17.3,10.3z"/>
  </g>
  </svg>
`;
