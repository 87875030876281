import { Quest } from '../quest';

export class AggregateQuest extends Quest {
  detailKey = 'aggregateQuestDetail';
  dependentQuestIds: string[];
  updatedOn: Date | string;

  constructor(data?: {[key: string]: any}) {
    super(data);
    if (data) {
      this.setAllowedProperties(data, this.detailKey);
    }
  }

  getAllowedPropertyNames(): string[] {
    return [
      'dependentQuestIds',
    ];
  }
}
