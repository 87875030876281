<div class="loginHeader fixed-top bg-primary">&nbsp;</div>
<div class="d-flex flex-wrap align-items-center justify-content-start loginContainer w-100" [ngClass]="{'loginContainerIE': browserService.isIE }">
  <section class="loginFormContainerWrapper text-center">
    <div class="loginFormContainer mx-auto">
      <img id="primaryLogo" src="assets/img/logo.svg" alt="My ZB Portal" class="mx-auto d-block loginImage">
      <h1 id="subTitle" class="subLogo mvBoli text-center">for teachers and school administrators</h1>

      <div class="my-3">
        <h2 class="h3 text-center text-danger mt-5 mb-4">Link Expired</h2>
        <p>It has been more than 72 hours so this link has expired.</p>
        <p>Please request a new link via email by clicking below.</p>
        <div class="mt-5">
          <button id="requestResetPasswordButton" type="button" class="btn btn-outline-tertiary text-uppercase" (click)="goToForgotPassword()">Request a new link</button>
        </div>
      </div>
    </div>
  </section>
  <div class="loginSwirlContainer mt-auto ms-auto align-self-end text-end">
    <img id="swirlImage" class="loginImage loginImageSwirl" src="assets/img/zb_greyswirl_right.png" role="presentation" alt="">
  </div>
</div>
<zbp-footer></zbp-footer>