<div class="d-flex flex-column justify-content-between h-100">
  <header class="mb-5">
    <nav class="col col-xs-12 col-sm-3 col-md-4 align-self-lg-start backToZanerBloserContainer">
      <div id="backToZanerBloserLinkContainer" class="text-bold ms-4 mt-2">
        <span class="d-block">back to</span>
        <a id="backToZanerBloserLink" class="d-block ps-1" [attr.href]="backToZanerBloserLink">zaner-bloser.com</a>
      </div>
    </nav>
  </header>
  <section *ngIf="!this.up" class="d-flex flex-column justify-content-start align-content-center h-75 w-50 mx-auto">
    <header class="text-center">
      <h1 class="text-uppercase" [innerHTML]="this.title"></h1>
    </header>
    <article id="siteDownMessage" class="alert alert-danger" [innerHTML]="this.message"></article>
  </section>
  <zbp-footer></zbp-footer>
</div>
