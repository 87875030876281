import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zbp-qr-code-help-modal',
  templateUrl: './qr-code-help-modal.component.html',
  styleUrls: ['./qr-code-help-modal.component.scss']
})
export class QrCodeHelpModalComponent {

  constructor(public activeModal: NgbActiveModal) { }

  dismiss(): void {
    this.activeModal.dismiss();
  }

}
