import { IntegrationProfileType } from '@shared/enums/integration-profile-type';
import { RosterImportStatus } from '@shared/enums/roster-import-status.enum';

/**
 * Represents the last run for a roster import.
 */
export class RosterImportLastRun {
  educationalUnitId: string = undefined;

  /**
   * The integration profile type of the educational unit.
   *
   * This is passed back from the back-ed, but originated from the front-end.
   */
  integrationProfileType: IntegrationProfileType = undefined;

  /**
   * Represents the status of the roster import.
   */
  rosterImportStatus: RosterImportStatus = undefined;

  /**
   * An unformatted ISO date string.
   */
  lastUpdatedOn: string = undefined;

  /**
   * An unformatted ISO date string.
   */
  startedOn: string = undefined;

  /**
   * The number of records processed so far.
   *
   * If null, then the process has started but no progres has been made.
   */
  appliedRecords?: number = undefined;

  /**
   * The total number of records to process.
   */
  totalRecords?: number = undefined;

  /**
   * The number of errors.
   *
   * @see RosterImportAlert
   */
  totalAlerts?: number = undefined;
}
