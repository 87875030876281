import { PrimaryMenu } from '@models/primary-menu';
import { hlContactUsMenu } from './contact-us-menu';

export const TEACHERS_PRODUCTS_MENU_ID = 'products';

export const teacherMainMenus: PrimaryMenu[] = [
  hlContactUsMenu,
  {
    id: TEACHERS_PRODUCTS_MENU_ID,
    label: 'My Products',
    isRouted: false,
    isExternal: false,
    weight: -10,
  },
];
