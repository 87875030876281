import { Component, OnInit } from '@angular/core';
import { AppConfigService } from '@core/appconfig.service';
import { CompanyCode } from '@shared/enums/company-code';

@Component({
  selector: 'zbp-back-to-website-link',
  templateUrl: './back-to-website-link.component.html',
  styleUrls: ['./back-to-website-link.component.scss'],
})
export class BackToWebsiteLinkComponent implements OnInit {
  constructor(private appConfigService: AppConfigService) {}

  isHighlights: boolean = null;
  linkUrl: string = '';
  linkText: string = '';

  ngOnInit(): void {
    this.isHighlights = this.appConfigService.loginBrand === CompanyCode.HighlightsPortal;

    this.setupBrandData();
  }

  private setupBrandData() {
    if (!this.isHighlights) {
      this.linkUrl = 'http://www.zaner-bloser.com';
      this.linkText = 'Return to Zaner-Bloser.com';
    }
  }
}
