import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AppConfigService } from '../appconfig.service';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class HideInProductionGuard  {
  constructor(
    private authService: AuthenticationService,
    private appConfig: AppConfigService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(!this.appConfig.isProduction);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
