/* eslint-disable no-param-reassign */
import { Remarkable, utils } from 'remarkable';
import { UNESCAPE_RE } from './helpers';

/**
 * Underline markdown plugin.
 *
 * This was ported from Javascript in the Vocab app.
 */
export const underlinePlugin: Remarkable.Plugin = (md: Remarkable, options?: any): void => {
  /**
   * Parses a given text for +(content)+.
   *
   * The state object is mutated.
   *
   * @returns {boolean} Returns true if there is something to renderer or false if not.
   */
  const parser = (state: Remarkable.StateInline, silent: boolean): boolean => {
    let found = false;
    const start: number = state.pos;
    const max: number = state.posMax;

    if (state.src.charCodeAt(start) !== 0x2B
        || silent
        || (start + 2 >= max)
        || (options && options.maxNesting && state.level >= options.maxNesting)) {
      // Only continue to parse if the first character matches "+", we're not in validation mode,
      // short syntax (from word wisdom code), or we've exceeded maximum nesting level.
      return false;
    }

    state.pos = start + 1;

    // Goes through each character to find a matching end character.
    while (state.pos < max) {
      if (state.src.charCodeAt(state.pos) === 0x2B) {
        found = true;
        break;
      }

      state.parser.skipToken(state);
    }

    if (!found || start + 1 === state.pos) {
      // Returns false and resets the position when there is no ending + character.
      state.pos = start;
      return false;
    }

    // Grabs the content between the two markers.
    const content = state.src.slice(start + 1, state.pos);

    // From Vocab app plugin.
    state.posMax = state.pos;
    state.pos = start + 1;

    if (!silent) {
      // Adds the content to replace and escape characters to be replaced later.
      state.push({
        type: 'underline',
        level: state.level,
        content: content.replace(UNESCAPE_RE, '$1'),
      });
    }

    // From Vocab app plugin.
    state.pos = state.posMax + 1;
    state.posMax = max;
    return true;
  };

  md.inline.ruler.push('underline', parser, options);
  md.renderer.rules.underline = (tokens: any[], index: number): string => {
    const content = utils.escapeHtml(tokens[index].content);
    return `<span class="underline">${content}</span>`;
  };
};
