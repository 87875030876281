/**
 * Adds a toJSON method for simple models that need to export from themselves.
 *
 * Originally from typescript-object-helper
 */
export abstract class ObjectHelperBaseObject {

  toJSON(): Object {
    const proto = Object.getPrototypeOf(this);
    const jsonObj: Object = {};
    const nonPrivateProperties = Object.entries(this).filter(prop => !prop[0].startsWith('_'));
    const descriptors = Object.entries(Object.getOwnPropertyDescriptors(proto));
    const allProperties = nonPrivateProperties.concat(descriptors);

    allProperties
      .filter(([, descriptor]) => typeof descriptor.get === 'function')
      .forEach(([key, descriptor]) => {
        if (descriptor && !key.startsWith('_')) {
          try {
            const val = (this as any)[key];
            if (typeof val === 'object' && val instanceof ObjectHelperBaseObject) {
              jsonObj[key] = val.toJSON();
            } else if (val instanceof Map) {
              jsonObj[key] = Object.fromEntries(val.entries());
            } else {
              jsonObj[key] = JSON.stringify(val);
            }
          } catch (error) {
            console.error(`Error calling getter ${key}`, error);
          }
        }
      });

    return jsonObj;
  }
}
