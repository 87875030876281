import { Component, Input } from '@angular/core';
import { zbpIconNames } from '@shared/zbp-icon/zbp-icon-names';

@Component({
  selector: 'zbp-role-button',
  templateUrl: './role-button.component.html',
  styleUrls: ['./role-button.component.scss'],
})
export class RoleButtonComponent {

  zbpIconNames = zbpIconNames;
  @Input() iconName: string;

  constructor(
  ) { }

  readonly roleLinkButtons = [
    {
      Label: 'Teachers & administrators',
      Icon: this.zbpIconNames.admin_teacher_role,
      Route: '/login/user',
      Id: 'teacher-admin',
    },
    {
      Label: 'Parents & guardians',
      Icon: this.zbpIconNames.parent_role,
      Route: '/login/parent',
      Id: 'parent',
    },
    {
      Label: 'Students',
      Icon: this.zbpIconNames.student_role,
      Route: '/login/student',
      Id: 'students',
    },
  ];
}
