<div class="alert-bar-container">
  <zbp-icon
    *ngIf="zbpIconName"
    [iconName]="zbpIconName"
    hoveStyle="disabled"
  >
  </zbp-icon>
  <div class="alert-bar-content">
    <ng-content></ng-content>
  </div>
</div>
