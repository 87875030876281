import { Injectable } from '@angular/core';
import { IAudioHelpFile } from '@models/audio-help-file';
import { fromEvent, of, race, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';


import { AppConfigService } from './appconfig.service';
import { BrowserService } from './browser.service';

/**
 * Provides an interface for loading and playing audio elements.
 */
export interface ISfxService {
  getAudioFileById(id: string): IAudioHelpFile|null;
  getAudioFileByKey(key: string): IAudioHelpFile|null;
  load(el: HTMLAudioElement): Observable<boolean>;
  stopCurrent(): void;
  stop(el: HTMLAudioElement): void;
  playAsCurrent(el: HTMLAudioElement): Observable<boolean>;
}

@Injectable()
export class SfxService implements ISfxService {
  private currentlyPlaying: HTMLAudioElement = null;
  private studentAudioFiles: IAudioHelpFile[] = [];

  /**
   * Store the content statically because everything in the app blocks on this service.
   *
   * Changing this to a http call is going to require refactoring the entire app's loading processes,
   * which is why the entire concept of student audio buttons is a bad user experiecne idea and should
   * really be redone/rethought as tutorial/tour functionality.
   */
  readonly studentAudioFileData: IAudioHelpFile[] = [
    {
      fileName: 'landing_logout.mp3',
      caption: 'Log out',
      label: 'Log out audio button',
    },
    {
      fileName: 'landing_settings.mp3',
      caption: 'Settings',
      label: 'Settings audio button',
    },
    {
      fileName: 'landing_nameID.mp3',
      caption: 'This is your name and your school ID.',
      label: 'User name audio help button',
    },
    {
      fileName: 'landing_zbportal.mp3',
      caption: `My ZB Portal--this is the website you're using right now!`,
      label: 'ZB Portal audio help button',
    },
    {
      fileName: 'landing_classes.mp3',
      caption: 'Classes',
      label: 'Classes audio button',
    },
    {
      fileName: 'landing_programs.mp3',
      caption: 'Programs',
      label: 'Programs audio button',
    },
    {
      fileName: 'landing_currentclass.mp3',
      caption: 'This is your class',
      label: 'Class name audio help button',
    },
    {
      fileName: 'landing_quests.mp3',
      caption: 'Welcome to My ZB Portal! You have quests assigned to you.',
      label: 'Quests audio help button',
    },
    {
      fileName: 'landing_noquests.mp3',
      caption: 'Welcome to My ZB Portal! You have no quests.',
      label: 'Quests audio help button',
    },
    {
      fileName: 'landing_questopen.mp3',
      caption: 'These are your Quests. Click on a quest to open it.',
      label: 'My Quests audio help button',
    },
    {
      fileName: 'landing_currentprogram.mp3',
      caption: `This is the program you're using right now.`,
      label: 'Program audio help button',
    },
    {
      fileName: 'landing_skof.mp3',
      caption: 'Superkids Online Fun',
      label: 'Superkids Online Fun audio button',
    },
    {
      fileName: 'landing_currentclass.mp3',
      caption: 'This is your class.',
      label: 'Class name audio help button',
    },
    {
      fileName: 'landing_help.mp3',
      caption: `Help.`,
      label: 'Help audio button',
    },
    {
      fileName: 'landing_avatar.mp3',
      caption: 'This is your avatar!',
      label: 'Avatar audio button',
    },
    {
      fileName: 'landing_on.mp3',
      caption: 'On',
      label: 'On audio button',
    },
    {
      fileName: 'landing_off.mp3',
      caption: 'Off',
      label: 'Off audio button',
    },
    {
      fileName: 'landing_navaudio.mp3',
      caption: 'If you want to turn navigation audio on or off, use the toggle.',
      label: 'Audio navigation audio help button',
    },
    {
      fileName: 'classes_choose.mp3',
      caption: `These are your classes. Pick the class you'd like to work in.`,
      label: 'Classes audio help button',
    },
    {
      fileName: 'program_choose.mp3',
      caption: `These are the programs available to you. Pick the program you'd like to use.`,
      label: 'Programs audio help button',
    },
    {
      fileName: 'program_skGK.mp3',
      caption: 'The Superkids Reading Program - Kindergarten',
      label: 'Program audio button',
      associatedKeys: ['SK2015_GK_C', 'SK2017_GK_C'],
    },
    {
      fileName: 'program_skG1.mp3',
      caption: 'The Superkids Reading Program - First Grade',
      label: 'Program audio button',
      associatedKeys: ['SK2015_G1_C', 'SK2017_G1_C'],
    },
    {
      fileName: 'program_skG2.mp3',
      caption: 'The Superkids Reading Program - Second Grade',
      label: 'Program audio button',
      associatedKeys: ['SK2015_G2_C', 'SK2017_G2_C'],
    },
    {
      fileName: 'program_fskGK.mp3',
      caption: 'The Superkids Foundational Skills Kit - Kindergarten',
      label: 'Program audio button',
      associatedKeys: ['FSK2021_GK_C'],
    },
    {
      fileName: 'program_fskG1.mp3',
      caption: 'The Superkids Foundational Skills Kit - First Grade',
      label: 'Program audio button',
      associatedKeys: ['FSK2021_G1_C'],
    },
    {
      fileName: 'program_fskG2.mp3',
      caption: 'The Superkids Foundational Skills Kit - Second Grade',
      label: 'Program audio button',
      associatedKeys: ['FSK2021_G2_C'],
    },
    {
      fileName: 'program_hwGK.mp3',
      caption: 'Zaner-Bloser Handwriting - Kindergarten',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_GK_C', 'HW2025N_GK_C'],
    },
    {
      fileName: 'program_hwG1.mp3',
      caption: 'Zaner-Bloser Handwriting - First Grade',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G1_C', 'HW2025N_G1_C'],
    },
    {
      fileName: 'program_hwG2M.mp3',
      caption: 'Zaner-Bloser Handwriting - Second Grade Manuscript',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G2M_C', 'HW2025N_G2M_C'],
    },
    {
      fileName: 'program_hwG2C.mp3',
      caption: 'Zaner-Bloser Handwriting - Second Grade Cursive',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G2C_C', 'HW2025N_G2C_C'],
    },
    {
      fileName: 'program_hwG3.mp3',
      caption: 'Zaner-Bloser Handwriting - Third Grade',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G3_C', 'HW2025N_G3_C'],
    },
    {
      fileName: 'program_hwG4.mp3',
      caption: 'Zaner-Bloser Handwriting - Fourth Grade',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G4_C', 'HW2025N_G4_C'],
    },
    {
      fileName: 'program_hwG5.mp3',
      caption: 'Zaner-Bloser Handwriting - Fifth Grade',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G5_C', 'HW2025N_G5_C'],
    },
    {
      fileName: 'program_hwG6.mp3',
      caption: 'Zaner-Bloser Handwriting - Sixth Grade',
      label: 'Program audio button',
      associatedKeys: ['HW2020N_G6_C', 'HW2025N_G6_C'],
    },
    {
      fileName: 'program_hwGKT.mp3',
      caption: 'Zaner-Bloser Handwriting - Kindergarten Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_GK_C'],
    },
    {
      fileName: 'program_hwG1T.mp3',
      caption: 'Zaner-Bloser Handwriting - First Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G1_C'],
    },
    {
      fileName: 'program_hwG2T.mp3',
      caption: 'Zaner-Bloser Handwriting - Second Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G2_C'],
    },
    {
      fileName: 'program_hwG3T.mp3',
      caption: 'Zaner-Bloser Handwriting - Third Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G3_C'],
    },
    {
      fileName: 'program_hwG4T.mp3',
      caption: 'Zaner-Bloser Handwriting - Fourth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G4_C'],
    },
    {
      fileName: 'program_hwG5T.mp3',
      caption: 'Zaner-Bloser Handwriting - Fifth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G5_C'],
    },
    {
      fileName: 'program_hwG6T.mp3',
      caption: 'Zaner-Bloser Handwriting - Sixth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['HW2020TX_G6_C'],
    },
    {
      fileName: 'program_hwesGK.mp3',
      caption: 'Zaner-Bloser La Escritura - Kindergarten',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_GK_C'],
    },
    {
      fileName: 'program_hwesG1.mp3',
      caption: 'Zaner-Bloser La Escritura - First Grade',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G1_C'],
    },
    {
      fileName: 'program_hwesG2M.mp3',
      caption: 'Zaner-Bloser La Escritura - Second Grade Manuscript',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G2M_C'],
    },
    {
      fileName: 'program_hwesG2C.mp3',
      caption: 'Zaner-Bloser La Escritura - Second Grade Cursive',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G2C_C'],
    },
    {
      fileName: 'program_hwesG3.mp3',
      caption: 'Zaner-Bloser La Escritura - Third Grade',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G3_C'],
    },
    {
      fileName: 'program_hwesG4.mp3',
      caption: 'Zaner-Bloser Handwriting - Fourth Grade',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G4_C'],
    },
    {
      fileName: 'program_hwesG5.mp3',
      caption: 'Zaner-Bloser La Escritura - Fifth Grade',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G5_C'],
    },
    {
      fileName: 'program_hwesG6.mp3',
      caption: 'Zaner-Bloser La Escritura - Sixth Grade',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020N_G6_C'],
    },
    {
      fileName: 'program_hwesGKT.mp3',
      caption: 'Zaner-Bloser La Escritura - Kindergarten Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_GK_C'],
    },
    {
      fileName: 'program_hwesG1T.mp3',
      caption: 'Zaner-Bloser La Escritura - First Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G1_C'],
    },
    {
      fileName: 'program_hwesG2T.mp3',
      caption: 'Zaner-Bloser La Escritura - Second Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G2_C'],
    },
    {
      fileName: 'program_hwesG3T.mp3',
      caption: 'Zaner-Bloser La Escritura - Third Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G3_C'],
    },
    {
      fileName: 'program_hwG4T.mp3',
      caption: 'Zaner-Bloser La Escritura - Fourth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G4_C'],
    },
    {
      fileName: 'program_hwesG5T.mp3',
      caption: 'Zaner-Bloser La Escritura - Fifth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G5_C'],
    },
    {
      fileName: 'program_hwesG6T.mp3',
      caption: 'Zaner-Bloser La Escritura - Sixth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['LaEsc2020TX_G6_C'],
    },
    {
      fileName: 'program_spcnG1.mp3',
      caption: 'Spelling Connections - First Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G1_C', 'SPCN2016N_SEsl_G1', 'SPCN2022_G1_C'],
    },
    {
      fileName: 'program_spcnG2.mp3',
      caption: 'Spelling Connections - Second Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G2_C', 'SPCN2016N_SEsl_G2', 'SPCN2022_G2_C'],
    },
    {
      fileName: 'program_spcnG3.mp3',
      caption: 'Spelling Connections - Third Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G3_C', 'SPCN2016N_SEs1_G3', 'SPCN2022_G3_C'],
    },
    {
      fileName: 'program_spcnG4.mp3',
      caption: 'Spelling Connections - Fourth Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G4_C', 'SPCN2016N_SEsl_G4', 'SPCN2022_G4_C'],
    },
    {
      fileName: 'program_spcnG5.mp3',
      caption: 'Spelling Connections - Fifth Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G5_C', 'SPCN2016N_SEsl_G5', 'SPCN2022_G5_C'],
    },
    {
      fileName: 'program_spcnG6.mp3',
      caption: 'Spelling Connections - Sixth Grade',
      label: 'Program audio button',
      associatedKeys: ['SPCN2016N_OG_G6_C', 'SPCN2016N_SEsl_G6', 'SPCN2022_G6_C'],
    },
    {
      fileName: 'program_spcnG1T.mp3',
      caption: 'Spelling Connections - First Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G1_C'],
    },
    {
      fileName: 'program_spcnG2T.mp3',
      caption: 'Spelling Connections - Second Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G2_C'],
    },
    {
      fileName: 'program_spcnG3T.mp3',
      caption: 'Spelling Connections - Third Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G3_C'],
    },
    {
      fileName: 'program_spcnG4T.mp3',
      caption: 'Spelling Connections - Fourth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G4_C'],
    },
    {
      fileName: 'program_spcnG5T.mp3',
      caption: 'Spelling Connections - Fifth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G5_C'],
    },
    {
      fileName: 'program_spcnG6T.mp3',
      caption: 'Spelling Connections - Sixth Grade Texas Edition',
      label: 'Program audio button',
      associatedKeys: ['SPCN2020TX_G6_C'],
    },
    {
      fileName: 'program_gumG1.mp3',
      caption: 'Grammar, Usage, and Mechanics - First Grade',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'program_gumG2.mp3',
      caption: 'Grammar, Usage, and Mechanics - Second Grade',
      label: 'Program audio button',
      associatedKeys: ['GUM2016N_SEsl_G2', 'GUM2021N_G2_C'],
    },
    {
      fileName: 'program_gumG3.mp3',
      caption: 'Grammar, Usage, and Mechanics - Third Grade',
      label: 'Program audio button',
      associatedKeys: ['GUM2016N_SEsl_G3', 'GUM2021N_G3_C'],
    },
    {
      fileName: 'program_gumG4.mp3',
      caption: 'Grammar, Usage, and Mechanics - Fourth Grade',
      label: 'Program audio button',
      associatedKeys: ['GUM2016N_SEsl_G4', 'GUM2021N_G4_C'],
    },
    {
      fileName: 'program_gumG5.mp3',
      caption: 'Grammar, Usage, and Mechanics - Fifth Grade',
      label: 'Program audio button',
      associatedKeys: ['GUM2016N_SEsl_G5', 'GUM2021N_G5_C'],
    },
    {
      fileName: 'program_gumG6.mp3',
      caption: 'Grammar, Usage, and Mechanics - Sixth Grade',
      label: 'Program audio button',
      associatedKeys: ['GUM2016N_SEsl_G6', 'GUM2021N_G6_C'],
    },
    {
      fileName: 'landing_progress1.mp3',
      caption: 'Progress Test 1',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_progress2f.mp3',
      caption: 'Progress Test 2',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_progress3.mp3',
      caption: 'Progress Test 3',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_progress4.mp3',
      caption: 'Progress Test 4',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_progress5.mp3',
      caption: 'Progress Test 5',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_progress6.mp3',
      caption: 'Progress Test 6',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_benchmark.mp3',
      caption: 'Benchmark Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_clubbenchmark.mp3',
      caption: 'Club Benchmark Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_U1progress.mp3',
      caption: 'Unit 1 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU1T1', 'SK2015_testG2AU1T1', 'SK2017_testG1AU1T1', 'SK2017_testG2AU1T1'],
    },
    {
      fileName: 'landing_U2progress.mp3',
      caption: 'Unit 2 Progress Test',
      label: 'Quest audio button',
      associatedKeys: [
        'SK2015_testGKBU2T1',
        'SK2015_testGKBU11T1',
        'SK2015_testG1BU2T1',
        'SK2015_testG2AU2T1',
        'SK2017_testG1AU2T1',
        'SK2017_testG2AU2T1',
      ],
    },
    {
      fileName: 'landing_U3progress.mp3',
      caption: 'Unit 3 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU3T1', 'SK2015_testG2AU3T1', 'SK2017_testG1AU3T1', 'SK2017_testG2AU3T1'],
    },
    {
      fileName: 'landing_U4progress.mp3',
      caption: 'Unit 4 Progress Test',
      label: 'Quest audio button',
      associatedKeys: [
        'SK2015_testGKBU4T1',
        'SK2015_testG1AU4T1',
        'SK2015_testG1BU4T1',
        'SK2015_testG2AU4T1',
        'SK2017_testG1AU4T1',
        'SK2017_testG2AU4T1',
      ],
    },
    {
      fileName: 'landing_U5progress.mp3',
      caption: 'Unit 5 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU3T1', 'SK2015_testG2BU5T1', 'SK2017_testG1AU5T1', 'SK2017_testG2AU5T1'],
    },
    {
      fileName: 'landing_U6progress.mp3',
      caption: 'Unit 6 Progress Test',
      label: 'Quest audio button',
      associatedKeys: [
        'SK2015_testG1AU4T1',
        'SK2015_testG1BU6T1',
        'SK2015_testG2BU6T1',
        'SK2017_testG1AU6T1',
        'SK2017_testG2AU6T1',
      ],
    },
    {
      fileName: 'landing_U7progress.mp3',
      caption: 'Unit 7 Progress Test',
      label: 'Quest audio button',
      associatedKeys: [
        'SK2015_testGKAU7T1',
        'SK2015_testGKBU4T1',
        'SK2015_testG2BU7T1',
        'SK2017_testG1AU7T1',
        'SK2017_testG2AU7T1',
      ],
    },
    {
      fileName: 'landing_U8progress.mp3',
      caption: 'Unit 8 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU8T1', 'SK2015_testG1BU8T1', 'SK2015_testG2BU8T1'],
    },
    {
      fileName: 'landing_U9progress.mp3',
      caption: 'Unit 9 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU9T1', 'SK2015_testGKBU9T1', 'SK2017_testG1BU9T1', 'SK2017_testG2BU9T1'],
    },
    {
      fileName: 'landing_U10progress.mp3',
      caption: 'Unit 10 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU10T1', 'SK2015_testG1BU10T1', 'SK2017_testG1BU10T1', 'SK2017_testG2BU10T1'],
    },
    {
      fileName: 'landing_U11progress.mp3',
      caption: 'Unit 11 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU11T1', 'SK2015_testGKBU11T1', 'SK2017_testG1BU11T1', 'SK2017_testG2BU11T1'],
    },
    {
      fileName: 'landing_U12progress.mp3',
      caption: 'Unit 12 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1BU12T1', 'SK2017_testG2BU12T1'],
    },
    {
      fileName: 'landing_U13progress.mp3',
      caption: 'Unit 13 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1BU13T1', 'SK2017_testG2BU13T1'],
    },
    {
      fileName: 'landing_U14progress.mp3',
      caption: 'Unit 14 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1BU14T1', 'SK2017_testG2BU14T1'],
    },
    {
      fileName: 'landing_U15progress.mp3',
      caption: 'Unit 15 Progress Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1BU15T1', 'SK2017_testG2BU15T1'],
    },
    {
      fileName: 'landing_U16endofyear.mp3',
      caption: 'Unit 16 End-of-Year Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1BU16T1', 'SK2017_testG2BU16T1'],
    },
    {
      fileName: 'landing_U8midyear.mp3',
      caption: 'Unit 8 Midyear Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2017_testG1AU8T1', 'SK2017_testG2AU8T1'],
    },
    {
      fileName: 'landing_beginofyear.mp3',
      caption: 'Beginning-of-the-Year Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_pretestG2AU1T1', 'SK2017_testGKAU1T1', 'SK2017_testG1AURT1', 'SK2017_testG1AURT1'],
    },
    {
      fileName: 'landing_placement.mp3',
      caption: 'Placement Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU1T1'],
    },
    {
      fileName: 'landing_readiness.mp3',
      caption: 'Readiness Test',
      label: 'Program audio button',
      associatedKeys: ['SK2017_pretestGKAU1T1'],
    },
    {
      fileName: 'landing_endofyear.mp3',
      caption: 'End-of-Year Test',
      label: 'Program audio button',
      associatedKeys: ['SK2017_testG1BU16T1'],
    },
    {
      fileName: 'landing_endofsemester.mp3',
      caption: 'End-of-Semester Test',
      label: 'Program audio button',
      associatedKeys: ['SK2015_testGKAU13T1', 'SK2015_posttestGKBU11T1'],
    },
    {
      fileName: 'landing_U17progress.mp3',
      caption: 'Unit 17 Progress Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_U20progress.mp3',
      caption: 'Unit 20 Progress Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_U22progress.mp3',
      caption: 'Unit 22 Progress Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_U24progress.mp3',
      caption: 'Unit 24 Progress Test',
      label: 'Program audio button',
      associatedKeys: [],
    },
    {
      fileName: 'landing_meetreadiness.mp3',
      caption: 'Meet Readiness Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_pretestGKAU1T1'],
    },
    {
      fileName: 'landing_meetprogress1.mp3',
      caption: 'Meet Progress Test 1',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU1T1'],
    },
    {
      fileName: 'landing_meetprogress2.mp3',
      caption: 'Meet Progress Test 2',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU3T1'],
    },
    {
      fileName: 'landing_meetprogress3.mp3',
      caption: 'Meet Progress Test 3',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU5T1'],
    },
    {
      fileName: 'landing_meetprogress4.mp3',
      caption: 'Meet Progress Test 4',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU7T1'],
    },
    {
      fileName: 'landing_meetprogress5.mp3',
      caption: 'Meet Progress Test 5',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU9T1'],
    },
    {
      fileName: 'landing_meetprogress6.mp3',
      caption: 'Meet Progress Test 6',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU11T1'],
    },
    {
      fileName: 'landing_meetendofsemester.mp3',
      caption: 'Meet End-of-Semester Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKAU13T1', 'SK2017_testGKAU13T1'],
    },
    {
      fileName: 'landing_clubprogress1.mp3',
      caption: 'Club Progress Test 1',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKBU2T1', 'SK2017_testGKBU15T1'],
    },
    {
      fileName: 'landing_clubprogress2.mp3',
      caption: 'Club Progress Test 2',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKBU4T1', 'SK2017_testGKBU17T1'],
    },
    {
      fileName: 'landing_clubprogress3.mp3',
      caption: 'Club Progress Test 3',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKBU7T1', 'SK2017_testGKBU20T1'],
    },
    {
      fileName: 'landing_clubprogress4.mp3',
      caption: 'Club Progress Test 4',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKBU9T1', 'SK2017_testGKBU22T1'],
    },
    {
      fileName: 'landing_clubprogress5.mp3',
      caption: 'Club Progress Test 5',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testGKBU11T1', 'SK2017_testGKBU24T1'],
    },
    {
      fileName: 'landing_clubendofsemester.mp3',
      caption: 'Club End-of-Semester Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_posttestGKBU11T1', 'SK2017_posttestGKBU24T1'],
    },
    {
      fileName: 'landing_adventuresplacement.mp3',
      caption: 'Adventures Placement Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU1T1'],
    },
    {
      fileName: 'landing_adventuresprogress1.mp3',
      caption: 'Adventures Progress Test 1',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU2T1'],
    },
    {
      fileName: 'landing_adventuresprogress2.mp3',
      caption: 'Adventures Progress Test 2',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU4T1'],
    },
    {
      fileName: 'landing_adventuresprogress3.mp3',
      caption: 'Adventures Progress Test 3',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU6T1'],
    },
    {
      fileName: 'landing_adventuresprogress4.mp3',
      caption: 'Adventures Progress Test 4',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU8T1'],
    },
    {
      fileName: 'landing_adventuresprogress5.mp3',
      caption: 'Adventures Progress Test 5',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1AU10T1'],
    },
    {
      fileName: 'landing_adventuresendoflevel.mp3',
      caption: 'Adventures End-of-Level Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_posttestG1AU10T1'],
    },
    {
      fileName: 'landing_moreprogress1.mp3',
      caption: 'More Progress Test 1',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1BU2T1'],
    },
    {
      fileName: 'landing_moreprogress2.mp3',
      caption: 'More Progress Test 2',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1BU4T1'],
    },
    {
      fileName: 'landing_moreprogress3.mp3',
      caption: 'More Progress Test 3',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1BU6T1'],
    },
    {
      fileName: 'landing_moreprogress4.mp3',
      caption: 'More Progress Test 4',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1BU8T1'],
    },
    {
      fileName: 'landing_moreprogress5.mp3',
      caption: 'More Progress Test 5',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG1BU10T1'],
    },
    {
      fileName: 'landing_moreendoflevel.mp3',
      caption: 'More End-of-Level Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_posttestG1BU10T1'],
    },
    {
      fileName: 'landing_beginningoftheyear.mp3',
      caption: 'Beginning of The Year Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_pretestG2AU1T1'],
    },
    {
      fileName: 'landing_hitsecondprogress1.mp3',
      caption: 'Hit Second Progress Test 1',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2AU1T1'],
    },
    {
      fileName: 'landing_hitsecondprogress2.mp3',
      caption: 'Hit Second Progress Test 2',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2AU2T1'],
    },
    {
      fileName: 'landing_hitsecondprogress3.mp3',
      caption: 'Hit Second Progress Test 3',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2AU3T1'],
    },
    {
      fileName: 'landing_hitsecondprogress4.mp3',
      caption: 'Hit Second Progress Test 4',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2AU4T1'],
    },
    {
      fileName: 'landing_hitsecondendoflevel.mp3',
      caption: 'Hit Second End-of-Lvel Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_posttestG2AU4T1'],
    },
    {
      fileName: 'landing_takeoffprogress5.mp3',
      caption: 'Take Off Progress Test 5',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2BU5T1'],
    },
    {
      fileName: 'landing_takeoffprogress6.mp3',
      caption: 'Take Off Progress Test 6',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2BU6T1'],
    },
    {
      fileName: 'landing_takeoffprogress7.mp3',
      caption: 'Take Off Progress Test 7',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2BU7T1'],
    },
    {
      fileName: 'landing_takeoffprogress8.mp3',
      caption: 'Take Off Progress Test 8',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_testG2BU8T1'],
    },
    {
      fileName: 'landing_takeoffendoflevel.mp3',
      caption: 'Take Off End-of-Level Test',
      label: 'Quest audio button',
      associatedKeys: ['SK2015_posttestG2BU8T1'],
    },
  ];

  constructor(
    private browserService: BrowserService,
    private appConfig: AppConfigService
  ) {
    this.initialize();
  }

  /**
   * Get audio metadata by unique id string.
   *
   * @param {String} id
   */
  getAudioFileById(id: string): IAudioHelpFile {
    return this.studentAudioFiles.find(f => f.audioId === id);
  }

  /**
   * Get audio metadata matching an associated key.
   *
   * @param {String} key - Either a SKU name or Quest key.
   */
  getAudioFileByKey(key: string): IAudioHelpFile {
    return this.studentAudioFiles.find(file => file.associatedKeys?.includes(key));
  }

  /**
   * Stops playing the currently played file, if any.
   */
  stopCurrent(): void {
    if (this.currentlyPlaying) {
      this.currentlyPlaying.pause();
      this.currentlyPlaying.currentTime = 0;
      this.currentlyPlaying = null;
    }
  }

  /**
   * Stops playing the specified audio element.
   */
  stop(el: HTMLAudioElement): void {
    if (el.src !== this.currentlyPlaying?.src) {
      this.stopCurrent();
    } else {
      el.pause();
    }
  }

  /**
   * Plays the given audio element tracking it in currentlyPlaying variable.
   *
   * @param {HTMLAudioElement} el
   *
   * @return {Observable<boolean>}
   *   True if audio element finished playing otherwise false on early async return.
   */
  playAsCurrent(el: HTMLAudioElement): Observable<boolean> {
    if (this.currentlyPlaying) {
      this.stop(el);
    }
    this.currentlyPlaying = el;

    const played = fromEvent(this.currentlyPlaying, 'ended');
    const paused = fromEvent(this.currentlyPlaying, 'pause');
    this.currentlyPlaying.play().catch((err) => {
      console.error(err);
    });

    return race([played, paused])
      .pipe(
        map(() => {
          if (this.currentlyPlaying?.src === el.src) {
            this.currentlyPlaying = null;
          }
          return true;
        }),
        take(1)
      );
  }

  /**
   * Wraps media element events in observable to load audio element.
   *
   * This allows the service to easily determine if an audio element is loaded or has errored out
   * by taking the first boolean result from either event chain.
   *
   * @param {HTMLAudioElement} element
   */
  load(element: HTMLAudioElement): Observable<boolean> {
    return race([this.buildLoadedDataObservable(element), this.buildErrorObservable(element)]);
  }

  setAudioData(data: IAudioHelpFile[]): void {
    this.studentAudioFiles = data.map(file => ({
      ...file,
      audioId: file.fileName.replace(/\.mp3$/, ''),
      fileName: `${this.appConfig.assetUrl}content/shared/audio-instruction/${file.fileName}`,
    }));
  }

  private initialize(): void {
    this.setAudioData(this.studentAudioFileData);
  }

  private buildLoadedDataObservable(audio: HTMLAudioElement): Observable<boolean> {
    const obs = fromEvent(audio, 'loadeddata')
      .pipe(
        catchError(() => {
          console.error('loadeddata: missing audio');
          return of(false);
        }),
        map(() => true),
        take(1)
      );
    if (this.browserService.isIOSDevice) {
      audio.load();
    }
    return obs;
  }

  private buildErrorObservable(audio: HTMLAudioElement): Observable<boolean> {
    const errorObs = fromEvent(audio, 'error')
      .pipe(
        catchError(() => {
          console.error('audio error: missing audio');
          return of(false);
        }),
        map((r: ErrorEvent) => {
          console.error(`missing audio: ${audio.src}`);
          if (r.error) {
            console.error(r.error);
          }
          return false;
        }),
        // take(1)
      );
    return errorObs;
  }
}
