
import { ClassroomIcon } from '@models/classroom-icon';
import { School } from '@models/school';
import { createAction, props } from '@ngrx/store';

export enum SCHOOL_ACTIONS {
  reset = '[Schools] Reset Loading',
  setMessages = '[Schools] Set Messages',
  refresh = '[Schools] Refresh Schools',
  setSchools = '[Schools] Set Schools',
  updateSchool = '[Schools] Update School props',
  addSchool = '[Schools] Add School',
  removeSchool = '[Schools] Remove School',
  setSelectedSchoolId = '[Schools] Set Selected School Id',
  resetClassroomIcons = '[Schools] Reset Classroom Icons Loading',
  initClassroomIcons = '[Schools] Initialize Classroom Icons',
  setClassroomIcons = '[Schools] Set Classroom Icons',
}

export const resetLoading = createAction(SCHOOL_ACTIONS.reset);
export const setMessages = createAction(SCHOOL_ACTIONS.setMessages, props<{ messages: string[] }>());
export const refreshSchools = createAction(SCHOOL_ACTIONS.refresh);
export const setSchools = createAction(SCHOOL_ACTIONS.setSchools, props<{ payload: School[] }>());
export const updateSchool = createAction(SCHOOL_ACTIONS.updateSchool, props<{ payload: any, schoolId: string }>());
export const addSchool = createAction(SCHOOL_ACTIONS.addSchool, props<{ payload: School }>());
export const removeSchool = createAction(SCHOOL_ACTIONS.removeSchool, props<{ schoolId: string }>());
export const setSelectedSchoolId = createAction(SCHOOL_ACTIONS.setSelectedSchoolId, props<{ schoolId: string }>());
export const resetClassroomIconsLoading = createAction(SCHOOL_ACTIONS.resetClassroomIcons);
export const initClassroomIcons = createAction(SCHOOL_ACTIONS.initClassroomIcons);
export const setClassroomIcons = createAction(SCHOOL_ACTIONS.setClassroomIcons, props<{
  payload: ClassroomIcon[]
}>());
