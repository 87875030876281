export const thumbtackIcon = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 26 41" width="15" height="15" style="enable-background:new 0 0 26 41;" xml:space="preserve">
    <path d="M20.8,2.2c-0.1,0.5-0.5,1.1-0.9,1.5c-0.2,0.2-0.4,0.4-0.6,0.5l-0.8,0.6v1V17v1.1l1,0.6l0.2,0.1c0.6,0.4,1.1,0.8,1.6,1.2
      c0.9,0.8,1.9,1.9,2.2,3.2h-9.2h-2h-0.5H2.4c0.3-1.3,1.3-2.5,2.2-3.2c0.6-0.5,1.2-0.9,1.8-1.3l1-0.6V17V5.9v-1L6.5,4.3
      C6.3,4.1,6.1,3.9,5.9,3.7C5.4,3.3,5.1,2.8,5,2.2H20.8 M4.1,0.2c-0.2,0-0.3,0-0.5,0.1C3.5,0.4,3.3,0.5,3.2,0.6C3.1,0.7,3,0.8,3,1
      C2.9,1.1,2.9,1.3,2.9,1.5c0,1.7,0.9,2.9,1.6,3.7C4.7,5.4,5,5.7,5.4,5.9V17c-0.7,0.4-1.4,0.9-2.1,1.5c-1.3,1.2-2.9,3-2.9,5.5
      c0,0.3,0.1,0.6,0.4,0.9c0.2,0.2,0.6,0.4,0.9,0.4h10v11.3c0,0.7,0.6,3.8,1.2,3.8c0.7,0,1.2-3.1,1.2-3.8V25.2h10
      c0.3,0,0.6-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9c0-2.4-1.6-4.3-2.9-5.5c-0.7-0.6-1.4-1.1-1.9-1.4L20.4,17V5.9
      c0.3-0.2,0.6-0.4,0.9-0.7c0.8-0.8,1.6-2,1.6-3.7c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4H4.1C4.1,0.2,4.1,0.2,4.1,0.2
      L4.1,0.2z"/>
  </svg>
`;

