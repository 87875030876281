/**
 * This interface extends the built-in Error interface and adds two properties: uuid and url.
 *
 * @interface ExtendedHttpErrorResponse
 * @extends Error
 * @property {string} backendErrorId - uuid generated by front end
 * @property {string[]} messages - error messages from the backend
 */

import { HttpErrorResponse } from '@angular/common/http';

export class ExtendedHttpErrorResponse extends HttpErrorResponse {
  backendErrorId: string = undefined;
  messages: string[] = [];
}
